import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect, useState } from "react";
import {
  fetchClientsData,
  openClientEditor,
} from "../../redux/actions/client.actions";

import { Table } from "antd";
import Filters from "./Filters";
import { BsPencil } from "react-icons/bs";

const ClientsTable = () => {
  const dispatch = useAppDispatch();
  const clientsData = useAppSelector((state) => state.clients.filteredClients);
  const isLoading = useAppSelector((state) => state.clients.isLoading);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleOpenClientEditor = ({
    codigoCliente,
    nombre,
  }: {
    codigoCliente: string;
    nombre: string;
  }) => {
    dispatch(
      openClientEditor({
        open: true,
        clientId: codigoCliente,
        clientName: nombre,
      })
    );
  };
  useEffect(() => {
    dispatch(fetchClientsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: "#",
      width: 100,
      dataIndex: "codigoCliente",
    },
    {
      title: "Nombre",
      width: 100,
      dataIndex: "nombre",
    },
    {
      title: "Domicilio",
      width: 220,
      dataIndex: "domicilio",
    },
    {
      title: "Acciones",
      width: 150,
      key: "action",
      render: (_: any, record: any) => (
        <>
          <button
            onClick={() => handleOpenClientEditor(record)}
            className="bg-green-700 text-white hover:bg-green-600  p-2 rounded-md mr-1 flex "
          >
            <BsPencil className="mt-1 mr-1" /> Editar
          </button>
        </>
      ),
    },
  ];
  return (
    <div className="w-full p-10">
      <Filters />
      <Table
        id="clients-table"
        loading={isLoading}
        columns={columns}
        pagination={{
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: handlePageChange,
        }}
        dataSource={clientsData}
      />
    </div>
  );
};

export default ClientsTable;
