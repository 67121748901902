export const API_BASE_URL = process.env.REACT_APP_ECOMEXICO_API;

export const DRIVIN_BASE_URL = "https://external.driv.in/api/external/v2";
export const getRequestHeaders = (authToken: string) => {
  return {
    headers: {
      Authorization: authToken,
    },
  };
};

export class DrivinApi {
  static async getPruebaEntrega(orderCodes: string[]) {
    try {
      let query = "";
      orderCodes.forEach(
        (orderCode) => (query += `order_code[]=${orderCode}&`)
      );
      const data = await fetch(`${DRIVIN_BASE_URL}/pods?${query}`, {
        headers: {
          "content-type": "application/json",
          "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
        },
      });
      const response = await data.json();
      return response.response;
    } catch (error) {
      throw error;
    }
  }
}

export default DrivinApi;
