export default function ErrorPage() {
  return (
    <div id="error-page" className="mt-10">
      <h1 className="text-center w-full text-3xl">
        Error 404 - Pagina no encontrada
      </h1>
      <p className="text-center w-full text-xl">
        Pagina no encontrada, refresca la página o intenta otra url.
      </p>
      <p>
        <i>{}</i>
      </p>
    </div>
  );
}
