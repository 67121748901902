import { Dispatch } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../utils/api";
export const ActionTypes = {
  // GET
  GET_LISTAS_PRECIOS_START: "GET_LISTAS_PRECIOS_START",
  GET_LISTAS_PRECIOS_SUCCESS: "GET_LISTAS_PRECIOS_SUCCESS",
  GET_LISTAS_PRECIOS_ERROR: "GET_LISTAS_PRECIOS_ERROR",

  // GET ONE
  GET_ONE_LISTAS_PRECIOS_START: "GET_ONE_LISTAS_PRECIOS_START",
  GET_ONE_LISTAS_PRECIOS_SUCCESS: "GET_ONE_LISTAS_PRECIOS_SUCCESS",
  GET_ONE_LISTAS_PRECIOS_ERROR: "GET_ONE_LISTAS_PRECIOS_ERROR",

  // POST / PUT
  SAVE_LISTAS_PRECIOS_START: "SAVE_LISTAS_PRECIOS_START",
  SAVE_LISTAS_PRECIOS_SUCCESS: "SAVE_LISTAS_PRECIOS_SUCCESS",
  SAVE_LISTAS_PRECIOS_ERROR: "SAVE_LISTAS_PRECIOS_ERROR",

  // DELETE
  DELETE_LISTAS_PRECIOS_START: "DELETE_LISTAS_PRECIOS_START",
  DELETE_LISTAS_PRECIOS_SUCCESS: "DELETE_LISTAS_PRECIOS_SUCCESS",
  DELETE_LISTAS_PRECIOS_ERROR: "DELETE_LISTAS_PRECIOS_ERROR",
};
/**
 *
 * @param id String - If added will just ask for 1 lista precio
 * @returns
 */
export const getListasPrecios = (id?: string) => async (dispatch: Dispatch) => {
  try {
    let URL = `${API_BASE_URL}/listas-precios?`;
    dispatch({
      type: id
        ? ActionTypes.GET_ONE_LISTAS_PRECIOS_START
        : ActionTypes.GET_LISTAS_PRECIOS_START,
    });
    if (id) {
      URL += `id=${id}&`;
    }
    const response = await fetch(URL);
    if (!response.ok) {
      dispatch({
        type: id
          ? ActionTypes.GET_ONE_LISTAS_PRECIOS_ERROR
          : ActionTypes.GET_LISTAS_PRECIOS_ERROR,
        payload: [response],
      });
      return;
    }
    const data = await response.json();
    dispatch({
      type: id
        ? ActionTypes.GET_ONE_LISTAS_PRECIOS_SUCCESS
        : ActionTypes.GET_LISTAS_PRECIOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: id
        ? ActionTypes.GET_ONE_LISTAS_PRECIOS_ERROR
        : ActionTypes.GET_LISTAS_PRECIOS_ERROR,
      payload: [error],
    });
  }
};

export const saveListaPrecios =
  (payload: any, id?: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SAVE_LISTAS_PRECIOS_START,
    });
    try {
      let URL = `${API_BASE_URL}/listas-precios`;
      if (id) {
        URL += `/${id}`;
      }
      const response = await fetch(URL, {
        method: id ? "PUT" : "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        dispatch({
          type: ActionTypes.SAVE_LISTAS_PRECIOS_ERROR,
          payload: [`${response.status} - ${response.statusText}`],
        });
        return;
      }
      dispatch({ type: ActionTypes.SAVE_LISTAS_PRECIOS_SUCCESS });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_LISTAS_PRECIOS_ERROR,
        payload: [error],
      });
    }
  };

export const deleteListaPrecios =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_LISTAS_PRECIOS_START,
    });
    try {
      const response = await fetch(`${API_BASE_URL}/listas-precios/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        dispatch({
          type: ActionTypes.DELETE_LISTAS_PRECIOS_ERROR,
          payload: [response],
        });
        return;
      }
      dispatch({
        type: ActionTypes.DELETE_LISTAS_PRECIOS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DELETE_LISTAS_PRECIOS_ERROR,
        payload: [error],
      });
    }
  };
