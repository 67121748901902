import { Button } from "antd";
import Filtros from "../components/Filtros/Filtros";
import Manifiestos from "../components/ManifiestosTable/ManifiestosTable";
import { useAppDispatch, useAppSelector } from "../hooks";
import { BsArrowClockwise } from "react-icons/bs";
import {
  getManifiestos,
  resetReport,
  setFilters,
} from "../redux/actions/manifiestos.actions";
import { useEffect } from "react";
import dayjs from "dayjs";

const Reports = () => {
  const {
    manifiestos: { manifiestosCount },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setFilters({
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        folios: [],
        codigoClientes: [],
        nombreCliente: "",
        rutas: [],
        domicilio: "",
        aprobados: true,
      })
    );
    return () => {
      dispatch(resetReport());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-5">
      <h1 className="text-2xl text-green-800">Manifiestos</h1>
      <div className="flex">
        <Filtros />
        <span
          style={{ paddingTop: "6px", paddingBottom: "6px" }}
          className="text-green-800 border-green-900 border px-3 ml-2 rounded-md self-start"
        >
          Formularios encontrados: {manifiestosCount}
        </span>
        <Button onClick={() => dispatch(getManifiestos())} className="ml-2">
          <BsArrowClockwise />
        </Button>
      </div>
      <Manifiestos />
    </div>
  );
};

export default Reports;
