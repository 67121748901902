import { Button, Form, Input, Select, notification } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import { Product } from "../../utils/types";
import { BsPlusCircleFill } from "react-icons/bs";
interface IProps {
  onAdd: (cantidad: number, codigoProducto: string, size: string) => void;
}
const AgregarRecoleccionForm = ({ onAdd }: IProps) => {
  const [showForm, setShowForm] = useState(false);
  const [form] = useForm();
  const [sizesNuevo, setSizesNuevo] = useState<any[]>([]);
  const codigoProductoNuevo = useWatch("codigo_producto", form);
  /**REDUX */
  const productsData = useAppSelector((state) => {
    const productos = state.products.products;
    const data = new Set<string>();
    productos.forEach(({ producto }: Product) => {
      data.add(producto);
    });
    return Array.from(data);
  });
  const products: Product[] = useAppSelector(
    (state) => state.products.products
  );

  useEffect(() => {
    const newSizes = products
      .filter((product) => product.producto === codigoProductoNuevo)
      .map(({ size }) => ({
        label: size,
        value: size,
      }));
    setSizesNuevo(newSizes);
  }, [codigoProductoNuevo, products]);

  const handleAgregarElemento = () => {
    const { cantidad, codigo_producto, size } = form.getFieldsValue();
    if (!cantidad || cantidad === "" || parseInt(cantidad) < 1) {
      notification.error({ message: "Escribe la cantidad" });
      return;
    }
    if (!codigo_producto || codigo_producto === "") {
      notification.error({ message: "Elige el producto" });
      return;
    }
    if (!size || size === "") {
      notification.error({ message: "Elige el tamaño" });
      return;
    }
    onAdd(cantidad, codigo_producto, size);
    form.resetFields();
    setShowForm(false);
  };
  return showForm ? (
    <Form form={form} id="agregar-recoleccion" className="flex justify-between">
      <Form.Item className="w-1/3 mr-2 h-5" name={"cantidad"}>
        <Input placeholder="Cantidad" type="number" min={1} />
      </Form.Item>
      <Form.Item className="w-1/3 mr-2 h-5" name={"codigo_producto"}>
        <Select
          placeholder="Producto"
          options={productsData.map((product) => ({
            label: product,
            value: product,
          }))}
        />
      </Form.Item>
      <Form.Item className="w-1/3 mr-2 h-5" name={"size"}>
        <Select placeholder="Tamaño" options={sizesNuevo} />
      </Form.Item>
      <Button onClick={handleAgregarElemento}>Agregar</Button>
    </Form>
  ) : (
    <Button
      type="primary"
      size="middle"
      className="w-full mb-2 text-center flex justify-center "
      onClick={() => setShowForm(true)}
    >
      <BsPlusCircleFill className="my-auto mx-2" /> Agregar elemento
    </Button>
  );
};

export default AgregarRecoleccionForm;
