import { Dispatch } from "@reduxjs/toolkit";
import { API_BASE_URL, DRIVIN_BASE_URL } from "../../utils/api";
import { RootState } from "../store";

export const ActionTypes = {
  // SYNC ACTIONS
  SET_PAGINATION_STATE: "SET_PAGINATION_STATE",
  SET_ORDER_BY_STATE: "SET_ORDER_BY_STATE",
  // FETCH PREFACTURAS
  GET_PREFACTURAS_START: "GET_PREFACTURAS_START",
  GET_PREFACTURAS_SUCCESS: "GET_PREFACTURAS_SUCCESS",
  GET_PREFACTURAS_FAILED: "GET_PREFACTURAS_FAILED",
  // FETCH PREFACTURAS
  GET_PREFACTURA_BY_ID_START: "GET_PREFACTURA_BY_ID_START",
  GET_PREFACTURA_BY_ID_SUCCESS: "GET_PREFACTURA_BY_ID_SUCCESS",
  GET_PREFACTURA_BY_ID_FAILED: "GET_PREFACTURA_BY_ID_FAILED",
  // GET PREFACTURAS
  GET_PREFACTURAS_REPORT_START: "GET_PREFACTURAS_REPORT_START",
  GET_PREFACTURAS_REPORT_SUCCESS: "GET_PREFACTURAS_REPORT_SUCCESS",
  GET_PREFACTURAS_REPORT_FAILED: "GET_PREFACTURAS_REPORT_FAILED",

  // SAVE NEW PREFACTURA
  SAVE_NEW_PREFACTURA_START: "SAVE_NEW_PREFACTURA_START",
  SAVE_NEW_PREFACTURA_SUCCESS: "SAVE_NEW_PREFACTURA_SUCCESS",
  SAVE_NEW_PREFACTURA_FAILED: "SAVE_NEW_PREFACTURA_FAILED",
  // SAVE FACTURA
  SAVE_FACTURA_START: "SAVE_FACTURA_START",
  SAVE_FACTURA_SUCCESS: "SAVE_FACTURA_SUCCESS",
  SAVE_FACTURA_FAILED: "SAVE_FACTURA_FAILED",
  // DELETE FACTURA
  DELETE_FACTURA_START: "DELETE_FACTURA_START",
  DELETE_FACTURA_SUCCESS: "DELETE_FACTURA_SUCCESS",
  DELETE_FACTURA_FAILED: "DELETE_FACTURA_FAILED",

  SET_FILTERS: "SET_FILTERS",
  RESET_PREFACTURAS_STATE: "RESET_PREFACTURAS_STATE",
};

export const setPaginationState =
  (page: number, pageSize: number) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SET_PAGINATION_STATE,
      payload: { page, pageSize },
    });
  };

export const setOrderByState =
  (key: string, direction: "ASC" | "DESC") => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SET_ORDER_BY_STATE,
      payload: [key, direction],
    });
  };

export const resetPrefacturasState = () => (dispatch: Dispatch) => {
  dispatch({ type: ActionTypes.RESET_PREFACTURAS_STATE });
};

export const getPrefacturas =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({
      type: ActionTypes.GET_PREFACTURAS_START,
    });
    const {
      prefacturas: {
        filtros: { codigoClientes, facturada, startDate, endDate },
      },
    } = getState();
    try {
      let URL = `${API_BASE_URL}/prefactura?`;
      if (codigoClientes) {
        URL += `codigoCliente=${codigoClientes}&`;
      }
      if (facturada !== undefined) {
        URL += `facturada=${facturada}&`;
      }
      if (startDate && endDate) {
        URL += `start_date=${startDate}&end_date=${endDate}&`;
      }
      const response = await fetch(URL);
      if (!response.ok) {
        dispatch({
          type: ActionTypes.GET_PREFACTURAS_FAILED,
          payload: [response],
        });
        return;
      }

      const data = await response.json();
      dispatch({
        type: ActionTypes.GET_PREFACTURAS_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_PREFACTURAS_FAILED,
        payload: [error],
      });
    }
  };

export const getPrefacturaById = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.GET_PREFACTURA_BY_ID_START,
  });
  try {
    const response = await fetch(`${API_BASE_URL}/prefactura/${id}`);

    if (!response.ok) {
      dispatch({
        type: ActionTypes.GET_PREFACTURA_BY_ID_FAILED,
        payload: [response],
      });
      return;
    }
    const data = await response.json();
    dispatch({
      type: ActionTypes.GET_PREFACTURA_BY_ID_SUCCESS,
      payload: data[0],
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_PREFACTURA_BY_ID_FAILED,
      payload: [error],
    });
  }
};

export const setFilters = (filters: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.SET_FILTERS,
    payload: filters,
  });
};

export const getPrefacturasReport =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({
      type: ActionTypes.GET_PREFACTURAS_REPORT_START,
    });
    const {
      filtros: {
        codigoClientes,
        nombreCliente,
        estatus,
        tipoFacturacion,
        ultimaFacturaRegistradaFecha,
        ultimaFacturaRegistradaId,
      },
    } = getState().prefacturas;
    try {
      let url = `${API_BASE_URL}/corp/clients?`;
      if (estatus !== undefined && estatus !== "") {
        url += `has_facturas_aprobadas=${estatus}&`;
      }
      if (tipoFacturacion) url += `tipo_facturacion_id=${tipoFacturacion}&`;
      if (ultimaFacturaRegistradaId)
        url += `ultima_factura_id=${ultimaFacturaRegistradaId}&`;
      if (ultimaFacturaRegistradaFecha)
        url += `ultima_factura_fecha=${ultimaFacturaRegistradaFecha}&`;

      const drivinResponse1 = await fetch(
        `${DRIVIN_BASE_URL}/addresses?page=1`,
        {
          headers: {
            "content-type": "application/json",
            "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
          },
        }
      );
      const response1 = await drivinResponse1.json();
      const drivinResponse2 = await fetch(
        `${DRIVIN_BASE_URL}/addresses?page=2`,
        {
          headers: {
            "content-type": "application/json",
            "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
          },
        }
      );
      const response2 = await drivinResponse2.json();
      const drivinFormatedData: any = [].concat(
        response1.response,
        response2.response
      );
      const response = await fetch(url);
      let formatedData = await response.json();
      if (Array.isArray(codigoClientes) && codigoClientes.length > 0) {
        formatedData = formatedData.filter((data: any) =>
          codigoClientes.includes(data.codigoCliente)
        );
      }
      const finalData = formatedData.map((clientData: any) => {
        const clienteDrivin = drivinFormatedData.find(
          (element: any) => element.code === clientData.codigoCliente
        );
        if (clienteDrivin) {
          return {
            ...clientData,
            nombre: clienteDrivin.name,
            domicilio: `${clienteDrivin.address1 || ""} , ${
              clienteDrivin.city || ""
            } ${clienteDrivin.state || ""} ${clienteDrivin.country || ""}`,
          };
        }
        return {
          ...clientData,
        };
      });
      let payload = finalData;

      if (typeof nombreCliente === "string" && nombreCliente !== "") {
        payload = finalData.filter(
          (client: { [key: string]: any; nombre: string }) =>
            client.nombre?.toLowerCase().includes(nombreCliente.toLowerCase())
        );
      }
      dispatch({
        type: ActionTypes.GET_PREFACTURAS_REPORT_SUCCESS,
        payload,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ActionTypes.GET_PREFACTURAS_REPORT_FAILED,
      });
    }
  };

export const saveNewPrefactura =
  (payload: Object) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SAVE_NEW_PREFACTURA_START,
    });

    try {
      const response = await fetch(`${API_BASE_URL}/prefactura`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(payload),
      });
      if (response.status !== 201) {
        dispatch({
          type: ActionTypes.SAVE_NEW_PREFACTURA_FAILED,
          payload: [`There was an error`, response],
        });
        return;
      }
      dispatch({
        type: ActionTypes.SAVE_NEW_PREFACTURA_SUCCESS,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: ActionTypes.SAVE_NEW_PREFACTURA_FAILED });
    }
  };

export const saveFactura =
  (prefacturaId: string, payload: Object) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SAVE_FACTURA_START,
    });

    try {
      const response = await fetch(
        `${API_BASE_URL}/prefactura/${prefacturaId}`,
        {
          method: "PUT",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify(payload),
        }
      );
      if (response.status !== 204) {
        dispatch({
          type: ActionTypes.SAVE_FACTURA_FAILED,
          payload: [`There was an error`, response],
        });
        return;
      }
      dispatch({
        type: ActionTypes.SAVE_FACTURA_SUCCESS,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: ActionTypes.SAVE_FACTURA_FAILED });
    }
  };

export const deletePrefactura = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionTypes.DELETE_FACTURA_START });
  try {
    const response = await fetch(`${API_BASE_URL}/prefactura/${id}`, {
      method: "DELETE",
      headers: new Headers({ "content-type": "application/json" }),
    });
    if (response.status !== 204) {
      dispatch({
        type: ActionTypes.DELETE_FACTURA_FAILED,
        payload: [`There was an error`, response],
      });
      return;
    }
    dispatch({
      type: ActionTypes.DELETE_FACTURA_SUCCESS,
    });
  } catch (error) {
    dispatch({ type: ActionTypes.DELETE_FACTURA_FAILED });
  }
};
