import { Dispatch } from "@reduxjs/toolkit";

export const ActionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

export const login =
  ({ token, usuario }: { token: string; usuario: string }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.LOGIN,
      payload: { token, usuario },
    });
  };

export const logout = () => (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.LOGOUT,
  });
};
