import { Dispatch } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../utils/api";
export const ActionTypes = {
  // GET
  GET_TIPOS_FACTURACION_START: "GET_TIPOS_FACTURACION_START",
  GET_TIPOS_FACTURACION_SUCCESS: "GET_TIPOS_FACTURACION_SUCCESS",
  GET_TIPOS_FACTURACION_ERROR: "GET_TIPOS_FACTURACION_ERROR",

  // GET ONE
  GET_ONE_TIPOS_FACTURACION_START: "GET_ONE_TIPOS_FACTURACION_START",
  GET_ONE_TIPOS_FACTURACION_SUCCESS: "GET_ONE_TIPOS_FACTURACION_SUCCESS",
  GET_ONE_TIPOS_FACTURACION_ERROR: "GET_ONE_TIPOS_FACTURACION_ERROR",

  // POST / PUT
  SAVE_TIPOS_FACTURACION_START: "SAVE_TIPOS_FACTURACION_START",
  SAVE_TIPOS_FACTURACION_SUCCESS: "SAVE_TIPOS_FACTURACION_SUCCESS",
  SAVE_TIPOS_FACTURACION_ERROR: "SAVE_TIPOS_FACTURACION_ERROR",

  // DELETE
  DELETE_TIPOS_FACTURACION_START: "DELETE_TIPOS_FACTURACION_START",
  DELETE_TIPOS_FACTURACION_SUCCESS: "DELETE_TIPOS_FACTURACION_SUCCESS",
  DELETE_TIPOS_FACTURACION_ERROR: "DELETE_TIPOS_FACTURACION_ERROR",
};

export const getTiposFacturacion =
  (id?: string, tipo?: string) => async (dispatch: Dispatch) => {
    try {
      let URL = `${API_BASE_URL}/tipos-facturacion?`;
      dispatch({
        type: id
          ? ActionTypes.GET_ONE_TIPOS_FACTURACION_START
          : ActionTypes.GET_TIPOS_FACTURACION_START,
      });
      if (id) {
        URL += `id=${id}&`;
      }
      if (tipo) {
        URL += `tipo=${tipo}&`;
      }
      const response = await fetch(URL);
      if (!response.ok) {
        dispatch({
          type: id
            ? ActionTypes.GET_ONE_TIPOS_FACTURACION_ERROR
            : ActionTypes.GET_TIPOS_FACTURACION_ERROR,
          payload: [response],
        });
        return;
      }
      const data = await response.json();
      dispatch({
        type: id
          ? ActionTypes.GET_ONE_TIPOS_FACTURACION_SUCCESS
          : ActionTypes.GET_TIPOS_FACTURACION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: id
          ? ActionTypes.GET_ONE_TIPOS_FACTURACION_ERROR
          : ActionTypes.GET_TIPOS_FACTURACION_ERROR,
        payload: [error],
      });
    }
  };

export const saveTipoFacturacion =
  (payload: any, id?: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SAVE_TIPOS_FACTURACION_START,
    });
    try {
      let URL = `${API_BASE_URL}/tipos-facturacion`;
      if (id) {
        URL += `/${id}`;
      }
      const response = await fetch(URL, {
        method: id ? "PUT" : "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        dispatch({
          type: ActionTypes.SAVE_TIPOS_FACTURACION_ERROR,
          payload: [`${response.status} - ${response.statusText}`],
        });
        return;
      }
      dispatch({ type: ActionTypes.SAVE_TIPOS_FACTURACION_SUCCESS });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_TIPOS_FACTURACION_ERROR,
        payload: [error],
      });
    }
  };

export const deleteTipoFacturacion =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_TIPOS_FACTURACION_START,
    });
    try {
      const response = await fetch(`${API_BASE_URL}/tipos-facturacion/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        dispatch({
          type: ActionTypes.DELETE_TIPOS_FACTURACION_ERROR,
          payload: [response],
        });
        return;
      }
      dispatch({
        type: ActionTypes.DELETE_TIPOS_FACTURACION_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DELETE_TIPOS_FACTURACION_ERROR,
        payload: [error],
      });
    }
  };
