import { Alert, Button, Popconfirm, Table, Tooltip } from "antd";
import React from "react";
import {
  BsEye,
  BsGear,
  BsPlusCircleFill,
  BsReceipt,
  BsTrashFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
const tableDataSource = [
  {
    id: 1,
    modelo: "3500",
    anio: "2005",
    placas: "JW06845",
    numeroSerie: "3GBJC34R55M120648",
    numeroMotor: "3GBJC34R55M120648",
    multas: "$1,500.00",
  },
  {
    id: 3,
    modelo: "D21",
    anio: "2000",
    placas: "JW32486",
    numeroSerie: "3N6CD15SXYK049323",
    numeroMotor: "3GBJC34R55M120648",
    multas: "$700.00",
  },
  {
    id: 5,
    modelo: "NP300",
    anio: "2012",
    placas: "JS56953",
    numeroSerie: "3GBJC34R55M120648",
    numeroMotor: "3GBJC34R55M120648",
    multas: "$1,100.00",
  },
  {
    id: 6,
    modelo: "NP300",
    anio: "2009",
    placas: "JV09589",
    numeroSerie: "3GBJC34R55M120648",
    numeroMotor: "3GBJC34R55M120648",
    multas: "$900.00",
  },
];
const Vehiculos = () => {
  const handleDelete = (id: string) => {};
  return (
    <div className="p-5">
      <h1 className="text-2xl text-green-800">Vehiculos</h1>
      <Alert
        className="mb-2"
        message="Modulo en construcción"
        description="Actualmente el modulo es solamente informativo"
        type="info"
        showIcon
        icon={<BsGear />}
      />
      <Link to={"/vehiculos/agregar"}>
        <div className="flex gap-2">
          <Button type="primary" className="flex gap-2 mb-2">
            <BsPlusCircleFill className="mt-1 mr-1" />
            Agregar Vehiculo
          </Button>
          <Button type="primary" className="flex gap-2 mb-2 bg-teal-600">
            <BsReceipt className="mt-1 mr-1" />
            Actualizar multas
          </Button>
        </div>
      </Link>
      <Table
        dataSource={tableDataSource}
        columns={[
          { key: "id", dataIndex: "id", title: "Unidad" },
          { key: "modelo", dataIndex: "modelo", title: "Modelo" },
          { key: "anio", dataIndex: "anio", title: "Año" },
          { key: "placas", dataIndex: "placas", title: "Placas" },
          {
            key: "numeroSerie",
            dataIndex: "numeroSerie",
            title: "Número de serie",
          },
          {
            key: "numeroMotor",
            dataIndex: "numeroMotor",
            title: "Número de motor",
          },
          { key: "multas", dataIndex: "multas", title: "Multas" },
          {
            dataIndex: "id",
            title: "Acciones",
            render: (id, record, index) => {
              return (
                <div className="flex gap-2">
                  <Tooltip title="Ver expediente" placement="left">
                    <Link to="/vehiculos/expediente">
                      <Button type="primary">
                        <BsEye />
                      </Button>
                    </Link>
                  </Tooltip>

                  <Tooltip title="Eliminar" placement="right">
                    <Popconfirm
                      title="Seguro que deseas borrar la unidad"
                      onConfirm={() => handleDelete(id)}
                    >
                      <Button
                        type="primary"
                        className="bg-red-700 hover:bg-red-900"
                      >
                        <BsTrashFill />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default Vehiculos;
