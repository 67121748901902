import { TiposFacturacion } from "../../utils/types";
import { ActionTypes } from "../actions/tiposFacturacion.actions";

interface IReducer {
  isLoading: boolean;
  isLoadingOne: boolean;
  isSaveLoading: boolean;
  isDeleteLoading: boolean;
  isEditorOpen: boolean;
  hasErrors: boolean;
  errors: string[];
  tiposFacturacion: TiposFacturacion[];
  currentTipoFacturacion?: TiposFacturacion;
}

const initialState: IReducer = {
  isLoading: false,
  isLoadingOne: false,
  isSaveLoading: false,
  isDeleteLoading: false,
  isEditorOpen: false,
  hasErrors: false,
  errors: [],
  tiposFacturacion: [],
  currentTipoFacturacion: undefined,
};

const TiposFacturacionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // GET ONE
    case ActionTypes.GET_ONE_TIPOS_FACTURACION_START:
      return {
        ...state,
        isLoadingOne: true,
        hasErrors: false,
        errors: [],
        currentTipoFacturacion: null,
      };
    case ActionTypes.GET_ONE_TIPOS_FACTURACION_SUCCESS:
      return {
        ...state,
        isLoadingOne: false,
        currentTipoFacturacion: action.payload,
      };
    case ActionTypes.GET_ONE_TIPOS_FACTURACION_ERROR:
      return {
        ...state,
        isLoadingOne: false,
        hasErrors: true,
        errors: action.payload,
      };
    // GET MANY
    case ActionTypes.GET_TIPOS_FACTURACION_START:
      return {
        ...state,
        isLoading: true,
        hasErrors: false,
        errors: [],
        tiposFacturacion: [],
      };
    case ActionTypes.GET_TIPOS_FACTURACION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tiposFacturacion: action.payload,
      };
    case ActionTypes.GET_TIPOS_FACTURACION_ERROR:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    // SAVE
    case ActionTypes.SAVE_TIPOS_FACTURACION_START:
      return {
        ...state,
        isSaveLoading: true,
        hasErrors: false,
        errors: [],
      };
    case ActionTypes.SAVE_TIPOS_FACTURACION_SUCCESS:
      return {
        ...state,
        isSaveLoading: false,
      };
    case ActionTypes.SAVE_TIPOS_FACTURACION_ERROR:
      return {
        ...state,
        isSaveLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    // DELETE
    case ActionTypes.DELETE_TIPOS_FACTURACION_START:
      return {
        ...state,
        isDeleteLoading: true,
        hasErrors: false,
        errors: [],
      };
    case ActionTypes.DELETE_TIPOS_FACTURACION_SUCCESS:
      return {
        ...state,
        isDeleteLoading: false,
      };
    case ActionTypes.DELETE_TIPOS_FACTURACION_ERROR:
      return {
        ...state,
        isDeleteLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default TiposFacturacionReducer;
