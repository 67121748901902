import React from "react";
import ProductsTable from "../components/ProductsTable/ProductsTable";

const Productos = () => {
  return (
    <div className="p-5">
      <h1 className="text-2xl text-green-800">Productos</h1>
      <ProductsTable />
    </div>
  );
};

export default Productos;
