import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Select,
  Upload,
} from "antd";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchClientsData } from "../../redux/actions/client.actions";
import locale from "antd/es/date-picker/locale/es_ES";
import { BsUpload } from "react-icons/bs";

const SuspensionActivacion = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchClientsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCodigo = (value: any, option: any) => {
    const cliente = clientsData.find(
      (client) => client.codigoCliente === value
    );
    form.setFieldValue("domicilio", cliente?.domicilio);
    form.setFieldValue("nombreCliente", cliente?.nombre);
    form.setFieldValue("cliente", cliente);
  };
  const handleChangeNombre = (value: any, option: any) => {
    const cliente = clientsData.find(
      (client) => client.codigoCliente === option.key
    );
    form.setFieldValue("domicilio", cliente?.domicilio);
    form.setFieldValue("codigoCliente", cliente?.codigoCliente);
    form.setFieldValue("cliente", cliente);
  };

  const {
    clients: { isLoading, clientsData },
  } = useAppSelector((state) => state);
  return (
    <>
      <Form.Item label="Cliente" name="codigoCliente">
        <Select
          loading={isLoading}
          onChange={handleChangeCodigo}
          showSearch
          options={clientsData.map((cliente) => ({
            key: cliente.codigoCliente,
            label: cliente.codigoCliente,
            value: cliente.codigoCliente,
          }))}
        />
      </Form.Item>
      <Form.Item name="nombreCliente" label="Cliente">
        <Select
          loading={isLoading}
          onChange={handleChangeNombre}
          showSearch
          filterOption={(input, option) => {
            if (!option) return false;
            if (!option.value) return false;
            return (
              option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          options={clientsData.map((cliente) => ({
            key: cliente.codigoCliente,
            label: `${cliente.codigoCliente} - ${cliente.nombre}`,
            value: `${cliente.codigoCliente} - ${cliente.nombre}`,
          }))}
        />
      </Form.Item>
      <Form.Item name="cliente" label="Cliente" hidden>
        <Input className="input-disabled-remarked" disabled />
      </Form.Item>
      <Form.Item name="domicilio" label="Domicilio">
        <Input className="input-disabled-remarked" disabled />
      </Form.Item>
    </>
  );
};

const ServicioEspecial = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchClientsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCodigo = (value: any, option: any) => {
    const cliente = clientsData.find(
      (client) => client.codigoCliente === value
    );
    form.setFieldValue("domicilio", cliente?.domicilio);
    form.setFieldValue("nombreCliente", cliente?.nombre);
    form.setFieldValue("cliente", cliente);
  };
  const handleChangeNombre = (value: any, option: any) => {
    const cliente = clientsData.find(
      (client) => client.codigoCliente === option.key
    );
    form.setFieldValue("domicilio", cliente?.domicilio);
    form.setFieldValue("codigoCliente", cliente?.codigoCliente);
    form.setFieldValue("cliente", cliente);
  };

  const {
    clients: { isLoading, clientsData },
  } = useAppSelector((state) => state);
  return (
    <>
      <Form.Item label="Cliente" name="codigoCliente">
        <Select
          loading={isLoading}
          onChange={handleChangeCodigo}
          showSearch
          options={clientsData.map((cliente) => ({
            key: cliente.codigoCliente,
            label: cliente.codigoCliente,
            value: cliente.codigoCliente,
          }))}
        />
      </Form.Item>
      <Form.Item name="nombreCliente" label="Cliente">
        <Select
          loading={isLoading}
          onChange={handleChangeNombre}
          showSearch
          filterOption={(input, option) => {
            if (!option) return false;
            if (!option.value) return false;
            return (
              option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          options={clientsData.map((cliente) => ({
            key: cliente.codigoCliente,
            label: `${cliente.codigoCliente} - ${cliente.nombre}`,
            value: `${cliente.codigoCliente} - ${cliente.nombre}`,
          }))}
        />
      </Form.Item>
      <Form.Item name="cliente" label="Cliente" hidden>
        <Input className="input-disabled-remarked" disabled />
      </Form.Item>
      <Form.Item name="domicilio" label="Domicilio">
        <Input className="input-disabled-remarked" disabled />
      </Form.Item>
      <Form.Item name="fechaServicio" label="Fecha del servicio">
        <DatePicker locale={locale} />
      </Form.Item>
    </>
  );
};

const AltaCliente = ({ form }: { form: FormInstance }) => {
  return (
    <>
      <Form.Item label="Número de cliente" name="codigoCliente">
        <Input />
      </Form.Item>
      <Form.Item name="nombre" label="Nombre de cliente">
        <Input />
      </Form.Item>
      <Form.Item name="formatoAlta" label="Formato de alta">
        <Upload>
          <Button className="flex">
            <BsUpload className="mt-1 mr-1" />
            Elegir archivo...
          </Button>
        </Upload>
      </Form.Item>
    </>
  );
};

const Subformas = {
  SuspensionActivacion,
  ServicioEspecial,
  AltaCliente,
};

export default Subformas;
