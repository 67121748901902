import { Button, Checkbox, Divider, Form } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useForm } from "antd/es/form/Form";
import ExcelJS from "exceljs";
import moment from "moment";
import { useState } from "react";
import { buildManifiestosUrl } from "../../redux/actions/manifiestos.actions";
import { API_BASE_URL } from "../../utils/api";
import { useAppSelector } from "../../hooks";

const allOptions = [
  "folio",
  "codigoCliente",
  "nombreCliente",
  "dia",
  "fecha",
  "hora",
  "cantidad",
  "codigo_producto",
  "size",
  "chofer",
  "ruta",
  "observaciones",
  "domicilio",
  "recibioDinero",
  "dinero",
];

const columnsMap: any = {
  folio: { header: "Folio", key: "folio", width: 10 },
  codigoCliente: { header: "Numero Cliente", key: "codigoCliente", width: 20 },
  nombreCliente: { header: "Nombre Cliente", key: "nombreCliente", width: 50 },
  dia: { header: "Dia de la semana", key: "dia", width: 50 },
  fecha: { header: "Fecha", key: "fecha", width: 50 },
  hora: { header: "Hora", key: "hora", width: 50 },
  cantidad: { header: "Cantidad", key: "cantidad", width: 10 },
  codigo_producto: { header: "Producto", key: "codigo_producto", width: 10 },
  size: { header: "Tamaño", key: "size", width: 10 },
  chofer: { header: "Chofer", key: "chofer", width: 10 },
  ruta: { header: "Ruta", key: "ruta", width: 10 },
  observaciones: { header: "Observaciones", key: "observaciones", width: 10 },
  domicilio: { header: "Domicilio", key: "domicilio", width: 10 },
  recibioDinero: { header: "Recibio dinero", key: "recibioDinero", width: 10 },
  dinero: { header: "Cuanto", key: "dinero", width: 10 },
};
const ExcelReportGenerator = () => {
  const [formRef] = useForm();
  const {
    manifiestos: { manifiestosCount, filters },
  } = useAppSelector((state) => state);
  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(allOptions);
  const checkAll = allOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < allOptions.length;
  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? allOptions : []);
    formRef.setFieldValue("columns", e.target.checked ? allOptions : []);
  };

  const handleDownloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Manifiestos");
    const selectedColumns = checkedList.map(
      (key) => columnsMap[key.toString()]
    );
    sheet.columns = selectedColumns;
    let manifiestos: any[] = [];
    for (let i = 0; i * 100 < manifiestosCount; i++) {
      let url = `${API_BASE_URL}/serviceOrders?`;
      url = buildManifiestosUrl(url, {
        ...filters,
        pageSize: 100,
        currentPage: i,
      });
      const response = await fetch(url);
      const { rows } = await response.json();
      manifiestos = manifiestos.concat(rows);
    }
    manifiestos.forEach((order: any) => {
      order.recoleccions.forEach((recoleccion: any) => {
        sheet.addRow({
          folio: order.folio,
          codigoCliente: order.codigoCliente,
          nombreCliente: order.nombreCliente,
          dia: moment(order.fechaHora).format("dddd"),
          fecha: moment(order.fechaHora).format("DD/MM/YYYY"),
          hora: moment(order.fechaHora).format("hh:mm A"),
          cantidad: recoleccion.cantidad,
          codigo_producto: recoleccion.codigo_producto,
          size: recoleccion.size,
          chofer: order.chofer,
          ruta: order.ruta,
          observaciones: order.observaciones,
          fotos: JSON.parse(order.fotos).map((foto: string) => `'${foto}'`),
          domicilio: order.domicilio,
          recibioDinero: order.recibioDinero,
          dinero: order.dinero,
        });
      });
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const excelUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", "ordenes.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  return (
    <Form
      form={formRef}
      onFinish={handleDownloadExcel}
      initialValues={{ columns: allOptions }}
    >
      <Divider>Columnas</Divider>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        Incluir todas
      </Checkbox>
      <Form.Item name={"columns"}>
        <Checkbox.Group
          value={checkedList}
          onChange={onChange}
          className="flex flex-wrap justify-between w-full"
          options={[
            {
              style: { width: 150, marginLeft: "8px" },
              label: "Folio",
              value: "folio",
            },
            {
              style: { width: 150 },
              label: "Numero Cliente",
              value: "codigoCliente",
            },
            {
              style: { width: 150 },
              label: "Nombre Cliente",
              value: "nombreCliente",
            },
            { style: { width: 150 }, label: "Dia de la semana", value: "dia" },
            { style: { width: 150 }, label: "Fecha", value: "fecha" },
            { style: { width: 150 }, label: "Hora", value: "hora" },
            { style: { width: 150 }, label: "Cantidad", value: "cantidad" },
            {
              style: { width: 150 },
              label: "Producto",
              value: "codigo_producto",
            },
            { style: { width: 150 }, label: "Tamaño", value: "size" },
            { style: { width: 150 }, label: "Chofer", value: "chofer" },
            { style: { width: 150 }, label: "Ruta", value: "ruta" },
            {
              style: { width: 150 },
              label: "Observaciones",
              value: "observaciones",
            },
            { style: { width: 150 }, label: "Domicilio", value: "domicilio" },
            {
              style: { width: 150 },
              label: "Recibio dinero",
              value: "recibioDinero",
            },
            { style: { width: 150 }, label: "Cuanto", value: "dinero" },
          ]}
        ></Checkbox.Group>
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Descargar reporte
      </Button>
    </Form>
  );
};

export default ExcelReportGenerator;
