import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import locale from "antd/es/date-picker/locale/es_ES";
import {
  BsCheckCircleFill,
  BsDashCircleFill,
  BsFilePdfFill,
  BsPlusCircleFill,
  BsReceipt,
  BsSearch,
  BsXCircleFill,
} from "react-icons/bs";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchClientsData } from "../../redux/actions/client.actions";
import {
  ClientEcomexico,
  ListaPrecios,
  ListaPreciosDataItem,
} from "../../utils/types";
import DisplayDias from "../../components/Prefactura/DisplayDias";
import {
  fetchPDF,
  getManifiestos,
  openPDFViewer,
  resetReport,
  setFilters,
} from "../../redux/actions/manifiestos.actions";
import { ManifiestoWithRecolecciones } from "../../redux/reducers/manifiestos";
import { useForm, useWatch } from "antd/es/form/Form";
import dayjs, { Dayjs } from "dayjs";
import { getListasPrecios } from "../../redux/actions/listasPrecios.actions";
import AgregarRecoleccionForm from "../../components/EditorManifiestos/AgregarRecoleccionForm";
import { fetchProducts } from "../../redux/actions/productos.actions";
import {
  getPrefacturaById,
  resetPrefacturasState,
  saveFactura,
  saveNewPrefactura,
} from "../../redux/actions/prefacturas.actions";
import BotonAtras from "../../components/BotonAtras";

const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";
const currencyFormater = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

interface ItemFacturar {
  cantidad: number;
  producto: string;
  size: string;
  precio: number;
}
const PrefacturaTool = () => {
  /**UTILS */
  const { id, clientId } = useParams();

  const navigate = useNavigate();
  const [formRef] = useForm();
  const dateRange: [Dayjs, Dayjs] = useWatch("periodo", formRef);
  const dispatch = useAppDispatch();
  /**REDUX */
  const {
    clients: { clientsData: clientes, isLoading: clientsLoading },
    listaPrecios: { listaPrecios: listasPrecios },
    manifiestos: { isLoading: reportLoading, manifiestos: ordenes },
    prefacturas: { isLoadingPrefactura, currentPrefactura },
  } = useAppSelector((state) => state);

  /**INNER STATE */
  const [clientData, setClientData] = useState<ClientEcomexico>();
  const [sucursales, setSucursales] = useState<string[]>(
    clientId ? [clientId] : []
  );
  const [listaPreciosClient, setListaPreciosClient] = useState<ListaPrecios>();
  const [ordenesParaPrefactura, setOrdenesParaPrefactura] = useState<
    ManifiestoWithRecolecciones[]
  >([]);
  const [itemsAFacturar, setItemsAFacturar] = useState<ItemFacturar[]>([]);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [totalFactura, setTotalFactura] = useState(0);

  useEffect(() => {
    dispatch(fetchClientsData());
    dispatch(getListasPrecios());
    dispatch(fetchProducts());

    return () => {
      dispatch(resetReport());
      dispatch(fetchClientsData());
      dispatch(setFilters({}));
      dispatch(resetReport());
      dispatch(resetPrefacturasState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getPrefacturaById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (currentPrefactura) {
      setSucursales(currentPrefactura.sucursalesIncluidas);
      setClientData(currentPrefactura.cliente);
      setItemsAFacturar(currentPrefactura.prefacturas_items);
      formRef.setFieldsValue({
        conceptoFactura: currentPrefactura.comentarioRecomendado,
        comentarioInterno: currentPrefactura.comentarioInterno,
        periodo: [
          dayjs(currentPrefactura.periodoStart, "YYYY-MM-DD"),
          dayjs(currentPrefactura.periodoEnd, "YYYY-MM-DD"),
        ],
      });
      dispatch(setFilters({ folios: currentPrefactura.manifiestosId }));
      dispatch(getManifiestos());
    }
  }, [currentPrefactura, dispatch, formRef]);

  useEffect(() => {
    if (clientId) {
      const clientFound = clientes.find(
        (client) => client.codigoCliente === clientId
      );
      if (clientFound) setClientData(clientFound);
    }
  }, [clientId, clientes]);

  useEffect(() => {
    setOrdenesParaPrefactura(ordenes);
  }, [ordenes]);

  const addItemInfo = (
    currentItemArray: ItemFacturar[],
    item: ItemFacturar
  ) => {
    const index = currentItemArray.findIndex(
      (current) =>
        current.producto === item.producto && current.size === item.size
    );
    if (index === -1) {
      currentItemArray.push(item);
    } else {
      currentItemArray[index].cantidad += item.cantidad;
    }
  };

  useEffect(() => {
    if (id) return;
    if (clientData && ordenesParaPrefactura.length > 0) {
      const listaPrecios = listasPrecios.find(
        (lista: ListaPrecios) => lista.id === clientData?.listaPreciosId
      );
      if (listaPrecios) {
        setListaPreciosClient(listaPrecios);
        const items = ordenesParaPrefactura.reduce(
          (prev: ItemFacturar[], current) => {
            const itemsCalc = [...prev];
            const precioPorOrden = current.recoleccions.reduce((pre, cur) => {
              const precioItem = listaPrecios.data.find(
                (lista: ListaPreciosDataItem) =>
                  lista.producto === cur.codigo_producto &&
                  lista.size === cur.size
              );
              if (precioItem) {
                return pre + precioItem.precio * cur.cantidad;
              }
              return pre;
            }, 0);
            if (precioPorOrden < 165) {
              addItemInfo(itemsCalc, {
                cantidad: 1,
                producto: "SRR",
                size: "",
                precio: 165,
              });
            } else {
              current.recoleccions.forEach((recoleccion) => {
                const precioItem = listaPrecios.data.find(
                  (lista: ListaPreciosDataItem) =>
                    lista.producto === recoleccion.codigo_producto &&
                    lista.size === recoleccion.size
                );
                addItemInfo(itemsCalc, {
                  cantidad: recoleccion.cantidad,
                  producto: recoleccion.codigo_producto,
                  size: recoleccion.size,
                  precio: precioItem?.precio || 0,
                });
              });
            }
            return itemsCalc;
          },
          []
        );

        setItemsAFacturar(items);
      }
    }
    if (ordenesParaPrefactura.length === 0) {
      setItemsAFacturar([]);
    }
  }, [ordenesParaPrefactura, clientData, listasPrecios, id]);

  useEffect(() => {
    const total = itemsAFacturar.reduce((prev, curr) => {
      return prev + curr.cantidad * curr.precio;
    }, 0);
    setTotalFactura(total);
  }, [itemsAFacturar]);

  const handleSucursalesDeselect = (value: string | undefined) => {
    if (value === undefined) return;
    if (sucursales.length === 1 || value === clientId) {
      return;
    }
    const index = sucursales.findIndex((sucursal) => sucursal === value);
    const newSucursales = sucursales
      .slice(0, index)
      .concat(sucursales.slice(index + 1));
    setSucursales(newSucursales);
  };

  const handleSucursalesSelect = (value: string | undefined) => {
    if (value === undefined) return;
    const newSucursales = [...sucursales];
    newSucursales.push(value);
    setSucursales(newSucursales);
  };

  const handleBuscarManifiestos = () => {
    if (!dateRange) return;
    const filters = {
      aprobados: true,
      codigoClientes: sucursales,
      startDate: dateRange[0].format("DD-MM-YYYY"),
      endDate: dateRange[1].format("DD-MM-YYYY"),
      facturado: false,
    };
    dispatch(setFilters(filters));
    dispatch(getManifiestos());
  };

  const handleDownloadPDF = (order: any) => {
    dispatch(fetchPDF([order]));
    dispatch(openPDFViewer({ open: true }));
  };

  const removeOrdenServicio = (index: number) => {
    const newOrdenes = [...ordenesParaPrefactura];
    newOrdenes.splice(index, 1);
    setOrdenesParaPrefactura(newOrdenes);
  };

  const removeItemAFacturar = (index: number) => {
    const items = [...itemsAFacturar];
    items.splice(index, 1);
    setItemsAFacturar(items);
  };

  const handleChangePrice = (index: number, value: string) => {
    const items = [...itemsAFacturar];
    items[index].precio = parseFloat(value);
    setItemsAFacturar(items);
  };
  const handleItemAFacturarRemove = (index: number) => {
    const items = [...itemsAFacturar];
    items[index].cantidad--;
    setItemsAFacturar(items);
  };

  const handleItemAFacturarAdd = (index: number) => {
    const items = [...itemsAFacturar];
    items[index].cantidad++;
    setItemsAFacturar(items);
  };

  const handleAddItem = (
    cantidad: number,
    codigoProducto: string,
    size: string
  ) => {
    const items = [...itemsAFacturar];
    const precio = listaPreciosClient?.data.find(
      (elemento) =>
        elemento.producto === codigoProducto && elemento.size === size
    );
    items.push({
      cantidad: cantidad,
      precio: precio?.precio || 0,
      producto: codigoProducto,
      size: size,
    });
    setItemsAFacturar(items);
  };

  const openConfirmModal = () => {
    setIsModalConfirmOpen(true);
  };

  const handleGenerarPrefactura = async () => {
    const values = formRef.getFieldsValue();
    if (dateRange === undefined) {
      notification.error({ message: "Elige el periodo de la factura" });
    } else {
      const payload = {
        codigoCliente: clientId,
        comentarioRecomendado: values.conceptoFactura || ``,
        comentarioInterno: values.comentarioInterno || ``,
        sucursalesIncluidas: sucursales,
        manifiestos: ordenesParaPrefactura,
        items: itemsAFacturar,
        periodoStart: dateRange[0].format("YYYY-MM-DD"),
        periodoEnd: dateRange[1].format("YYYY-MM-DD"),
      };
      await dispatch(saveNewPrefactura(payload));
      navigate(-1);
    }
    setIsModalConfirmOpen(false);
  };

  const handleFacturar = async () => {
    if (!id) return;
    const values = formRef.getFieldsValue();

    const payload = {
      facturaId: values.facturaId,
      facturaFecha: values.facturaFecha.format("YYYY-MM-DD"),
    };
    await dispatch(saveFactura(id, payload));
    setIsModalConfirmOpen(false);
    navigate(-1);
  };

  return (
    <Form form={formRef} onFinish={openConfirmModal} className="p-5">
      <Modal open={isModalConfirmOpen} closable={false} footer={null}>
        {id ? (
          <>
            <p className="flex justify-center text-xl">
              Información de la factura
            </p>
            <Form.Item name={"facturaId"} label={"Folio factura"}>
              <Input placeholder="Ejemplo: F0000015" />
            </Form.Item>
            <Form.Item
              name={"facturaFecha"}
              label={"Fecha factura"}
              initialValue={dayjs()}
            >
              <DatePicker />
            </Form.Item>
            <div className="flex justify-around">
              <Button type="primary" onClick={handleFacturar}>
                Confirmar
              </Button>
              <Button onClick={() => setIsModalConfirmOpen(false)}>
                Cancelar
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="flex justify-center text-xl">
              ¿Seguro que deseas generar la prefactura?
            </p>
            <p className="text-center">
              La prefactura estara disponible para que el personal de
              facturación genere la factura
            </p>
            <div className="flex justify-around">
              <Button type="primary" onClick={handleGenerarPrefactura}>
                Confirmar
              </Button>
              <Button onClick={() => setIsModalConfirmOpen(false)}>
                Cancelar
              </Button>
            </div>
          </>
        )}
      </Modal>
      {clientsLoading || isLoadingPrefactura ? (
        <Spin />
      ) : (
        <>
          <h1 className="text-2xl text-green-800">
            {id ? `Facturar` : `Nueva factura - ${clientData?.nombre}`}
          </h1>
          <BotonAtras />
          <div className="flex gap-2">
            <div className="flex w-1/2 flex-wrap justify-between mt-3 px-4 py-2 border rounded-md shadow-sm">
              <div className="w-full">
                {!id && (
                  <>
                    <Divider>Dias de recolección</Divider>
                    {clientData && <DisplayDias cliente={clientData} />}
                    <Divider>Adicionales</Divider>
                  </>
                )}
              </div>
              <Form.Item label="Sucursales" className="w-full">
                <Select
                  disabled={Boolean(id)}
                  mode="multiple"
                  allowClear={false}
                  placeholder="Selecciona los clientes que formaran parte de la factura."
                  options={clientes.map((current) => ({
                    label: current.codigoCliente,
                    value: current.codigoCliente,
                  }))}
                  onDeselect={handleSucursalesDeselect}
                  onSelect={handleSucursalesSelect}
                  value={sucursales}
                />
              </Form.Item>
              <Form.Item label="Periodo" name="periodo">
                <RangePicker
                  disabled={Boolean(id)}
                  size="large"
                  allowClear={false}
                  format={dateFormat}
                  locale={locale}
                  allowEmpty={[false, false]}
                  defaultValue={undefined}
                  onChange={(value) =>
                    formRef.setFieldValue(
                      "conceptoFactura",
                      Array.isArray(value) && value[0] && value[1]
                        ? `SERVICIO DE RECOLECCIÓN DE BASURA DEL DIA ${value[0]
                            .format("DD-MMM-YYYY")
                            .toUpperCase()} AL ${value[1]
                            .format("DD-MMM-YYYY")
                            .toUpperCase()} GUADALAJARA JALISCO`
                        : "SERVICIO DE RECOLECCION DE BASURA"
                    )
                  }
                />
              </Form.Item>
              {!id && (
                <Button
                  onClick={handleBuscarManifiestos}
                  type="primary"
                  className="flex"
                >
                  <BsSearch className="mt-1 mr-1" />
                  Buscar manifiestos
                </Button>
              )}
            </div>
            <div className="w-1/2 px-4 py-2 mt-3 border rounded-md shadow-sm items-center">
              <Divider>
                {id ? `Items a facturar` : `Sugerencias de items a facturar`}
              </Divider>
              <Table
                dataSource={itemsAFacturar}
                loading={reportLoading}
                columns={[
                  {
                    dataIndex: "cantidad",
                    title: "Cantidad",
                    render: (value, record, index) =>
                      id ? (
                        <div className="text-center">{value}</div>
                      ) : (
                        <div className="flex justify-between items-center">
                          <Button
                            type="text"
                            onClick={() => handleItemAFacturarRemove(index)}
                          >
                            <BsDashCircleFill />
                          </Button>
                          {value}
                          <Button
                            type="text"
                            onClick={() => handleItemAFacturarAdd(index)}
                          >
                            <BsPlusCircleFill />
                          </Button>
                        </div>
                      ),
                  },
                  {
                    dataIndex: "product",
                    title: "Descripción",
                    render: (value, record) => (
                      <p>{record.producto + " " + record.size}</p>
                    ),
                  },
                  {
                    dataIndex: "precio",
                    title: "Precio c/u",
                    render: (value, record, index) =>
                      id ? (
                        <>$ {value}</>
                      ) : (
                        <InputNumber
                          value={value}
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          onChange={(value) => handleChangePrice(index, value)}
                          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                        />
                      ),
                  },
                  {
                    dataIndex: "precio",
                    title: "Total",
                    render: (value, record, index) => {
                      return currencyFormater.format(
                        record.cantidad * record.precio
                      );
                    },
                  },
                  {
                    dataIndex: "id",
                    title: "",
                    render: (value, record, index) =>
                      id ? (
                        <></>
                      ) : (
                        <Button
                          type="primary"
                          className="bg-red-500 hover:bg-red-700"
                          onClick={() => removeItemAFacturar(index)}
                        >
                          <BsXCircleFill />
                        </Button>
                      ),
                  },
                ]}
                pagination={false}
              />
              {!id && <AgregarRecoleccionForm onAdd={handleAddItem} />}
              <Divider orientation="right">
                Total: {currencyFormater.format(totalFactura)}
              </Divider>
            </div>
          </div>
          <div className="flex border rounded-md shadow-sm mt-3 py-2 px-4">
            <div className="w-3/5">
              <Divider>Ordenes de servicio</Divider>
              <Table
                dataSource={ordenesParaPrefactura}
                loading={reportLoading}
                columns={[
                  {
                    dataIndex: "folio",
                    title: "Folio",
                    sorter: (a, b) => a.folio.localeCompare(b.folio),
                  },
                  {
                    dataIndex: "fechaHora",
                    title: "Fecha",
                    sorter: (a, b) => a.fechaHora.localeCompare(b.fechaHora),
                    render: (value) => dayjs(value).format("ddd DD/MM/YYYY"),
                  },
                  {
                    dataIndex: "codigoCliente",
                    title: "# Cliente",
                    sorter: (a, b) =>
                      a.codigoCliente.localeCompare(b.codigoCliente),
                  },
                  {
                    dataIndex: "recoleccions",
                    title: "Items recolectados",
                    render: (recolecciones: any[]) => {
                      return (
                        <div>
                          {recolecciones.map((recoleccion, index) => (
                            <p key={index}>
                              {`${recoleccion.cantidad} ${recoleccion.codigo_producto} ${recoleccion.size}`}
                            </p>
                          ))}
                        </div>
                      );
                    },
                  },
                  {
                    dataIndex: "folio",
                    title: "Acciones",
                    render: (value, record, index) => (
                      <div className="flex">
                        <Button
                          type="primary"
                          className="bg-blue-500 hover:bg-blue-700 mr-2"
                          onClick={() => handleDownloadPDF(record)}
                        >
                          <BsFilePdfFill />
                        </Button>
                        {!id && (
                          <Button
                            type="primary"
                            className="bg-red-500 hover:bg-red-700"
                            onClick={() => removeOrdenServicio(index)}
                          >
                            <BsXCircleFill />
                          </Button>
                        )}
                      </div>
                    ),
                  },
                ]}
                pagination={false}
              />
            </div>
            <div className="w-2/5 p-5">
              <Form.Item
                name="conceptoFactura"
                label="Concepto en la factura"
                labelCol={{ span: 24 }}
              >
                <Input.TextArea
                  disabled={Boolean(id)}
                  className="border rounded-md p-2 bg-slate-200 text-black cursor-text"
                  rows={5}
                  defaultValue={"SERVICIO DE RECOLECCIÓN DE BASURA"}
                />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 24 }}
                name="comentarioInterno"
                label="Comentario interno"
              >
                <Input.TextArea
                  rows={5}
                  disabled={Boolean(id)}
                  className="border rounded-md p-2 bg-slate-200 text-black cursor-text"
                  placeholder="Dejar cualquier comentario para la persona que va a facturar"
                />
              </Form.Item>
              {id ? (
                <Button
                  onClick={openConfirmModal}
                  type="primary"
                  className="flex float-right"
                >
                  <BsReceipt className="mt-1 mr-2 " />
                  Facturar
                </Button>
              ) : (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="flex float-right"
                >
                  <BsCheckCircleFill className="mt-1 mr-2 " /> Aprobar
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default PrefacturaTool;
