import { Reducer } from "redux";
import {
  ClientEcomexico,
  CompleteClientInfo,
  Product,
} from "../../utils/types";
import { ActionTypes } from "../actions/client.actions";
interface IReducer {
  isLoading: boolean;
  hasErrors: boolean;
  errors: string[];
  isClientEditorOpen?: boolean;
  isOneClientLoading?: boolean;
  clientIdEditing?: string;
  clientNameEditing?: string;
  currentClient?: CompleteClientInfo;
  clientsData: CompleteClientInfo[];
  isLoadingProducts?: boolean;
  products?: Product[];
  isLoadingSave?: boolean;
  clientSaved?: boolean;
  filteredClients?: ClientEcomexico[];
}

const initialState: IReducer = {
  isLoading: false,
  hasErrors: false,
  errors: [],
  isClientEditorOpen: false,
  isOneClientLoading: false,
  currentClient: undefined,
  clientsData: [],
  clientIdEditing: undefined,
  clientNameEditing: undefined,
  isLoadingProducts: false,
  products: [],
  isLoadingSave: false,
  clientSaved: false,
  filteredClients: [],
};

const ClientsReducer: Reducer<IReducer, any> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.START_FETCH_CLIENTS:
      return {
        ...state,
        isLoading: true,
        hasErrors: false,
        errors: [],
        clientsData: [],
        filteredClients: [],
      };
    case ActionTypes.SUCCESS_FETCH_CLIENTS:
      return {
        ...state,
        isLoading: false,
        clientsData: action.payload,
        filteredClients: action.payload,
      };
    case ActionTypes.APPLY_FILTERS:
      return {
        ...state,
        filteredClients: action.payload,
      };
    case ActionTypes.FAILED_FETCH_CLIENTS:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    case ActionTypes.OPEN_CLIENT_EDITOR:
      return {
        ...state,
        isClientEditorOpen: action.payload.open,
        clientIdEditing: action.payload.clientId,
        clientNameEditing: action.payload.clientName,
      };
    case ActionTypes.ADD_TRANSLATION_ENTRY:
    case ActionTypes.DELETE_TRANSLATION_ENTRY:
      return {
        ...state,
        currentClient: action.payload,
      };
    case ActionTypes.START_FETCH_ONE_CLIENT:
      return {
        ...state,
        isOneClientLoading: true,
        currentClient: undefined,
        hasErrors: false,
        errors: [],
      };

    case ActionTypes.SUCCESS_FETCH_ONE_CLIENT:
      return {
        ...state,
        isOneClientLoading: false,
        currentClient: action.payload,
      };
    case ActionTypes.FAILED_FETCH_ONE_CLIENT:
      return {
        ...state,
        isOneClientLoading: false,
        hasErrors: true,
        errors: action.payload,
      };

    case ActionTypes.START_FETCH_ALL_PRODUCTS:
      return {
        ...state,
        hasErrors: false,
        errors: [],
        isLoadingProducts: true,
      };
    case ActionTypes.SUCCESS_FETCH_ALL_PRODUCTS:
      return {
        ...state,
        isLoadingProducts: false,
        products: action.payload,
      };
    case ActionTypes.FAILED_FETCH_ALL_PRODUCTS:
      return {
        ...state,
        isLoadingProducts: false,
        errors: action.payload,
        hasError: true,
      };
    case ActionTypes.START_SAVE_CLIENT:
      return {
        ...state,
        isLoadingSave: true,
        hasErrors: false,
        errors: [],
        clientSaved: false,
      };
    case ActionTypes.SUCCESS_SAVE_CLIENT:
      return {
        ...state,
        isLoadingSave: false,
        clientSaved: true,
      };
    case ActionTypes.FAILED_SAVE_CLIENT:
      return {
        ...state,
        isLoadingSave: false,
        clientSaved: false,
        errors: action.payload,
      };

    case "RESET-ERRORS":
      return {
        ...state,
        hasErrors: false,
        errors: [],
      };
    default:
      return state;
  }
};

export default ClientsReducer;
