import { ListaPrecios } from "../../utils/types";
import { ActionTypes } from "../actions/listasPrecios.actions";

interface IReducer {
  isLoading: boolean;
  isLoadingOne: boolean;
  isSaveLoading: boolean;
  isDeleteLoading: boolean;
  hasErrors: boolean;
  errors: string[];
  listaPrecios: ListaPrecios[];
  currentListaPrecios?: ListaPrecios;
}

const initialState: IReducer = {
  isLoading: false,
  isLoadingOne: false,
  isSaveLoading: false,
  isDeleteLoading: false,
  hasErrors: false,
  errors: [],
  listaPrecios: [],
  currentListaPrecios: undefined,
};

const ListaPreciosReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // GET ONE
    case ActionTypes.GET_ONE_LISTAS_PRECIOS_START:
      return {
        ...state,
        isLoadingOne: true,
        hasErrors: false,
        errors: [],
        currentListaPrecios: null,
      };
    case ActionTypes.GET_ONE_LISTAS_PRECIOS_SUCCESS:
      return {
        ...state,
        isLoadingOne: false,
        currentListaPrecios: action.payload,
      };
    case ActionTypes.GET_ONE_LISTAS_PRECIOS_ERROR:
      return {
        ...state,
        isLoadingOne: false,
        hasErrors: true,
        errors: action.payload,
      };
    // GET MANY
    case ActionTypes.GET_LISTAS_PRECIOS_START:
      return {
        ...state,
        isLoading: true,
        hasErrors: false,
        errors: [],
        listaPrecios: [],
      };
    case ActionTypes.GET_LISTAS_PRECIOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listaPrecios: action.payload,
      };
    case ActionTypes.GET_LISTAS_PRECIOS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    // SAVE
    case ActionTypes.SAVE_LISTAS_PRECIOS_START:
      return {
        ...state,
        isSaveLoading: true,
        hasErrors: false,
        errors: [],
      };
    case ActionTypes.SAVE_LISTAS_PRECIOS_SUCCESS:
      return {
        ...state,
        isSaveLoading: false,
      };
    case ActionTypes.SAVE_LISTAS_PRECIOS_ERROR:
      return {
        ...state,
        isSaveLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    // DELETE
    case ActionTypes.DELETE_LISTAS_PRECIOS_START:
      return {
        ...state,
        isDeleteLoading: true,
        hasErrors: false,
        errors: [],
      };
    case ActionTypes.DELETE_LISTAS_PRECIOS_SUCCESS:
      return {
        ...state,
        isDeleteLoading: false,
      };
    case ActionTypes.DELETE_LISTAS_PRECIOS_ERROR:
      return {
        ...state,
        isDeleteLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default ListaPreciosReducer;
