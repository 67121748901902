import { Form, Input, Modal, Select, Spin, notification } from "antd";
import { useAppSelector } from "../../hooks";
import { Product } from "../../utils/types";
import { useForm, useWatch } from "antd/es/form/Form";
import { useEffect, useState } from "react";
interface IProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  recoleccion: any;
  onFinishEdit: (
    cantidad: number,
    codigoProducto: string,
    size: string
  ) => void;
}

const EditarRecoleccionForm = ({
  isOpen,
  setIsOpen,
  recoleccion,
  onFinishEdit,
}: IProps) => {
  const [form] = useForm();
  const codigoProducto = useWatch("codigo_producto", form);
  const [sizes, setSizes] = useState<any[]>([]);
  /**REDUX */
  const products: Product[] = useAppSelector(
    (state) => state.products.products
  );
  const productsData = useAppSelector((state) => {
    const productos = state.products.products;
    const data = new Set<string>();
    productos.forEach(({ producto }: Product) => {
      data.add(producto);
    });
    return Array.from(data);
  });
  const isProductosLoading = useAppSelector(
    (state) => state.products.isLoading
  );

  useEffect(() => {
    const newSizes = products
      .filter((product) => product.producto === codigoProducto)
      .map(({ size }) => ({
        label: size,
        value: size,
      }));
    setSizes(newSizes);
  }, [codigoProducto, products]);

  const handleEditRecoleccion = () => {
    const { cantidad, codigo_producto, size } = form.getFieldsValue();

    if (!cantidad || cantidad === "" || parseInt(cantidad) < 1) {
      notification.error({ message: "Escribe la cantidad" });
      return;
    }
    if (!codigo_producto || codigo_producto === "") {
      notification.error({ message: "Elige el producto" });
      return;
    }
    if (!size || size === "") {
      notification.error({ message: "Elige el tamaño" });
      return;
    }
    onFinishEdit(cantidad, codigo_producto, size);
    setIsOpen(false);
  };
  const handleCancelEditRecoleccion = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      open={isOpen}
      closable
      onCancel={handleCancelEditRecoleccion}
      destroyOnClose
      onOk={handleEditRecoleccion}
      title={"Editar recolección"}
    >
      {isProductosLoading ? (
        <Spin />
      ) : (
        <Form
          id="recoleccion-editor"
          form={form}
          initialValues={{ ...recoleccion }}
        >
          <Form.Item className="mr-2" label="Cantidad" name={"cantidad"}>
            <Input type="number" placeholder="Cantidad" size="large" />
          </Form.Item>
          <Form.Item className="mr-2" label="Producto" name={"codigo_producto"}>
            <Select
              placeholder="Producto"
              size="large"
              options={productsData.map((product) => ({
                label: product,
                value: product,
              }))}
            />
          </Form.Item>
          <Form.Item className="mr-2" label="Tamaño" name={"size"}>
            <Select placeholder="Tamaño" size="large" options={sizes} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditarRecoleccionForm;
