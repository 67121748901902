import { Button } from "antd";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const BotonAtras = () => {
  const navigate = useNavigate();
  return (
    <Button className="flex" onClick={() => navigate(-1)}>
      <BsArrowLeftCircleFill className="mt-1 mr-2" /> Ir atras
    </Button>
  );
};

export default BotonAtras;
