import { Card, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { BsArrowClockwise, BsArrowRightCircleFill } from "react-icons/bs";
import {
  getManifiestosCount,
  setFilters as setManifiestosFilter,
} from "../../redux/actions/manifiestos.actions";
import {
  getPrefacturas,
  setFilters,
} from "../../redux/actions/prefacturas.actions";
import dayjs, { Dayjs } from "dayjs";
import PrefacturasDelDia from "./PrefacturasDelDia";

const FacturacionIndex = () => {
  const dispatch = useAppDispatch();
  const {
    manifiestos: { isLoading: isManifiestosLoading, manifiestosCount },
    prefacturas: { isLoading: isPrefacturasLoading },
  } = useAppSelector((state) => state);

  const [numeroPrefacturas, setNumeroPrefacturas] = useState(0);
  const [facturasPeriodo, setFacturasPeriodo] = useState(0);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([
    dayjs(),
    dayjs().add(1, "day"),
  ]);
  const syncgetManifiestos = async () => {
    await dispatch(setManifiestosFilter({ aprobados: true, facturado: false }));
    await dispatch(getManifiestosCount());
  };

  const syncFetchPrefacturas = async () => {
    dispatch(setFilters({ facturada: false }));
    const prefacturas = await dispatch(getPrefacturas());
    setNumeroPrefacturas(prefacturas.length);
  };

  const syncFacturasPeriodo = async () => {
    dispatch(
      setFilters({
        startDate: dateRange[0].format("DD-MM-YYYY"),
        endDate: dateRange[1].format("DD-MM-YYYY"),
      })
    );
    const prefacturas = await dispatch(getPrefacturas());
    setFacturasPeriodo(prefacturas.length);
  };

  useEffect(() => {
    syncFetchPrefacturas();
    syncgetManifiestos();
    syncFacturasPeriodo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-5 ">
      <h1 className="text-2xl text-green-800">Facturación</h1>
      <div className="flex flex-nowrap gap-5 justify-around">
        <Card
          className="h-56"
          title="Facturas hechas en el periodo"
          actions={[
            <BsArrowClockwise
              className="m-auto text-slate-700 hover:text-green-500"
              size={20}
              onClick={syncFacturasPeriodo}
            />,
            <Link to="list">
              <BsArrowRightCircleFill
                size={20}
                className="m-auto text-slate-700 hover:text-green-500"
              />
            </Link>,
          ]}
        >
          <div className="h-16">
            <h3 className="text-2xl text-center">{facturasPeriodo}</h3>
            <DatePicker.RangePicker
              defaultValue={dateRange}
              onChange={(value) => {
                if (value && value[0] && value[1]) {
                  setDateRange([value[0], value[1]]);
                }
              }}
            />
          </div>
        </Card>
        <Card
          className="h-56 w-56 text-center"
          loading={isPrefacturasLoading}
          title="Prefacturas"
          actions={[
            <BsArrowClockwise
              className="m-auto text-slate-700 hover:text-green-500"
              size={20}
              onClick={syncFetchPrefacturas}
            />,
            <Link to="clientes">
              <BsArrowRightCircleFill
                size={20}
                className="m-auto text-slate-700 hover:text-green-500"
              />
            </Link>,
          ]}
        >
          <div className="h-16">
            <h3 className="text-2xl text-center">{numeroPrefacturas}</h3>
          </div>
        </Card>
        <Card
          className="h-56"
          loading={isManifiestosLoading}
          title="Manifiestos sin facturar"
          actions={[
            <BsArrowClockwise
              size={20}
              className="m-auto text-slate-700 hover:text-green-500"
              onClick={syncgetManifiestos}
            />,
            <Link to="manifiestos-sin-facturar">
              <BsArrowRightCircleFill
                size={20}
                className="m-auto text-slate-700 hover:text-green-500"
              />
            </Link>,
          ]}
        >
          <div className="h-16">
            <h3 className="text-2xl text-center">{manifiestosCount}</h3>
          </div>
        </Card>
      </div>
      <PrefacturasDelDia />
    </div>
  );
};

export default FacturacionIndex;
