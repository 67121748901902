import { ActionTypes } from "../actions/formulariosConErrores.actions";

interface IReducer {
  isLoading: boolean;
  hasErrors: boolean;
  errors: string[];
  formularios: any[];
}

const initialState: IReducer = {
  isLoading: false,
  hasErrors: false,
  errors: [],
  formularios: [],
};

const FormulariosConErroresReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.START_FETCH_FORMULARIOS_ERRORES:
      return {
        ...state,
        isLoading: true,
        hasErrors: false,
        errors: [],
        formularios: [],
      };
    case ActionTypes.SUCCESS_FETCH_FORMULARIOS_ERRORES:
      return {
        ...state,
        isLoading: false,
        formularios: action.payload,
      };
    case ActionTypes.FAILED_FETCH_FORMULARIOS_ERRORES:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: [action.payload],
      };
    case "RESET-ERRORS":
      return {
        ...state,
        hasErrors: false,
        errors: [],
      };
    default:
      return state;
  }
};

export default FormulariosConErroresReducer;
