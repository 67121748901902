import { Dispatch } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../utils/api";
import { RootState } from "../store";
import { Product, TranslationSaveBody } from "../../utils/types";
export const ActionTypes = {
  // FETCH ALL PRODUCTS
  START_FETCH_PRODUCTS: "START_FETCH_PRODUCTS",
  SUCCESS_FETCH_PRODUCTS: "SUCCESS_FETCH_PRODUCTS",
  FAILED_FETCH_PRODUCTS: "FAILED_FETCH_PRODUCTS",
  // FETCH ONE PRODUCT
  START_FETCH_ONE_PRODUCT: "START_FETCH_ONE_PRODUCT",
  SUCCESS_FETCH_ONE_PRODUCT: "SUCCESS_FETCH_ONE_PRODUCT",
  FAILED_FETCH_ONE_PRODUCT: "FAILED_FETCH_ONE_PRODUCT",
  // FETCH PRODUCT TRANSLATION
  START_FETCH_PRODUCT_TRANSLATION: "START_FETCH_PRODUCT_TRANSLATION",
  SUCCESS_FETCH_PRODUCT_TRANSLATION: "SUCCESS_FETCH_PRODUCT_TRANSLATION",
  FAILED_FETCH_PRODUCT_TRANSLATION: "FAILED_FETCH_PRODUCT_TRANSLATION",
  // SAVE PRODUCT
  START_SAVE_PRODUCT: "START_SAVE_PRODUCT",
  SUCCESS_SAVE_PRODUCT: "SUCCESS_SAVE_PRODUCT",
  FAILED_SAVE_PRODUCT: "FAILED_SAVE_PRODUCT",
  // SAVE TRANSLATION
  START_SAVE_TRANSLATION: "START_SAVE_TRANSLATION",
  SUCCESS_SAVE_TRANSLATION: "SUCCESS_SAVE_TRANSLATION",
  FAILED_SAVE_TRANSLATION: "FAILED_SAVE_TRANSLATION",

  // DELETE PRODUCT
  START_DELETE_PRODUCT: "START_DELETE_PRODUCT",
  SUCCESS_DELETE_PRODUCT: "SUCCESS_DELETE_PRODUCT",
  FAILED_DELETE_PRODUCT: "FAILED_DELETE_PRODUCT",
};

export const fetchProducts = () => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.START_FETCH_PRODUCTS,
  });
  try {
    const response = await fetch(`${API_BASE_URL}/corp/products`, {
      method: "GET",
    });
    if (!response.ok) {
      dispatch({
        type: ActionTypes.FAILED_FETCH_PRODUCTS,
        payload: [response],
      });
    } else {
      const data = await response.json();
      dispatch({
        type: ActionTypes.SUCCESS_FETCH_PRODUCTS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILED_FETCH_PRODUCTS,
      payload: [error],
    });
  }
};

export const fetchOneProduct =
  (productId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.START_FETCH_ONE_PRODUCT,
    });
    try {
      const response = await fetch(
        `${API_BASE_URL}/corp/products/${productId}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        dispatch({
          type: ActionTypes.FAILED_FETCH_ONE_PRODUCT,
          payload: [response],
        });
        return;
      }
      const currentProduct: Product[] = await response.json();
      const defaultTranslations: TranslationSaveBody[] = [];
      for (let i = 0; i < currentProduct.length; i++) {
        const product = currentProduct[i];
        const apiResponse = await fetch(
          `${API_BASE_URL}/corp/translation?cliente=DEFAULT&producto=${product.producto}&size=${product.size}`
        );
        const translation = await apiResponse.json();
        if (translation.length > 0) defaultTranslations.push(translation[0]);
      }
      dispatch({
        type: ActionTypes.SUCCESS_FETCH_ONE_PRODUCT,
        payload: {
          currentProduct: currentProduct,
          defaultTranslations: defaultTranslations,
        },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FAILED_FETCH_ONE_PRODUCT,
        payload: [error],
      });
    }
  };

export const fetchProductTranslation =
  (clientId: string, productId: string, size: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({
      type: ActionTypes.START_FETCH_PRODUCT_TRANSLATION,
    });
    try {
      const response = await fetch(
        `${API_BASE_URL}/corp/translation?cliente=${clientId}&producto=${productId}&size=${size}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        dispatch({
          type: ActionTypes.FAILED_FETCH_PRODUCT_TRANSLATION,
          payload: [response],
        });
      } else {
        const data = await response.json();
        const currentTranslation = data[0];
        const state = getState();
        const translations = [...state.products.defaultTranslations];

        translations.push({
          clientId: currentTranslation.codigo_cliente,
          codigo_producto: currentTranslation.codigo_producto,
          descripcion: currentTranslation.descripcion,
          size: currentTranslation.size,
        });
        dispatch({
          type: ActionTypes.SUCCESS_FETCH_PRODUCT_TRANSLATION,
          payload: translations,
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.FAILED_FETCH_PRODUCT_TRANSLATION,
        payload: [error],
      });
    }
  };

export const saveNewProduct =
  (payload: any, isUpdate: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.START_SAVE_PRODUCT,
    });
    try {
      let URL = `${API_BASE_URL}/corp/products`;
      const response = await fetch(URL, {
        method: isUpdate ? "PUT" : "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        dispatch({
          type: ActionTypes.FAILED_SAVE_PRODUCT,
          payload: [`${response.status} - ${response.statusText}`],
        });
        return;
      }
      dispatch({ type: ActionTypes.SUCCESS_SAVE_PRODUCT });
    } catch (error) {
      dispatch({
        type: ActionTypes.FAILED_SAVE_PRODUCT,
        payload: [error],
      });
    }
  };

export const saveTranslation =
  (codigo_producto: string, size: string, descripcion: string) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.START_SAVE_TRANSLATION,
    });
    try {
      const response = await fetch(`${API_BASE_URL}/corp/translation`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({
          codigo_cliente: "DEFAULT",
          codigo_producto,
          size,
          descripcion,
        }),
      });
      if (!response.ok) {
        dispatch({
          type: ActionTypes.FAILED_SAVE_TRANSLATION,
          payload: [response],
        });
      } else {
        const data = await response.json();

        dispatch({
          type: ActionTypes.SUCCESS_SAVE_TRANSLATION,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.FAILED_SAVE_TRANSLATION,
        payload: [error],
      });
    }
  };

export const deleteProduct =
  (codigo_producto: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.START_DELETE_PRODUCT,
    });
    try {
      const response = await fetch(`${API_BASE_URL}/corp/products`, {
        method: "DELETE",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({
          producto: codigo_producto,
        }),
      });
      if (!response.ok) {
        dispatch({
          type: ActionTypes.FAILED_DELETE_PRODUCT,
          payload: [response],
        });
      } else {
        const data = await response.json();

        dispatch({
          type: ActionTypes.SUCCESS_DELETE_PRODUCT,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.FAILED_DELETE_PRODUCT,
        payload: [error],
      });
    }
  };
