import { Tag } from "antd";

import { TiposFacturacion } from "../../utils/types";
import { TIPOS_FACTURACION_VALUES } from "../TiposDeFacturacion/constants";
interface IProps {
  tipoFacturacion: TiposFacturacion;
}
const FiltrosSelectOptionFacturacion = ({ tipoFacturacion }: IProps) => {
  let tagColor = "bg-slate-500";
  let tagText = "";
  switch (tipoFacturacion.tipo) {
    case TIPOS_FACTURACION_VALUES.DIA_DEL_MES:
      tagColor = "bg-sky-500";
      tagText = `Días del mes: ${tipoFacturacion.diasDelMes}`;
      break;
    case TIPOS_FACTURACION_VALUES.DIA_DE_LA_SEMANA:
      tagColor = "bg-emerald-500";
      tagText = tipoFacturacion.diasDeLaSemana.reduce(
        (prev, curr) => prev + curr + ", ",
        ""
      );
      break;
    case TIPOS_FACTURACION_VALUES.FRECUENCIA:
      tagColor = "bg-violet-500";
      tagText = `Cada ${tipoFacturacion.frecuencia} dias`;
      break;
    case TIPOS_FACTURACION_VALUES.FECHA_ESPECIFICA:
      tagColor = "bg-slate-500";
      tagText = `${tipoFacturacion.fechaEspecifica}`;
      break;
  }

  return (
    <div className="flex">
      <Tag className={tagColor + " text-white"}>{tagText}</Tag>
      {tipoFacturacion.descripcion}
    </div>
  );
};

export default FiltrosSelectOptionFacturacion;
