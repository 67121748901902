export enum TIPOS_FACTURACION_VALUES {
  DIA_DEL_MES = "DIA_DEL_MES",
  DIA_DE_LA_SEMANA = "DIA_DE_LA_SEMANA",
  FRECUENCIA = "FRECUENCIA",
  FECHA_ESPECIFICA = "FECHA_ESPECIFICA",
}
export const TIPOS_FACTURACION = [
  {
    label: "Días fijos en el mes",
    value: TIPOS_FACTURACION_VALUES.DIA_DEL_MES,
  },
  {
    label: "Días fijos en la semana",
    value: TIPOS_FACTURACION_VALUES.DIA_DE_LA_SEMANA,
  },
  {
    label: "Cada x numero de días",
    value: TIPOS_FACTURACION_VALUES.FRECUENCIA,
  },
  {
    label: "Fecha especifica",
    value: TIPOS_FACTURACION_VALUES.FECHA_ESPECIFICA,
  },
];
