import { BsCurrencyDollar } from "react-icons/bs";
import { Product } from "../../utils/types";
import { Form, Input } from "antd";
interface IProps {
  products: Product[];
}
const ProductListPrice = ({ products }: IProps) => {
  return (
    <>
      {products.map((product, idx) => (
        <Form.Item
          key={idx}
          name={`${product.producto}_${product.size}`}
          label={`${product.producto.replaceAll(
            "-",
            " "
          )} ${product.size.replaceAll("-", " ")}`}
          className="w-96"
        >
          <Input addonBefore={<BsCurrencyDollar />} type="number" />
        </Form.Item>
      ))}
    </>
  );
};

export default ProductListPrice;
