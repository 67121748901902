import { Form, FormInstance, Input, Modal, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect } from "react";
import { fetchProducts } from "../../redux/actions/productos.actions";
import ProductListPrice from "./ProductListPrice";
import { ListaPrecios, Product } from "../../utils/types";
interface IProps {
  isModalOpen: boolean;
  formRef: FormInstance;
  mode?: "ADD" | "EDIT";
  elementToEdit?: ListaPrecios;
  handleSave: () => void;
  handleCancel: () => void;
}
const AddEditModal = ({
  isModalOpen,
  formRef,
  mode,
  elementToEdit,
  handleSave,
  handleCancel,
}: IProps) => {
  const dispatch = useAppDispatch();
  const isSaveLoading = useAppSelector(
    (state) => state.tiposFacturacion.isSaveLoading
  );
  const isLoadingProducts = useAppSelector((state) => state.products.isLoading);
  const productos: Product[] = useAppSelector(
    (state) => state.products.products
  );

  useEffect(() => {
    dispatch(fetchProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (elementToEdit) {
      formRef.setFieldValue("descripcion", elementToEdit.descripcion);
      for (let i = 0; i < elementToEdit.data.length; i++) {
        const current = elementToEdit.data[i];
        formRef.setFieldValue(
          `${current.producto}_${current.size}`,
          current.precio
        );
      }
    } else {
      formRef.setFieldValue("descripcion", "");
      formRef.resetFields();
    }
  }, [elementToEdit, formRef]);

  return (
    <Modal
      title={
        mode === "ADD" ? "Agregar lista de precios" : "Editar lista de precios"
      }
      width={850}
      open={isModalOpen}
      onOk={handleSave}
      okButtonProps={{ loading: isSaveLoading }}
      okText={"Guardar"}
      cancelText={"Cancelar"}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form form={formRef} id="tipos-facturacion-form" className="w-full">
        <Form.Item label="Descripción" name="descripcion" className="mb-2">
          <Input type="text" placeholder="Nombre de la lista de precios" />
        </Form.Item>
        {isLoadingProducts ? (
          <Spin />
        ) : (
          <div className="flex flex-row justify-around flex-wrap">
            <ProductListPrice products={productos} />
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default AddEditModal;
