import ClientsTable from "../components/ClientsTable/ClientsTable";

const Clients = () => {
  return (
    <div className="flex flex-row w-full">
      <ClientsTable />
    </div>
  );
};

export default Clients;
