import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getManifiestos,
  resetReport,
  setFilters,
  setOrderByState,
  setPaginationState,
} from "../../redux/actions/manifiestos.actions";
import { Button, Table, TableColumnType, Tooltip } from "antd";
import { ManifiestoWithRecolecciones } from "../../redux/reducers/manifiestos";
import { BsArrowRightCircleFill } from "react-icons/bs";
import moment from "moment";
import { Link } from "react-router-dom";
import ManifiestosFiltros from "./ManifiestosFiltros";
import BotonAtras from "../../components/BotonAtras";

const ManifiestosSinFacturar = () => {
  const dispatch = useAppDispatch();

  const {
    manifiestos: {
      manifiestos,
      manifiestosCount,
      isLoading,
      pageSize,
      currentPage,
    },
  } = useAppSelector((state) => state);
  const [direction, setDirection] = useState<"ASC" | "DESC">("ASC");

  const handlePageChange = (page: number, pageSize: number) => {
    dispatch(setPaginationState(page - 1, pageSize));
    dispatch(getManifiestos());
  };

  const sortingConfiguration = (
    column: TableColumnType<ManifiestoWithRecolecciones>
  ) => {
    return {
      className: "header-sorter",
      onClick: async () => {
        if (manifiestos.length === 0) return;
        setDirection((val) => (val === "ASC" ? "DESC" : "ASC"));
        await dispatch(setOrderByState(`${column.dataIndex}`, direction));
        dispatch(getManifiestos());
      },
    };
  };

  useEffect(() => {
    dispatch(setFilters({ facturado: false }));
    dispatch(getManifiestos());
    return () => {
      dispatch(resetReport());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-5">
      <h1 className="text-xl">Manifiestos</h1>
      <div className="flex gap-2">
        <BotonAtras />
        <ManifiestosFiltros />
      </div>
      <Table
        loading={isLoading}
        dataSource={manifiestos}
        showSorterTooltip={false}
        pagination={{
          current: currentPage + 1,
          total: manifiestosCount,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: handlePageChange,
        }}
        columns={[
          {
            title: "Folio",
            width: 120,
            dataIndex: "folio",
            onHeaderCell: sortingConfiguration,
          },
          {
            title: "Fecha y hora",
            dataIndex: "fechaHora",
            onHeaderCell: sortingConfiguration,
            render: (fechaHora: string) => moment(fechaHora).format("LLL"),
          },
          {
            title: "# Cliente",
            dataIndex: "codigoCliente",
            onHeaderCell: sortingConfiguration,
          },
          {
            title: "Cliente",
            className: "client-name-cell collapsed",
            dataIndex: "nombreCliente",

            render: (nombre: string) => (
              <Tooltip title={nombre}>{nombre}</Tooltip>
            ),
          },
          {
            title: "Domicilio",
            className: "domicilio-cell collapsed",
            dataIndex: "domicilio",
            render: (domicilio: string) => (
              <Tooltip title={domicilio}>{domicilio}</Tooltip>
            ),
          },
          {
            title: "Acciones",
            className: "text-center",
            key: "action",
            render: (_: any, record: ManifiestoWithRecolecciones) => (
              <div className="flex justify-center gap-1">
                <Tooltip title="Facturar">
                  <Link
                    to={`/facturacion/prefactura/nueva/${record.codigoCliente}`}
                  >
                    <Button
                      type="primary"
                      className="flex gap-2 bg-slate-600 text-white hover:bg-slate-500 rounded-md "
                    >
                      <BsArrowRightCircleFill size={15} className="pt-1" />
                      Facturar
                    </Button>
                  </Link>
                </Tooltip>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ManifiestosSinFacturar;
