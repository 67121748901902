import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/es_ES";
import { useForm } from "antd/es/form/Form";
import { setFilters } from "../../redux/actions/prefacturas.actions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { BsFilter } from "react-icons/bs";
import { TiposFacturacion } from "../../utils/types";
import FiltrosSelectOptionFacturacion from "./FiltrosSelectOptionFacturacion";

const dateFormat = "DD-MM-YYYY";
interface IProps {
  onSuccess: () => void;
}
const Filtros = ({ onSuccess }: IProps) => {
  const [open, setOpen] = useState(false);
  const [formRef] = useForm();
  const dispatch = useAppDispatch();
  const filtros = useAppSelector((state) => state.manifiestos.filters);
  const tiposFacturacion: TiposFacturacion[] = useAppSelector(
    (state) => state.tiposFacturacion.tiposFacturacion
  );
  const [fechaUltimaFactura, setFechaUltimaFactura] = useState<dayjs.Dayjs>();
  useEffect(() => {
    filtros.ultimaFacturaFecha &&
      setFechaUltimaFactura(dayjs(filtros.ultimaFacturaFecha));
  }, [filtros]);

  const fetchData = async () => {
    let clientIDF;
    let clientNameF = formRef.getFieldValue("clientNames");
    if (formRef.getFieldValue("clientIds")) {
      let text = String(formRef.getFieldValue("clientIds"));
      clientIDF = text.replaceAll(" ", "");
      clientIDF = clientIDF === "" ? undefined : clientIDF.split(",");
    }
    let tipoFacturacion = formRef.getFieldValue("tipoFacturacion");
    let hasFacturasAprobadas = formRef.getFieldValue("hasFacturasAprobadas");
    let ultimaFacturaId = formRef.getFieldValue("ultimaFacturaId");
    let ultimaFacturaFecha = formRef.getFieldValue("ultimaFacturaFecha");
    if (ultimaFacturaFecha) {
      ultimaFacturaFecha = dayjs(ultimaFacturaFecha).format("YYYY-MM-DD");
    }

    dispatch(
      setFilters({
        codigoClientes: clientIDF,
        nombreCliente: clientNameF,
        estatus: hasFacturasAprobadas,
        tipoFacturacion: tipoFacturacion,
        ultimaFacturaRegistradaId: ultimaFacturaId,
        ultimaFacturaRegistradaFecha: ultimaFacturaFecha,
      })
    ).then(() => onSuccess());

    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleBorrarFiltros = () => {
    formRef.setFieldsValue({
      datesRange: [dayjs(), dayjs()],
      orderNumbers: "",
      clientIds: "",
      clientNames: "",
      domicilio: "",
      rutas: "",
      pdfCreado: undefined,
      emailEnviado: undefined,
    });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        className="mb-2 flex items-center"
      >
        <BsFilter className="mr-2" />
        Filtros
      </Button>
      <Modal
        maskClosable={false}
        width={600}
        closable={true}
        open={open}
        title={"Filtros"}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={<></>}
      >
        <Form
          form={formRef}
          onFinish={fetchData}
          className="w-full"
          initialValues={{ ...filtros, ultimaFacturaFecha: fechaUltimaFactura }}
        >
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Número de cliente</label>
            <Form.Item name="clientIds" className="mt-1 flex-1 mb-0">
              <Input
                size="large"
                allowClear
                placeholder="Selecciona el id de los clientes a filtrar"
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Nombre del cliente</label>
            <Form.Item name="clientNames" className="mt-1 flex-1 mb-0">
              <Input
                className="w-full "
                size="large"
                allowClear
                placeholder="Selecciona el nombre de los clientes a filtrar"
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Tipo de facturación</label>
            <Form.Item name="tipoFacturacion" className="mt-1 flex-1 mb-0">
              <Select allowClear>
                {tiposFacturacion.map((tipoFac) => (
                  <Select.Option key={tipoFac.id} value={tipoFac.id}>
                    <FiltrosSelectOptionFacturacion tipoFacturacion={tipoFac} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Estatus</label>
            <Form.Item name="hasFacturasAprobadas" className="mt-1 flex-1 mb-0">
              <Select
                allowClear
                options={[
                  { label: "Sin facturas aprobadas", value: false },
                  { label: "Con facturas aprobadas", value: true },
                ]}
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Ultima factura registrada ID</label>
            <Form.Item name="ultimaFacturaId" className="mt-1 flex-1 mb-0">
              <Input
                className="w-full "
                size="large"
                allowClear
                placeholder="Selecciona el nombre de los clientes a filtrar"
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Ultima factura registrada - fecha</label>
            <Form.Item name="ultimaFacturaFecha" className="mt-1 flex-1 mb-0">
              <DatePicker
                size="large"
                allowClear={true}
                format={dateFormat}
                locale={locale}
              />
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit" className="mr-2 mt-2">
            Buscar
          </Button>
          <Button onClick={handleBorrarFiltros} type="dashed">
            Borrar filtros
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default Filtros;
