import React, { useEffect } from "react";
import BotonAtras from "../../components/BotonAtras";
import { Button, Table, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  deletePrefactura,
  getPrefacturas,
  setFilters,
  setPaginationState,
} from "../../redux/actions/prefacturas.actions";
import { Prefacturas } from "../../utils/types";
import moment from "moment";
import Filtros from "../../components/Prefactura/Filtros";
import { Link } from "react-router-dom";

const PrefacturasList = () => {
  const {
    prefacturas: {
      isLoading,
      isLoadingDelete,
      prefacturas,
      prefacturasCount,
      currentPage,
      pageSize,
    },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setFilters({}));
    dispatch(getPrefacturas());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page: number, pageSize: number) => {
    dispatch(setPaginationState(page - 1, pageSize));
    dispatch(getPrefacturas());
  };

  const handleEliminarPrefactura = async (id: string) => {
    // eslint-disable-next-line no-restricted-globals
    const response = confirm(
      `¿Seguro que deseas eliminar la prefactura ${id}? Todos los manifiestos que esten marcados con esa factura quedaran sin factura asignada`
    );
    if (response) {
      await dispatch(deletePrefactura(id));
      await dispatch(getPrefacturas());
    }
  };

  return (
    <div className="p-5">
      <h1 className="text-xl">Prefacturas</h1>
      <div className="flex gap-2">
        <BotonAtras />
        <Filtros onSuccess={() => dispatch(getPrefacturas())} />
      </div>
      <Table
        loading={isLoading || isLoadingDelete}
        dataSource={prefacturas}
        showSorterTooltip={false}
        pagination={{
          current: currentPage + 1,
          total: prefacturasCount,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: handlePageChange,
        }}
        columns={[
          {
            title: "Número prefactura",
            width: 120,
            dataIndex: "id",
          },
          {
            title: "Cliente",
            dataIndex: "codigoCliente",
          },
          {
            title: "Periodo incluido",
            dataIndex: "periodoStart",
            render: (periodoStart: string, record: Prefacturas) =>
              `${moment(periodoStart, "YYYY-MM-DD").format("LL")} al ${moment(
                record.periodoEnd,
                "YYYY-MM-DD"
              ).format("LL")}`,
          },
          {
            title: "Comentario interno",
            dataIndex: "comentarioInterno",
            render: (comentario: string) => (
              <Tooltip title={comentario}>{comentario}</Tooltip>
            ),
          },
          {
            title: "Factura",
            dataIndex: "facturada",
            render: (facturada: boolean, record: Prefacturas) =>
              !facturada ? (
                ""
              ) : (
                <div className="flex justify-center gap-1">
                  <Tooltip title="Facturar">
                    <span className="text-indigo-800 mr-2">
                      {record.facturaId}
                    </span>
                    <span className="text-orange-800">
                      {moment(record.facturaFecha, "YYYY-MM-DD").format("LL")}
                    </span>
                  </Tooltip>
                </div>
              ),
          },
          {
            title: "Acciones",
            dataIndex: "id",
            render: (id: string, record) => (
              <div className="flex gap-2">
                <Link to={`/facturacion/prefactura/${id}`}>
                  <Button type="primary">Facturar</Button>
                </Link>
                <Button
                  type="primary"
                  className="bg-red-800"
                  onClick={() => handleEliminarPrefactura(id)}
                >
                  Eliminar
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default PrefacturasList;
