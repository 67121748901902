import { Divider } from "antd";
import React from "react";
import Uploader from "../../components/Uploader";
const fotosLabels = ["FRONTAL", "DERECHA", "IZQUIERDA", "TRASERA"];
const ExpedienteVehiculo = () => {
  return (
    <div className="p-5">
      <h1 className="text-2xl">Unidad 15</h1>
      <Divider orientation="left">Fotos</Divider>
      <div className="flex gap-2 justify-between">
        {fotosLabels.map((label) => (
          <Uploader label={label} />
        ))}
      </div>
    </div>
  );
};

export default ExpedienteVehiculo;
