import { ClientEcomexico, Prefacturas } from "../../utils/types";
import { ActionTypes } from "../actions/prefacturas.actions";

interface IReducer {
  isLoading: boolean;
  isLoadingReport: boolean;
  isLoadingSave: boolean;
  isLoadingDelete: boolean;
  hasErrors: boolean;
  errors: string[];
  prefacturas: Prefacturas[];
  prefacturasCount: number;
  reportePrefacturas: ClientEcomexico[];
  filtros: {
    codigoClientes: string[];
    nombreCliente: string;
    tipoFacturacion: string;
    estatus: string;
    ultimaFacturaRegistradaId: string;
    ultimaFacturaRegistradaFecha: string;
  };
  isLoadingPrefactura: boolean;
  currentPrefactura: Prefacturas | null;
  // PAGINATION
  currentPage: number;
  pageSize: number;
  // ORDER BY
  orderBy?: [string, string];
}

const initialState: IReducer = {
  isLoading: false,
  isLoadingReport: false,
  isLoadingDelete: false,
  hasErrors: false,
  errors: [],
  prefacturas: [],
  prefacturasCount: 0,
  reportePrefacturas: [],
  filtros: {
    codigoClientes: [],
    nombreCliente: "",
    estatus: "",
    tipoFacturacion: "",
    ultimaFacturaRegistradaFecha: "",
    ultimaFacturaRegistradaId: "",
  },
  isLoadingSave: false,
  isLoadingPrefactura: false,
  currentPrefactura: null,
  currentPage: 0,
  pageSize: 25,
  orderBy: undefined,
};

const PrefacturasReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_PAGINATION_STATE:
      return {
        ...state,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.page,
      };
    case ActionTypes.SET_ORDER_BY_STATE:
      return {
        ...state,
        orderBy: action.payload,
      };
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filtros: action.payload,
      };
    case ActionTypes.RESET_PREFACTURAS_STATE:
      return initialState;
    case ActionTypes.GET_PREFACTURAS_START:
      return {
        ...state,
        isLoading: true,
        hasErrors: false,
        prefacturas: [],
        errors: [],
      };

    case ActionTypes.GET_PREFACTURAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prefacturas: action.payload,
      };

    case ActionTypes.GET_PREFACTURAS_FAILED:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload,
      };

    case ActionTypes.GET_PREFACTURA_BY_ID_START:
      return {
        ...state,
        isLoadingPrefactura: true,
        hasErrors: false,
        currentPrefactura: undefined,
        errors: [],
      };

    case ActionTypes.GET_PREFACTURA_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingPrefactura: false,
        currentPrefactura: action.payload,
      };

    case ActionTypes.GET_PREFACTURA_BY_ID_FAILED:
      return {
        ...state,
        isLoadingPrefactura: false,
        hasErrors: true,
        errors: action.payload,
      };

    case ActionTypes.GET_PREFACTURAS_REPORT_START:
      return {
        ...state,
        isLoadingReport: true,
        hasErrors: false,
        reportePrefacturas: [],
        errors: [],
      };

    case ActionTypes.GET_PREFACTURAS_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReport: false,
        reportePrefacturas: action.payload,
      };

    case ActionTypes.GET_PREFACTURAS_REPORT_FAILED:
      return {
        ...state,
        isLoadingReport: false,
        hasErrors: true,
        errors: action.payload,
      };

    case ActionTypes.SAVE_NEW_PREFACTURA_START:
      return {
        ...state,
        isLoadingSave: true,
        hasErrors: false,
        errors: [],
      };
    case ActionTypes.SAVE_NEW_PREFACTURA_SUCCESS:
      return {
        ...state,
        isLoadingSave: false,
      };
    case ActionTypes.SAVE_NEW_PREFACTURA_FAILED:
      return {
        ...state,
        isLoadingSave: false,
        hasErrors: true,
        errors: action.payload,
      };

    case ActionTypes.DELETE_FACTURA_START:
      return {
        ...state,
        isLoadingDelete: true,
        hasErrors: false,
        errors: [],
      };
    case ActionTypes.DELETE_FACTURA_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
      };
    case ActionTypes.DELETE_FACTURA_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
        hasErrors: true,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default PrefacturasReducer;
