/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Spin,
  notification,
} from "antd";
import TranslateTable from "./TranslateTable";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchAllProducts,
  fetchClientInfo,
  saveClientInfo,
} from "../../redux/actions/client.actions";
import { ListaPrecios, TiposFacturacion } from "../../utils/types";
import { getTiposFacturacion } from "../../redux/actions/tiposFacturacion.actions";
import { useForm } from "antd/es/form/Form";
import { getListasPrecios } from "../../redux/actions/listasPrecios.actions";
interface IProps {
  clientId: string;
  clientName: string;
}

const CONTENEDORES = [
  "1mt3",
  "1.5mt3",
  "2mt3",
  "3mt3",
  "4mt3",
  "5mt3",
  "6mt3",
  "7mt3",
];

const ClientEditor = ({ clientId, clientName }: IProps) => {
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  const isLoading = useAppSelector((state) => state.clients.isOneClientLoading);
  const tiposFacturacion = useAppSelector(
    (state) => state.tiposFacturacion.tiposFacturacion
  );
  const isTiposFacturacionLoading = useAppSelector(
    (state) => state.tiposFacturacion.isLoading
  );
  const listaPrecios = useAppSelector(
    (state) => state.listaPrecios.listaPrecios
  );
  const isListaPreciosLoading = useAppSelector(
    (state) => state.listaPrecios.isLoading
  );
  const isLoadingSave = useAppSelector((state) => state.clients.isLoadingSave);
  const clientSaved = useAppSelector((state) => state.clients.clientSaved);
  const currentClient = useAppSelector((state) => state.clients.currentClient);
  const [formRef] = useForm();

  useEffect(() => {
    dispatch(fetchClientInfo(clientId));
    dispatch(fetchAllProducts());
    dispatch(getTiposFacturacion());
    dispatch(getListasPrecios());
  }, []);

  useEffect(() => {
    if (currentClient) {
      const diasRecoleccion: string[] = [];
      currentClient.lunes && diasRecoleccion.push("lunes");
      currentClient.martes && diasRecoleccion.push("martes");
      currentClient.miercoles && diasRecoleccion.push("miercoles");
      currentClient.jueves && diasRecoleccion.push("jueves");
      currentClient.viernes && diasRecoleccion.push("viernes");
      currentClient.sabado && diasRecoleccion.push("sabado");
      currentClient.domingo && diasRecoleccion.push("domingo");

      formRef.setFieldsValue({
        emails: currentClient.emails,
        codigoGenerador: currentClient.numeroGenerador,
        diasRecoleccion: diasRecoleccion,
        tipoFacturacionId: currentClient.tipoFacturacionId,
        listaPreciosId: currentClient.listaPreciosId,
        contenedorNormal: currentClient.contenedorNormal,
        contenedorPesado: currentClient.contenedorPesado,
      });
    }
  }, [currentClient]);

  useEffect(() => {
    if (clientSaved) {
      api.success({
        message: `Cliente guardado satisfactoriamente`,
        placement: "topRight",
      });
    }
  }, [clientSaved]);

  const handleFinish = () => {
    const values = formRef.getFieldsValue();
    const emails = values.emails?.replace(" ", "");
    const payload = {
      clientId,
      diasRecoleccion: {
        lunes: values.diasRecoleccion.includes("lunes"),
        martes: values.diasRecoleccion.includes("martes"),
        miercoles: values.diasRecoleccion.includes("miercoles"),
        jueves: values.diasRecoleccion.includes("jueves"),
        viernes: values.diasRecoleccion.includes("viernes"),
        sabado: values.diasRecoleccion.includes("sabado"),
        domingo: values.diasRecoleccion.includes("domingo"),
      },
      emails,
      numeroGenerador: values.codigoGenerador || "",
      tipoFacturacionId: values.tipoFacturacionId || null,
      listaPreciosId: values.listaPreciosId || null,
      contenedorNormal: values.contenedorNormal,
      contenedorPesado: values.contenedorPesado,
    };
    dispatch(saveClientInfo(payload));
  };

  return isLoading || isTiposFacturacionLoading || isListaPreciosLoading ? (
    <Spin />
  ) : (
    <Form form={formRef} className="pb-5" onFinish={handleFinish}>
      {contextHolder}

      <Form.Item
        label="Cliente"
        name="clientName"
        className="inline-block w-full"
      >
        <Input disabled defaultValue={clientName} />
      </Form.Item>
      <Form.Item name="diasRecoleccion" label="Dias de recolección">
        <Checkbox.Group
          options={[
            { label: "Lunes", value: "lunes" },
            { label: "Martes", value: "martes" },
            { label: "Miercoles", value: "miercoles" },
            { label: "Jueves", value: "jueves" },
            { label: "Viernes", value: "viernes" },
            { label: "Sábado", value: "sabado" },
            { label: "Domingo", value: "domingo" },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="emails"
        label="Correos"
        className="w-1/2 inline-block pr-2"
      >
        <Input placeholder="sistemas@ecomexico.mx;text@ecomexico.mx" />
      </Form.Item>
      <Form.Item
        name="tipoFacturacionId"
        label="Tipo de facturación"
        className="w-1/2 inline-block pr-2"
      >
        <Select
          allowClear
          loading={isTiposFacturacionLoading}
          options={tiposFacturacion.map((tipo: TiposFacturacion) => ({
            label: tipo.descripcion,
            value: tipo.id,
          }))}
        />
      </Form.Item>
      <Form.Item
        name="listaPreciosId"
        label="Lista de precios"
        className="w-1/2 inline-block pr-2"
      >
        <Select
          allowClear
          loading={isListaPreciosLoading}
          options={listaPrecios.map((lista: ListaPrecios) => ({
            label: lista.descripcion,
            value: lista.id,
          }))}
        />
      </Form.Item>
      <Form.Item
        name="codigoGenerador"
        label="Codigo generador"
        className="w-1/2 inline-block"
      >
        <Input placeholder="Por definir" />
      </Form.Item>
      <Form.Item
        name="contenedorNormal"
        label="Contenedor normal"
        className="w-1/2 inline-block"
      >
        <Select
          allowClear
          options={CONTENEDORES.map((contenedor) => ({
            label: contenedor,
            value: contenedor,
          }))}
        />
      </Form.Item>
      <Form.Item
        name="contenedorPesado"
        label="Contenedor pesado"
        className="w-1/2 inline-block"
      >
        <Select
          allowClear
          options={CONTENEDORES.map((contenedor) => ({
            label: contenedor,
            value: contenedor,
          }))}
        />
      </Form.Item>
      <div>
        <h1>Tabla de traducciones</h1>
        <TranslateTable />
      </div>
      <label className="block text-right">
        Importante: si deseas modificar telefonos o direcciones, hacer los
        cambios directamente en DRIV.IN, se actualizara en la plataforma
        autómaticamente
      </label>
      <Button
        htmlType="submit"
        type="primary"
        className="bg-green-600 hover:bg-green-400 float-right"
        disabled={isLoadingSave}
      >
        {isLoadingSave && <Spin />}
        Guardar
      </Button>
    </Form>
  );
};

export default ClientEditor;
