import { Button, Divider, Form, Input } from "antd";

import React from "react";
import Uploader from "../../components/Uploader";
import { BiSave } from "react-icons/bi";

const fotosLabels = ["FRONTAL", "DERECHA", "IZQUIERDA", "TRASERA"];

const AgregarVehiculo = () => {
  return (
    <div className="p-5">
      <h1 className="text-2xl text-green-800">Agregar vehiculo</h1>
      <Form>
        <Divider orientation="left">Fotografias</Divider>
        <div className="flex">
          {fotosLabels.map((label) => (
            <Uploader label={label} />
          ))}
        </div>
        <Divider orientation="left">Información general</Divider>
        <div className="flex justify-between gap-2">
          <Form.Item className="w-full" label="Unidad" name="">
            <Input />
          </Form.Item>
          <Form.Item className="w-full" label="Placas" name="">
            <Input />
          </Form.Item>
          <Form.Item className="w-full" label="Marca" name="">
            <Input />
          </Form.Item>
          <Form.Item className="w-full" label="Modelo" name="">
            <Input />
          </Form.Item>
        </div>
        <div className="flex justify-between gap-2">
          <Form.Item className="w-full" label="Año" name="">
            <Input />
          </Form.Item>
          <Form.Item className="w-full" label="Número de serie" name="">
            <Input />
          </Form.Item>
          <Form.Item className="w-full" label="Número de motor" name="">
            <Input />
          </Form.Item>
        </div>
        <Divider orientation="left">Información de documentación</Divider>
        <div className="flex justify-between gap-2">
          <div className="w-full">
            <Uploader label="Poliza de seguro" />
            <Uploader label="Tarjeta de circulación" />
          </div>
          <div className="w-full">
            <Form.Item className="w-full" label="Compañia" name="">
              <Input />
            </Form.Item>
            <Form.Item className="w-full" label="No. de poliza" name="">
              <Input />
            </Form.Item>
            <Uploader label="Ultimo refrendo" />
          </div>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          className="flex w-full text-xl justify-center p-2 h-12"
        >
          <BiSave className="mt-1 mr-2" />
          Guardar
        </Button>
      </Form>
    </div>
  );
};

export default AgregarVehiculo;
