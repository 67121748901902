import { Button, Checkbox, DatePicker, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTiposFacturacion } from "../../redux/actions/tiposFacturacion.actions";
import { TiposFacturacion } from "../../utils/types";
import { TIPOS_FACTURACION_VALUES } from "../../components/TiposDeFacturacion/constants";
import { fetchClientsData } from "../../redux/actions/client.actions";
import { BsCheckCircleFill, BsExclamationDiamondFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import PrefacturaSelectorModal from "../../components/Prefactura/SelectorModal";
import moment from "moment";

const DIAS_DE_LA_SEMANA = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
];

const PrefacturasDelDia = () => {
  const dispatch = useAppDispatch();
  const {
    tiposFacturacion: {
      tiposFacturacion,
      isLoading: isLoadingTiposFacturacion,
    },
    clients: { clientsData, isLoading: isLoadingClients },
  } = useAppSelector((state) => state);

  const [dia, setDia] = useState(dayjs());
  const [tableData, setTableData] = useState<any[]>([]);
  const [isPrefacturaSelectorOpen, setIsPrefacturaSelectorOpen] =
    useState(false);
  const [prefacturasSelectedClient, setPrefacturasSelectedClient] =
    useState("");
  const [tiposFacturacionFrecuencia, setTiposFacturacionFrecuencia] = useState<
    TiposFacturacion[]
  >([]);
  const [showEventuales, setShowEventuales] = useState(false);

  useEffect(() => {
    dispatch(getTiposFacturacion());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tfFrecuencia: TiposFacturacion[] = [];
    const tiposFacturacionAplicables = tiposFacturacion.filter(
      (tf: TiposFacturacion) => {
        switch (tf.tipo) {
          case TIPOS_FACTURACION_VALUES.DIA_DEL_MES:
            if (tf.diasDelMes.includes(`${dia.date()}`)) {
              return tf;
            }
            break;
          case TIPOS_FACTURACION_VALUES.DIA_DE_LA_SEMANA:
            if (!showEventuales) {
              if (tf.descripcion === "Eventual") return false;
            }
            if (tf.diasDeLaSemana.includes(DIAS_DE_LA_SEMANA[dia.day()])) {
              return tf;
            }
            break;
          case TIPOS_FACTURACION_VALUES.FECHA_ESPECIFICA:
            if (dayjs(tf.fechaEspecifica).isSame(dia)) {
              return tf;
            }
            break;
          case TIPOS_FACTURACION_VALUES.FRECUENCIA:
            tfFrecuencia.push(tf);
            return tf;
        }
        return false;
      }
    );

    setTiposFacturacionFrecuencia(tfFrecuencia);
    const tiposFacturacionId = tiposFacturacionAplicables.map(
      (tf: TiposFacturacion) => tf.id
    );
    dispatch(fetchClientsData(tiposFacturacionId));
  }, [tiposFacturacion, dia, dispatch, showEventuales]);

  useEffect(() => {
    const frecuenciaIdArray = tiposFacturacionFrecuencia.map((tf) => tf.id);
    const clientesDepurados = clientsData.filter((cliente) => {
      if (frecuenciaIdArray.includes(cliente.tipoFacturacionId)) {
        // Checar cada cuando se hace su facturacion y cuando fue su ultima factura
        const frecuencia = tiposFacturacionFrecuencia.find(
          (tf) => tf.id === cliente.tipoFacturacionId
        )?.frecuencia;
        if (frecuencia && cliente.ultimaFacturaFecha) {
          const ultimaFactura = dayjs(cliente.ultimaFacturaFecha);
          const diasPasados = ultimaFactura.diff(dia, "days");
          return diasPasados >= frecuencia ? cliente : false;
        }
        return cliente;
      }
      return cliente;
    });
    const tableFinalData = clientesDepurados.map((client) => {
      const tipoFact = tiposFacturacion.find(
        (tipoF: TiposFacturacion) => tipoF.id === client.tipoFacturacionId
      );
      return {
        ...client,
        tipoFacturacion: tipoFact ? tipoFact.descripcion : "",
      };
    });
    setTableData(tableFinalData);
  }, [clientsData, dia, tiposFacturacion, tiposFacturacionFrecuencia]);

  const handleOpenPrefacturaSelector = async (clientId: string) => {
    setPrefacturasSelectedClient(clientId);
    setIsPrefacturaSelectorOpen(true);
  };

  return (
    <div className="p-5">
      {isPrefacturaSelectorOpen && (
        <PrefacturaSelectorModal
          isOpen={isPrefacturaSelectorOpen}
          clientId={prefacturasSelectedClient}
          onClose={() => setIsPrefacturaSelectorOpen(false)}
        />
      )}
      <h1 className="text-xl">Facturación de hoy</h1>
      <DatePicker
        className="mb-5 mr-2"
        defaultValue={dia}
        onChange={(value) => value && setDia(value)}
      />
      <Checkbox
        checked={showEventuales}
        onChange={(value) => setShowEventuales(value.target.checked)}
      >
        Mostrar eventuales
      </Checkbox>
      <Table
        showSorterTooltip={false}
        loading={isLoadingClients || isLoadingTiposFacturacion}
        dataSource={tableData}
        columns={[
          {
            key: "codigoCliente",
            dataIndex: "codigoCliente",
            className: "text-center",
            title: "# Cliente",
            sorter: (a, b) => a.codigoCliente.localeCompare(b.codigoCliente),
          },
          {
            dataIndex: "nombre",
            className: "text-center",
            title: "Nombre",
            sorter: (a, b) => a.nombre?.localeCompare(b.nombre || "") || 0,
          },
          {
            dataIndex: "tipoFacturacion",
            className: "text-center",
            title: "Tipo Facturación",
            sorter: (a, b) => a.tipoFacturacionId - b.tipoFacturacionId,
          },
          {
            dataIndex: "hasFacturasAprobadas",
            className: "text-center",
            title: "Estatus",
            render: (hasFacturasAprobadas) => (
              <div className="flex justify-center">
                {hasFacturasAprobadas ? (
                  <Tooltip
                    title={
                      "El cliente tiene prefacturas aprobadas, usa la herramienta para facturar y definir el número de factura"
                    }
                  >
                    <BsExclamationDiamondFill
                      size={25}
                      className="text-yellow-700"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={"Todas las prefacturas han sido facturadas, gracias"}
                  >
                    <BsCheckCircleFill size={25} className="text-green-700" />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            dataIndex: "ultimaFactura",
            className: "text-center",
            title: "Última factura registrada",
            sorter: (a, b) =>
              a.ultimaFacturaFecha?.localeCompare(b.ultimaFacturaFecha || "") ||
              -1,
            render: (_, record) => {
              const fecha = record.ultimaFacturaFecha
                ? moment(record.ultimaFacturaFecha, "YYYY-MM-DD").format("LL")
                : "Sin facturas registradas";
              return (
                <div className="flex justify-center">
                  <p className="text-orange-700 mr-2">
                    {record.ultimaFacturaId}
                  </p>
                  <p className="text-indigo-700">{fecha}</p>
                </div>
              );
            },
          },
          {
            dataIndex: "codigoCliente",
            title: "Acciones",
            render: (value, record) => (
              <div className="flex justify-center">
                <Link to={`/facturacion/prefactura/nueva/${value}`}>
                  <Button type="primary" className="mr-2">
                    Generar prefactura
                  </Button>
                </Link>
                {record.hasFacturasAprobadas && (
                  <Button
                    onClick={() => handleOpenPrefacturaSelector(value)}
                    type="primary"
                    className="bg-slate-500 hover:bg-slate-700 active:bg-slate-900"
                  >
                    Facturar
                  </Button>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default PrefacturasDelDia;
