import { useState } from "react";
import {
  BsCartDash,
  BsHouse,
  BsPerson,
  BsSpeedometer2,
  BsExclamationCircle,
  BsCurrencyDollar,
  BsGearWideConnected,
} from "react-icons/bs";
import { BiBuildings, BiCar } from "react-icons/bi";
import { Link } from "react-router-dom";
import logo from "../../assets/Logo.svg";
import { Button } from "antd";
import { Auth } from "aws-amplify";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { logout } from "../../redux/actions/login.actions";

const PAGES = [
  {
    id: "home",
    title: "Inicio",
    ref: "/",
    icon: <BsHouse />,
  },
  {
    id: "clients",
    title: "Clientes",
    ref: "/clients",
    icon: <BsPerson />,
  },
  {
    id: "monitoreo",
    title: "Monitoreo",
    ref: "/monitoreo",
    icon: <BsSpeedometer2 />,
  },
  {
    id: "facturacion",
    title: "Facturación",
    ref: "/facturacion",
    icon: <BsCurrencyDollar />,
  },
  {
    id: "categorias",
    title: "Categorias",
    ref: "/categorias",
    icon: <BsGearWideConnected />,
  },
  {
    id: "productos",
    title: "Productos",
    ref: "/productos",
    icon: <BsCartDash />,
  },
  {
    id: "vehiculos",
    title: "Vehiculos",
    ref: "/vehiculos",
    icon: <BiCar />,
  },
  {
    id: "solicitudes",
    title: "Solicitudes",
    ref: "/solicitudes",
    icon: <BiBuildings />,
  },
  {
    id: "formularios-errores",
    title: "Errores WebHook",
    ref: "/formularios-errores",
    icon: <BsExclamationCircle />,
  },
];
const Navbar = () => {
  const [pageSelected, setPageSelected] = useState("home");
  const usuario = useAppSelector((state) => state.auth.usuario);

  const dispatch = useAppDispatch();
  const handleLogout = () => {
    Auth.signOut().then(() => {
      dispatch(logout());
    });
  };
  return (
    <nav className="bg-green-700 pt-2.5 h-full w-60 overflow-hidden flex flex-col justify-between fixed">
      <div className=" flex flex-wrap flex-col items-center mx-auto px-2 mt-5">
        <Link to={"/"} className="items-center text-center">
          <img src={logo} className="h-9 mx-auto" alt="Ecomexico Logo" />
          <p className="self-center text-3xl font-semibold whitespace-nowrap text-green-100 mb-0">
            Ecomexico
          </p>
          <span className="self-center whitespace-nowrap text-green-50">
            {usuario}
          </span>
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="items-center p-2 ml-3 text-sm text-green-500 rounded-lg md:hidden hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-200 dark:text-green-400 dark:hover:bg-green-700 dark:focus:ring-green-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="hidden md:block md:w-full" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 text-green-50 w-full">
            {PAGES.map((page, idx) => {
              return (
                <li key={idx}>
                  <Link
                    to={page.ref}
                    onClick={() => setPageSelected(page.id)}
                    className={`text-xl block py-2 px-2 text-green-100 w-full ${
                      page.id === pageSelected
                        ? "md:text-green-300"
                        : "md:text-green-100"
                    } hover:bg-green-600 rounded`}
                    aria-current="page"
                  >
                    <div className="flex flex-row w-auto p-2">
                      <div className="flex items-center pr-2">{page.icon}</div>
                      {page.title}
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <Button
        className="bg-white text-green-800 rounded-none h-14 text-xl "
        onClick={handleLogout}
      >
        Cerrar sesión
      </Button>
    </nav>
  );
};

export default Navbar;
