import { Upload, UploadFile } from "antd";
import React, { useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
const Uploader = ({ label }: { label: string }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const handleChange = (newFileList: UploadFile[]) => {
    setFileList(newFileList);
  };

  return (
    <Upload
      listType="picture-card"
      fileList={fileList}
      onChange={(fl) => handleChange(fl.fileList)}
      beforeUpload={() => false} // Disable auto upload
    >
      {fileList.length >= 1 ? null : (
        <div>
          <UploadOutlined />
          <div style={{ marginTop: 8 }}>{label}</div>
        </div>
      )}
    </Upload>
  );
};

export default Uploader;
