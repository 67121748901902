import React, { useState, useEffect } from "react";
import { BsPlus } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Product } from "../../utils/types";
import { addTranslationEntry } from "../../redux/actions/client.actions";

const ClientTraductionForm = () => {
  const products = useAppSelector((state) => state.clients.products);
  const dispatch = useAppDispatch();
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [traduccion, setTraduccion] = useState("");
  const [sizeOptions, setSizeOptions] = useState<string[]>([]);
  const [productOptions, setProductOptions] = useState<string[]>([]);

  useEffect(() => {
    const uniqueProds = new Set<string>();
    products &&
      products.length > 0 &&
      products.forEach(({ producto }: Product) => {
        uniqueProds.add(producto);
      });
    const prodOptions = Array.from(uniqueProds).sort();
    setSelectedProduct(prodOptions[0]);
    setProductOptions(prodOptions);
  }, [products]);

  useEffect(() => {
    let sizes: string[] = [];
    products &&
      products.length > 0 &&
      (sizes = products
        .filter((product: Product) => product.producto === selectedProduct)
        .map((product: Product) => product.size)
        .sort());
    setSizeOptions(sizes);
    sizes.length > 0 && setSelectedSize(sizes[0]);
  }, [selectedProduct, products]);

  const handleProductSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProduct(e.target.value);
  };

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(
      addTranslationEntry({
        codigo_producto: selectedProduct,
        size: selectedSize,
        descripcion: traduccion,
      })
    );
  };

  return (
    <tr className="h-20">
      <td>
        <label htmlFor="product">Producto: </label>
        <select
          name="product"
          className="border border-1 border-gray-500 rounded"
          onChange={handleProductSelect}
        >
          {productOptions.map((prodVal) => {
            return <option value={prodVal}>{prodVal}</option>;
          })}
        </select>
      </td>
      <td>
        <label htmlFor="size">Tamaño/Tipo: </label>
        <select
          name="size"
          className="border border-1 border-gray-500 rounded"
          onChange={(e) => setSelectedSize(e.target.value)}
        >
          {sizeOptions.map((size) => {
            return <option value={size}>{size}</option>;
          })}
        </select>
      </td>
      <td>
        <label htmlFor="traduccion">Traduccion: </label>
        <input
          onChange={(e) => setTraduccion(e.target.value)}
          name="traduccion"
          type="text"
          className="border border-1 border-gray-400 rounded"
        />
      </td>
      <td>
        <button
          onClick={handleAdd}
          className="bg-green-700 text-white p-2 rounded"
        >
          <BsPlus />
        </button>
      </td>
    </tr>
  );
};

export default ClientTraductionForm;
