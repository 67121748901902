import { Dispatch } from "@reduxjs/toolkit";
import { API_BASE_URL, DRIVIN_BASE_URL } from "../../utils/api";
import { ClientEcomexico, IDays, Translation } from "../../utils/types";
import { RootState } from "../store";
export const ActionTypes = {
  //MODALS
  OPEN_CLIENT_EDITOR: "OPEN_CLIENT_EDITOR",
  // FETCH ALL CLIENTS
  START_FETCH_CLIENTS: "START_FETCH_CLIENTS",
  SUCCESS_FETCH_CLIENTS: "SUCCESS_FETCH_CLIENTS",
  FAILED_FETCH_CLIENTS: "FAILED_FETCH_CLIENTS",
  // FILTER CLIENTS
  APPLY_FILTERS: "APPLY_FILTERS",
  // FETCH ONE CLIENT
  START_FETCH_ONE_CLIENT: "START_FETCH_ONE_CLIENT",
  SUCCESS_FETCH_ONE_CLIENT: "SUCCESS_FETCH_ONE_CLIENT",
  FAILED_FETCH_ONE_CLIENT: "FAILED_FETCH_ONE_CLIENT",
  // FETCH ALL PRODUCTS
  START_FETCH_ALL_PRODUCTS: "START_FETCH_ALL_PRODUCTS",
  SUCCESS_FETCH_ALL_PRODUCTS: "SUCCESS_FETCH_ALL_PRODUCTS",
  FAILED_FETCH_ALL_PRODUCTS: "FAILED_FETCH_ALL_PRODUCTS",
  // SAVE CLIENT (INNER DB)
  START_SAVE_CLIENT: "START_SAVE_CLIENT",
  SUCCESS_SAVE_CLIENT: "SUCCESS_SAVE_CLIENT",
  FAILED_SAVE_CLIENT: "FAILED_SAVE_CLIENT",
  // ADD-DELETE TRANSLATIONS
  ADD_TRANSLATION_ENTRY: "ADD_TRANSLATION_ENTRY",
  DELETE_TRANSLATION_ENTRY: "DELETE_TRANSLATION_ENTRY",
};

export const openClientEditor =
  ({
    open,
    clientId,
    clientName,
  }: {
    open: boolean;
    clientId: string;
    clientName: string;
  }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.OPEN_CLIENT_EDITOR,
      payload: { open, clientId, clientName },
    });
  };

export const filterClients =
  (clients: ClientEcomexico[]) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.APPLY_FILTERS,
      payload: clients,
    });
  };

export const fetchClientsData =
  (tipoFacturacion?: string | string[]) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.START_FETCH_CLIENTS,
    });
    let URL = `${API_BASE_URL}/corp/clients?`;
    if (tipoFacturacion) {
      if (Array.isArray(tipoFacturacion)) {
        tipoFacturacion.forEach(
          (tf) => (URL += `tipo_facturacion_id[]=${tf}&`)
        );
      } else {
        URL += `tipo_facturacion_id=${tipoFacturacion}&`;
      }
    }
    const response = await fetch(URL);
    const formatedData: any[] = await response.json();

    if (response.status === 200) {
      const drivinResponse = await fetch(
        `${DRIVIN_BASE_URL}/addresses?page=1`,
        {
          headers: {
            "content-type": "application/json",
            "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
          },
        }
      );
      const response1 = await drivinResponse.json();
      const drivinResponse2 = await fetch(
        `${DRIVIN_BASE_URL}/addresses?page=2`,
        {
          headers: {
            "content-type": "application/json",
            "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
          },
        }
      );
      const response2 = await drivinResponse2.json();
      const drivinFormatedData: any = [].concat(
        response1.response,
        response2.response
      );
      const finalData = formatedData.map((clientData: any) => {
        const clienteDrivin = drivinFormatedData.find(
          (element: any) => element.code === clientData.codigoCliente
        );
        if (clienteDrivin) {
          return {
            ...clientData,
            nombre: clienteDrivin.name,
            domicilio: `${clienteDrivin.address1 || ""} , ${
              clienteDrivin.city || ""
            } ${clienteDrivin.state || ""} ${clienteDrivin.country || ""}`,
          };
        }
        return {
          ...clientData,
        };
      });

      dispatch({
        type: ActionTypes.SUCCESS_FETCH_CLIENTS,
        payload: finalData,
      });
    } else {
      dispatch({
        type: ActionTypes.FAILED_FETCH_CLIENTS,
        payload: [formatedData],
      });
    }
  };

export const fetchClientInfo =
  (clientId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.START_FETCH_ONE_CLIENT,
    });
    const response = await fetch(`${API_BASE_URL}/corp/clients/${clientId}`);
    const formatedInnerData = await response.json();
    const clientInfo = formatedInnerData;
    if (response.status !== 200) {
      dispatch({
        type: ActionTypes.FAILED_FETCH_ONE_CLIENT,
        payload: [formatedInnerData],
      });
      return;
    }
    const translationResponse = await fetch(
      `${API_BASE_URL}/corp/translation?cliente=${clientId}`
    );
    if (translationResponse.status !== 200) {
      dispatch({
        type: ActionTypes.FAILED_FETCH_ONE_CLIENT,
        payload: [translationResponse],
      });
      return;
    }
    const translationData = await translationResponse.json();
    clientInfo.translations = translationData;

    dispatch({
      type: ActionTypes.SUCCESS_FETCH_ONE_CLIENT,
      payload: clientInfo,
    });
  };

export const fetchAllProducts = () => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.START_FETCH_ALL_PRODUCTS,
  });
  const response = await fetch(`${API_BASE_URL}/corp/products`);
  const productsData = await response.json();
  if (response.status !== 200) {
    dispatch({
      type: ActionTypes.FAILED_FETCH_ALL_PRODUCTS,
      payload: [productsData],
    });
    return;
  }
  dispatch({
    type: ActionTypes.SUCCESS_FETCH_ALL_PRODUCTS,
    payload: productsData,
  });
};

export const saveClientInfo =
  ({
    clientId,
    diasRecoleccion,
    emails,
    numeroGenerador,
    tipoFacturacionId,
    listaPreciosId,
    contenedorNormal,
    contenedorPesado,
  }: {
    clientId: string;
    diasRecoleccion: IDays;
    emails: string;
    numeroGenerador?: string;
    tipoFacturacionId?: string;
    listaPreciosId?: string;
    contenedorNormal: string;
    contenedorPesado: string;
  }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({
      type: ActionTypes.START_SAVE_CLIENT,
    });
    // SAVE CLIENT INFO IN CORPS
    const response = await fetch(`${API_BASE_URL}/corp/clients`, {
      method: "PUT",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({
        codigo_cliente: clientId,
        emails,
        numeroGenerador,
        lunes: +diasRecoleccion.lunes,
        martes: +diasRecoleccion.martes,
        miercoles: +diasRecoleccion.miercoles,
        jueves: +diasRecoleccion.jueves,
        viernes: +diasRecoleccion.viernes,
        sabado: +diasRecoleccion.sabado,
        domingo: +diasRecoleccion.domingo,
        tipoFacturacionId,
        listaPreciosId,
        contenedorNormal,
        contenedorPesado,
      }),
    });
    if (response.status !== 203 && response.status !== 201) {
      dispatch({
        type: ActionTypes.FAILED_SAVE_CLIENT,
        payload: ["Not able to save client in INNERDB", response],
      });
      return;
    }
    // DELETE TRANSLATIONS FOR THIS CLIENT
    const deleteTranslationResponse = await fetch(
      `${API_BASE_URL}/corp/translation`,
      {
        method: "DELETE",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({
          codigo_cliente: clientId,
        }),
      }
    );
    if (deleteTranslationResponse.status !== 204) {
      dispatch({
        type: ActionTypes.FAILED_SAVE_CLIENT,
        payload: ["Not able to delete all of the translations", response],
      });
      return;
    }
    // ADD EVERY TRANSLATION
    const state = getState();
    const currentClient = state.clients.currentClient;
    if (!currentClient || !currentClient.translations) return;
    const finalTranslations: Translation[] = currentClient
      ? currentClient.translations
      : [];
    for (let i = 0; i < finalTranslations.length; i++) {
      const currentTranslation = finalTranslations[i];
      const addTranslations = await fetch(`${API_BASE_URL}/corp/translation`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({
          codigo_cliente: clientId,
          codigo_producto: currentTranslation.codigo_producto,
          size: currentTranslation.size,
          descripcion: currentTranslation.descripcion,
        }),
      });
      if (addTranslations.status !== 201) {
        dispatch({
          type: ActionTypes.FAILED_SAVE_CLIENT,
          payload: ["Not able to Save translations", response],
        });
      }
    }
    dispatch({
      type: ActionTypes.SUCCESS_SAVE_CLIENT,
      payload: [""],
    });
  };

export const addTranslationEntry =
  (translation: Translation) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    if (
      state.clients.currentClient &&
      state.clients.currentClient.translations
    ) {
      const translations = [...state.clients.currentClient.translations];
      translations.push(translation);
      const payload = {
        ...state.clients.currentClient,
        translations: translations,
      };
      dispatch({
        type: ActionTypes.ADD_TRANSLATION_ENTRY,
        payload: payload,
      });
    }
  };

export const deleteTranslationEntry =
  (idx: number) => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    if (
      state.clients.currentClient &&
      state.clients.currentClient.translations
    ) {
      const translations = [...state.clients.currentClient.translations];
      translations.splice(idx, 1);
      const payload = {
        ...state.clients.currentClient,
        translations: translations,
      };
      dispatch({
        type: ActionTypes.ADD_TRANSLATION_ENTRY,
        payload: payload,
      });
    }
  };
