import { Product, TranslationSaveBody } from "../../utils/types";
import { ActionTypes } from "../actions/productos.actions";

interface IReducer {
  isLoading: boolean;
  isLoadingOne: boolean;
  isSaveLoading: boolean;
  isDeleteLoading: boolean;
  hasErrors: boolean;
  errors: string[];
  products: Product[];
  currentProduct: Product[];
  defaultTranslations: TranslationSaveBody[];
}

const initialState: IReducer = {
  isLoading: false,
  isLoadingOne: false,
  isSaveLoading: false,
  isDeleteLoading: false,
  hasErrors: false,
  errors: [],
  products: [],
  currentProduct: [],
  defaultTranslations: [],
};

const ProductsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // FETCH ONE
    case ActionTypes.START_FETCH_ONE_PRODUCT:
      return {
        ...state,
        isLoadingOne: true,
        currentProduct: null,
        hasErrors: false,
        errors: [],
      };
    case ActionTypes.SUCCESS_FETCH_ONE_PRODUCT:
      return {
        ...state,
        isLoadingOne: false,
        currentProduct: action.payload.currentProduct,
        defaultTranslations: action.payload.defaultTranslations,
      };
    case ActionTypes.FAILED_FETCH_ONE_PRODUCT:
      return {
        ...state,
        isLoadingOne: false,
        hasErrors: true,
        errors: action.payload,
      };
    // FETCH ALL
    case ActionTypes.START_FETCH_PRODUCTS:
      return {
        ...initialState,
        isLoading: true,
        hasErrors: false,
        errors: [],
        products: [],
      };
    case ActionTypes.SUCCESS_FETCH_PRODUCTS:
      return {
        ...state,
        isLoading: false,
        products: action.payload,
      };
    case ActionTypes.FAILED_FETCH_PRODUCTS:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    // FETCH TRANSLATIONS
    case ActionTypes.START_FETCH_PRODUCT_TRANSLATION:
      return {
        ...state,
        defaultTranslations: [],
      };
    case ActionTypes.SUCCESS_FETCH_PRODUCT_TRANSLATION:
      return {
        ...state,
        defaultTranslations: action.payload,
      };
    case ActionTypes.FAILED_FETCH_PRODUCT_TRANSLATION:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default ProductsReducer;
