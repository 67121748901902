import { Button, Form, Modal, Select, Table, Tag, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import React, { useState } from "react";
import { BsCheck, BsEye, BsPlusCircleFill, BsX } from "react-icons/bs";
import NuevaSolicitud from "./NuevaSolicitud";
const tableDataSource = [
  {
    id: 1,
    tipo: "Suspención",
    status: "new",
    fecha: moment().format("LLL "),
    cliente: "22 - Zarandeado",
    reporta: "Luis Enrique Santana",
    ultimoMovimiento: "Creado - Luis Santana",
    comentarios: "",
  },
  {
    id: 3,
    tipo: "Suspención",
    status: "new",
    fecha: moment().format("LLL "),
    cliente: "29 - Elektra",
    reporta: "Luis Enrique Santana",
    ultimoMovimiento: "Creado - Luis Santana",
    comentarios: "",
  },
  {
    id: 5,
    tipo: "Reactivación",
    status: "noAtender",
    fecha: moment().add(1, "d").format("LLL "),
    cliente: "22 - Zarandeado",
    reporta: "Luis Enrique Santana",
    ultimoMovimiento: "Cambio a no atender - Juan Camarena",
    comentarios: "",
  },
  {
    id: 6,
    tipo: "Reactivación",
    status: "atendido",
    fecha: moment().format("LLL "),
    cliente: "01 - Ecomexico",
    reporta: "Luis Enrique Santana",
    ultimoMovimiento: "Atendido - Miguel Castro",
    comentarios: "",
  },
];
const Solicitudes = () => {
  const [openModalAtender, setOpenModalAtender] = useState(false);
  const [openModalNoAtender, setOpenModalNoAtender] = useState(false);
  const [openModalNuevaSolicitud, setOpenModalNuevaSolicitud] = useState(false);
  return (
    <div className="p-5">
      <Modal
        title="Marcar como atendida"
        open={openModalAtender}
        closable={false}
        footer={null}
      >
        <Form>
          <Form.Item label="Comentarios" required>
            <TextArea />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            Guardar
          </Button>
          <Button type="default" onClick={() => setOpenModalAtender(false)}>
            Cancelar
          </Button>
        </Form>
      </Modal>
      <Modal
        title="Marcar como NO atendida"
        open={openModalNoAtender}
        closable={false}
        footer={null}
      >
        <Form>
          <Form.Item label="Explica por qué no se atenderá" required>
            <TextArea />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="mr-2">
            Guardar
          </Button>
          <Button type="default" onClick={() => setOpenModalNoAtender(false)}>
            Cancelar
          </Button>
        </Form>
      </Modal>
      <Modal
        open={openModalNuevaSolicitud}
        onCancel={() => setOpenModalNuevaSolicitud(false)}
        closable
        destroyOnClose
        footer={null}
      >
        <NuevaSolicitud />
      </Modal>
      <h1 className="text-2xl text-green-800">Solicitudes</h1>
      <div className="flex">
        <div className="flex gap-2 mr-2">
          <Button
            type="primary"
            className="flex gap-2 mb-2"
            onClick={() => setOpenModalNuevaSolicitud(true)}
          >
            <BsPlusCircleFill className="mt-1 mr-1" />
            Nueva solicitud
          </Button>
        </div>
        <span className="mr-2 pt-2">Status</span>
        <Select
          size="small"
          className="w-72 -mt-1"
          options={[
            { label: "Nueva", value: "new" },
            { label: "Atendida", value: "atendida" },
            { label: "No se atenderá", value: "cancelada" },
          ]}
        />
      </div>
      <Table
        dataSource={tableDataSource}
        columns={[
          { key: "id", dataIndex: "id", title: "Folio" },
          {
            key: "status",
            dataIndex: "status",
            title: "Estatus",
            render: (value) => {
              switch (value) {
                case "new":
                  return <Tag color="green">Nuevo</Tag>;
                case "noAtender":
                  return <Tag color="red">No se atenderá</Tag>;
                case "atendido":
                  return <Tag color="lime">Atendido</Tag>;
              }
            },
          },
          { key: "tipo", dataIndex: "tipo", title: "Tipo" },
          { key: "fecha", dataIndex: "fecha", title: "Fecha" },
          { key: "cliente", dataIndex: "cliente", title: "Cliente" },
          { key: "reporta", dataIndex: "reporta", title: "Quien reporta" },
          {
            key: "ultimoMovimiento",
            dataIndex: "ultimoMovimiento",
            title: "Último movimiento",
          },
          {
            key: "comentarios",
            dataIndex: "comentarios",
            title: "Comentarios",
          },
          {
            dataIndex: "id",
            title: "Acciones",
            render: (id, record, index) => {
              return (
                <div className="flex gap-2">
                  <Tooltip title="Ver detalles" placement="left">
                    <Button
                      type="primary"
                      className="bg-indigo-700 hover:bg-indigo-900"
                    >
                      <BsEye />
                    </Button>
                  </Tooltip>
                  {record.status === "new" && (
                    <>
                      <Tooltip title="Marcar como atendida" placement="top">
                        <Button
                          type="primary"
                          className="bg-green-700 hover:bg-green-900"
                          onClick={() => setOpenModalAtender(true)}
                        >
                          <BsCheck />
                        </Button>
                      </Tooltip>

                      <Tooltip title="No se atenderá" placement="right">
                        <Button
                          type="primary"
                          className="bg-red-700 hover:bg-red-900"
                          onClick={() => setOpenModalNoAtender(true)}
                        >
                          <BsX />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default Solicitudes;
