import { ActionTypes } from "../actions/login.actions";

interface IReducer {
  token: string;
  usuario: string;
}

const initialState: IReducer = {
  token: "",
  usuario: "",
};

const LoginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return {
        token: action.payload.token,
        usuario: action.payload.usuario,
      };
    case ActionTypes.LOGOUT:
      return {
        token: "",
        usuario: "",
      };
    default:
      return state;
  }
};

export default LoginReducer;
