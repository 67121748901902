import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { isTokenExpired } from "../utils/jwt";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";
import RootLayout from "./Layout";
import Protected from "../components/Protected/Protected";
import Reports from "./Manifiestos";
import Clients from "./Clients";
import Productos from "./Productos";
import ErrorPage from "./ErrorPage";
import { logout } from "../redux/actions/login.actions";
import Monitoreo from "./Monitoreo";
import FormulariosConErrores from "./FormulariosConErrores";
import Facturacion from "./Facturacion/Facturacion";
import Categorias from "./Categorias";
import PrefacturaTool from "./Facturacion/PrefacturaTool";
import FacturacionIndex from "./Facturacion";
import ManifiestosSinFacturar from "./Facturacion/ManifiestosSinFacturar";
import PrefacturasList from "./Facturacion/PrefacturasList";
import Vehiculos from "./Vehiculos";
import AgregarVehiculo from "./Vehiculos/AgregarVehiculo";
import ExpedienteVehiculo from "./Vehiculos/ExpedienteVehiculo";
import Solicitudes from "./Solicitudes";
import NuevaSolicitud from "./Solicitudes/NuevaSolicitud";

const RouterComponent = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const authToken = useAppSelector((state) => state.auth.token);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (authToken !== "") {
      if (!isTokenExpired(authToken)) {
        setIsSignedIn(true);
      } else {
        dispatch(logout());
        setIsSignedIn(false);
      }
    } else {
      setIsSignedIn(false);
    }
  }, [authToken, dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/signin"
          errorElement={<ErrorPage />}
          element={<LoginPage isSignedIn={isSignedIn} />}
        />
        <Route
          path="/"
          errorElement={<ErrorPage />}
          element={
            <Protected isSignedIn={isSignedIn}>
              <RootLayout />
            </Protected>
          }
        >
          <Route index errorElement={<ErrorPage />} element={<Reports />} />
          <Route
            path="/manifest"
            errorElement={<ErrorPage />}
            element={<Reports />}
          />
          <Route
            path="/clients"
            errorElement={<ErrorPage />}
            element={<Clients />}
          />
          <Route
            path="/monitoreo"
            errorElement={<ErrorPage />}
            element={<Monitoreo />}
          />
          <Route
            path="/formularios-errores"
            errorElement={<ErrorPage />}
            element={<FormulariosConErrores />}
          />
          <Route
            path="/productos"
            errorElement={<ErrorPage />}
            element={<Productos />}
          />
          <Route
            path="/facturacion"
            errorElement={<ErrorPage />}
            element={<FacturacionIndex />}
          />
          <Route
            path="/facturacion/list"
            errorElement={<ErrorPage />}
            element={<PrefacturasList />}
          />
          <Route
            path="/facturacion/clientes"
            errorElement={<ErrorPage />}
            element={<Facturacion />}
          />
          <Route
            path="/facturacion/manifiestos-sin-facturar"
            errorElement={<ErrorPage />}
            element={<ManifiestosSinFacturar />}
          />
          <Route
            path="/facturacion/prefactura/:id"
            errorElement={<ErrorPage />}
            element={<PrefacturaTool />}
          />
          <Route
            path="/facturacion/prefactura/nueva/:clientId"
            errorElement={<ErrorPage />}
            element={<PrefacturaTool />}
          />
          <Route
            path="/categorias"
            errorElement={<ErrorPage />}
            element={<Categorias />}
          />
          <Route
            path="/vehiculos"
            errorElement={<ErrorPage />}
            element={<Vehiculos />}
          />
          <Route
            path="/vehiculos/agregar"
            errorElement={<ErrorPage />}
            element={<AgregarVehiculo />}
          />
          <Route
            path="/vehiculos/expediente"
            errorElement={<ErrorPage />}
            element={<ExpedienteVehiculo />}
          />
          <Route
            path="/solicitudes"
            errorElement={<ErrorPage />}
            element={<Solicitudes />}
          />
          <Route
            path="/solicitudes/nueva"
            errorElement={<ErrorPage />}
            element={<NuevaSolicitud />}
          />
          <Route
            path="*"
            errorElement={<ErrorPage />}
            element={<ErrorPage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
