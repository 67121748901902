import { Button, Table, notification } from "antd";
import { useEffect, useState } from "react";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import AddEditModal from "./AddEditModal";
import { useForm } from "antd/es/form/Form";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  deleteListaPrecios,
  getListasPrecios,
  saveListaPrecios,
} from "../../redux/actions/listasPrecios.actions";
import { ListaPrecios as ListaPreciosType } from "../../utils/types";

const ListaPrecios = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.listaPrecios.isLoading);
  const isDeleteLoading = useAppSelector(
    (state) => state.listaPrecios.isDeleteLoading
  );
  const listasPrecios = useAppSelector(
    (state) => state.listaPrecios.listaPrecios
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formRef] = useForm();
  const [tipoEditing, setTipoEditing] = useState<any>();

  useEffect(() => {
    dispatch(getListasPrecios());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayload = (id?: string) => {
    const values = formRef.getFieldsValue();
    let result: ListaPreciosType = {
      descripcion: "",
      data: [],
    };
    for (const [key, value] of Object.entries(values)) {
      if (key === "descripcion" && typeof value === "string") {
        result.descripcion = value;
      } else {
        const productForm = `${key}`;
        const producto = productForm.split("_")[0];
        const size = productForm.split("_")[1];
        const precio = value ? +`${value}` : 0;
        result.data.push({
          id_lista_precios: id ? +id : undefined,
          producto,
          size,
          precio,
        });
      }
    }
    return result;
  };

  const handleAddListaPrecios = async () => {
    const payload = getPayload();
    await dispatch(saveListaPrecios(payload)).catch(() => {
      notification.error({ message: "Error" });
    });
    setIsModalOpen(false);
    await dispatch(getListasPrecios());
  };

  const handleEditListaPrecios = async () => {
    const payload = getPayload();
    await dispatch(saveListaPrecios(payload, tipoEditing?.id)).catch(() => {
      notification.error({ message: "Error" });
    });
    setIsModalOpen(false);
    await dispatch(getListasPrecios());
  };

  const handleDelete = async (id: string) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "¿Seguro deseas borrar la lista de precios? Todos los clientes que esten asignados con esta lista quedaran sin lista, es necesario re-asignar"
      ) === true
    ) {
      await dispatch(deleteListaPrecios(id));
      await dispatch(getListasPrecios());
    }
  };

  const closeAddEditModal = () => {
    setTipoEditing(undefined);
    setIsModalOpen(false);
  };
  return (
    <div className="p-2 w-full">
      <Button
        type="primary"
        className="mb-2 flex items-center float-right"
        onClick={() => setIsModalOpen(true)}
      >
        <BsPlusCircle className="mr-2" /> Agregar lista de precios
      </Button>

      {isModalOpen && (
        <AddEditModal
          formRef={formRef}
          isModalOpen={isModalOpen}
          elementToEdit={tipoEditing}
          mode={tipoEditing ? "EDIT" : "ADD"}
          handleSave={
            tipoEditing ? handleEditListaPrecios : handleAddListaPrecios
          }
          handleCancel={closeAddEditModal}
        />
      )}
      <Table
        dataSource={listasPrecios}
        loading={isLoading}
        columns={[
          {
            title: "Descripción",
            key: "descripcion",
            dataIndex: "descripcion",
          },
          {
            title: "Acciones",
            key: "id",
            dataIndex: "id",
            render: (value, record) => (
              <div className="flex">
                <Button
                  type="primary"
                  onClick={() => {
                    setTipoEditing(record);
                    setIsModalOpen(true);
                  }}
                >
                  <BsPencil />
                </Button>
                <Button
                  loading={isDeleteLoading}
                  className="ml-2 bg-red-700 text-white border-red-700 hover:border-red-400 hover:bg-red-500"
                  onClick={handleDelete.bind(this, value)}
                >
                  <BsTrash />
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ListaPrecios;
