import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Form, Input, Button, notification, Col, Row, Spin } from "antd";
import { BsLock, BsPerson } from "react-icons/bs";
import { AUTH_USER_TOKEN_KEY } from "../../utils/jwt";
import Logo from "../../assets/Logo_Addocs.png";
import { useAppDispatch } from "../../hooks";
import { login } from "../../redux/actions/login.actions";

const LoginContainer = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values: { username: string; password: string }) => {
        let { username, password } = values;
        setLoading(true);

        Auth.signIn(username, password)
          .then((user) => {
            const session = user.signInUserSession?.accessToken?.jwtToken;
            if (session) {
              localStorage.setItem(AUTH_USER_TOKEN_KEY, session);
              dispatch(
                login({
                  token: session,
                  usuario: user.signInUserSession?.idToken?.payload?.name || "",
                })
              );
              notification.success({
                message: "Inicio de session exitoso",
                description: "Redirigiendo a inicio",
                placement: "topRight",
                duration: 3,
              });

              navigate("/");
            } else {
              notification.error({
                message: "Error",
                description:
                  "Algo salió mal, si olvidaste tu contraseña contacta a sistemas@ecomexico.mx",
                placement: "topRight",
              });
              setLoading(false);
            }
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description:
                err.message +
                ". Algo salió mal, si olvidaste tu contraseña contacta a sistemas@ecomexico.mx",
              placement: "topRight",
            });
            setLoading(false);
          });
      })
      .catch((err: Error) => {
        notification.error({
          message: "Error",
          description: err.message,
          placement: "topRight",
        });
      });
  };

  return (
    <div className="border rounded shadow-md p-10">
      <img src={Logo} alt="" width={200} height={200} className="m-auto " />
      <h1 className="text-center text-2xl text-green-900">Bienvenido</h1>
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item
          name={"username"}
          rules={[{ required: true, message: "Escribe usuario" }]}
        >
          <Input prefix={<BsPerson />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name={"password"}
          rules={[
            {
              required: true,
              message: "Introduce tu contraseña por favor",
            },
          ]}
        >
          <Input prefix={<BsLock />} type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item className="text-center">
          <Row itemType="flex" gutter={16}>
            <Col lg={24}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={loading}
                htmlType="submit"
                className="login-form-button"
              >
                {loading ? <Spin /> : "Log in"}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginContainer;
