/* eslint-disable jsx-a11y/anchor-is-valid */

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect, useState } from "react";
import { Product } from "../../utils/types";
import {
  fetchProducts,
  saveNewProduct,
} from "../../redux/actions/productos.actions";
import { Button, Table, notification } from "antd";
import { BsPencil, BsPlusCircle } from "react-icons/bs";
import { useForm } from "antd/es/form/Form";
import AddEditModal from "./AddEditModal";

const ProductsTable = () => {
  const dispatch = useAppDispatch();
  const productsData = useAppSelector((state) => {
    const productos = state.products.products;
    const productAdded: string[] = [];
    let data: any[] = [];
    productos.forEach(({ producto }: Product) => {
      if (!productAdded.includes(producto)) {
        productAdded.push(producto);
        data.push({ name: producto });
      }
    });
    return data;
  });
  const isLoading = useAppSelector((state) => state.products.isLoading);

  const [formRef] = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productEditing, setProductEditing] = useState<string>();
  useEffect(() => {
    dispatch(fetchProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditClick = (productToEdit: string) => {
    setProductEditing(productToEdit);
    setIsModalOpen(true);
  };

  const getPayload = () => {
    const values = formRef.getFieldsValue();
    const producto = formRef.getFieldValue("producto");
    const translationsData: any[] = [];
    for (const [key, value] of Object.entries(values)) {
      if (key !== "producto") {
        const size = key;
        const translation = value;
        translationsData.push({
          size,
          traduccionDefault: translation,
        });
      }
    }
    return {
      producto: producto,
      data: translationsData,
    };
  };
  const handleAddProducto = async () => {
    // ADD LOGIC
    const payload = getPayload();
    await dispatch(saveNewProduct(payload, false)).catch(() =>
      notification.error({ message: "Error" })
    );
    setIsModalOpen(false);
    await dispatch(fetchProducts());
  };

  const handleEditProducto = async () => {
    // EDIT LOGIC
    const payload = getPayload();
    await dispatch(saveNewProduct(payload, true)).catch(() =>
      notification.error({ message: "Error" })
    );
    setIsModalOpen(false);
    await dispatch(fetchProducts());
  };

  const closeAddEditModal = () => {
    setProductEditing(undefined);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button
        type="primary"
        className="mb-2 flex items-center float-right"
        onClick={() => setIsModalOpen(true)}
      >
        <BsPlusCircle className="mr-2" /> Agregar producto
      </Button>

      {isModalOpen && (
        <AddEditModal
          formRef={formRef}
          isModalOpen={isModalOpen}
          elementToEdit={productEditing}
          mode={productEditing ? "EDIT" : "ADD"}
          handleSave={productEditing ? handleEditProducto : handleAddProducto}
          handleCancel={closeAddEditModal}
        />
      )}
      <Table
        loading={isLoading}
        dataSource={productsData}
        columns={[
          { key: "name", dataIndex: "name", title: "Producto" },
          {
            key: "name",
            dataIndex: "name",
            render: (value) => (
              <>
                <Button
                  type="primary"
                  onClick={handleEditClick.bind(this, value)}
                >
                  <BsPencil />
                </Button>
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ProductsTable;
