import React from "react";
import { Navigate } from "react-router-dom";
const Protected = ({
  isSignedIn,
  children,
}: {
  isSignedIn: boolean;
  children: any;
}) => {
  if (!isSignedIn) {
    return <Navigate to="/signin" replace />;
  }
  return children;
};
export default Protected;
