import { Button, Form, Input, Select } from "antd";
import React, { useState } from "react";
import { useAppSelector } from "../../hooks";
import { useForm } from "antd/es/form/Form";
import SubFormas from "./SubFormas";
enum TIPOS_SOLICITUD_CODIGO {
  S = "S",
  R = "R",
  SE = "SE",
  A = "A",
}

const TIPOS_SOLICITUD = [
  { label: "Suspensión", codigo: TIPOS_SOLICITUD_CODIGO.S },
  { label: "Reactivación", codigo: TIPOS_SOLICITUD_CODIGO.R },
  { label: "Servicio especial", codigo: TIPOS_SOLICITUD_CODIGO.SE },
  { label: "Alta de cliente", codigo: TIPOS_SOLICITUD_CODIGO.A },
];

const NuevaSolicitud = () => {
  const [form] = useForm();
  const user = useAppSelector((state) => state.auth.usuario);
  const [tipoSolicitud, setTipoSolicitud] = useState<TIPOS_SOLICITUD_CODIGO>();

  return (
    <div>
      <h1 className="text-2xl text-green-800">Nueva solicitud</h1>

      <Form form={form} initialValues={{ quienReporta: user }}>
        <Form.Item label="¿Qué deseas registrar?" required>
          <Select
            onSelect={(value) => setTipoSolicitud(value)}
            options={TIPOS_SOLICITUD.map((t) => ({
              label: t.label,
              value: t.codigo,
            }))}
          />
        </Form.Item>
        {(tipoSolicitud === TIPOS_SOLICITUD_CODIGO.S ||
          tipoSolicitud === TIPOS_SOLICITUD_CODIGO.R) && (
          <SubFormas.SuspensionActivacion form={form} />
        )}

        {tipoSolicitud === TIPOS_SOLICITUD_CODIGO.SE && (
          <SubFormas.ServicioEspecial form={form} />
        )}

        {tipoSolicitud === TIPOS_SOLICITUD_CODIGO.A && (
          <SubFormas.AltaCliente form={form} />
        )}
        <Form.Item name="quienReporta" hidden>
          <Input className="input-disabled-remarked" disabled />
        </Form.Item>
        <div className="flex justify-end">
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default NuevaSolicitud;
