import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/es_ES";
import { RangeValue } from "rc-picker/lib/interface";
import { useForm } from "antd/es/form/Form";
import {
  getManifiestos,
  setFilters,
} from "../../redux/actions/manifiestos.actions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { BsFilter } from "react-icons/bs";

const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";
const ManifiestosFiltros = () => {
  const [open, setOpen] = useState(false);
  const [formRef] = useForm();
  const dispatch = useAppDispatch();
  const filtros = useAppSelector((state) => state.manifiestos.filters);
  const [dateRange, setDateRange] = useState<RangeValue<dayjs.Dayjs>>();

  useEffect(() => {
    setDateRange([dayjs(filtros.startDate), dayjs(filtros.endDate)]);
  }, [filtros]);

  const fetchData = async () => {
    let startDate = undefined;
    let endDate = undefined;
    let folioF;
    let clientIDF;
    let clientNameF = formRef.getFieldValue("clientNames");
    let rutas;
    let domicilio;
    let pdfCreado;
    let emailEnviado;

    const misFechas = formRef.getFieldValue("datesRange");
    if (misFechas && misFechas.length > 1) {
      startDate = dayjs(misFechas[0]).format(dateFormat);
      endDate = dayjs(misFechas[1]).add(1, "day").format(dateFormat);
    }
    if (formRef.getFieldValue("orderNumbers")) {
      let text = String(formRef.getFieldValue("orderNumbers"));
      folioF = text.trim().replaceAll(" ", "");
      folioF = folioF === "" ? undefined : folioF.split(",");
    }
    if (formRef.getFieldValue("clientIds")) {
      let text = String(formRef.getFieldValue("clientIds"));
      clientIDF = text.replaceAll(" ", "");
      clientIDF = clientIDF === "" ? undefined : clientIDF.split(",");
    }
    if (formRef.getFieldValue("rutas")) {
      let text = String(formRef.getFieldValue("rutas"));
      rutas = text.replaceAll(" ", "");
      rutas = rutas === "" ? undefined : rutas.split(",");
    }
    if (formRef.getFieldValue("domicilio")) {
      domicilio = String(formRef.getFieldValue("domicilio"));
    }
    pdfCreado = formRef.getFieldValue("pdfCreado");
    emailEnviado = formRef.getFieldValue("emailEnviado");

    dispatch(
      setFilters({
        startDate: startDate,
        endDate: endDate,
        folios: folioF,
        codigoClientes: clientIDF,
        nombreCliente: clientNameF,
        rutas: rutas,
        domicilio: domicilio,
        aprobados: true,
        pdfCreado: pdfCreado,
        emailEnviado: emailEnviado,
        facturado: false,
      })
    ).then(() => dispatch(getManifiestos()));
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleBorrarFiltros = () => {
    formRef.setFieldsValue({
      datesRange: [dayjs(), dayjs()],
      orderNumbers: "",
      clientIds: "",
      clientNames: "",
      domicilio: "",
      rutas: "",
      pdfCreado: undefined,
      emailEnviado: undefined,
    });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        className="mb-2 flex items-center"
      >
        <BsFilter className="mr-2" />
        Filtros
      </Button>
      <Modal
        maskClosable={false}
        width={600}
        closable={true}
        open={open}
        title={"Filtros"}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={<></>}
      >
        <Form
          form={formRef}
          onFinish={fetchData}
          className="w-full"
          initialValues={{
            ...filtros,
            datesRange: dateRange,
          }}
        >
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2" htmlFor="filterDates">
              Fechas a filtrar
            </label>
            <Form.Item name="datesRange" className="mb-0">
              <RangePicker
                size="large"
                allowClear={false}
                format={dateFormat}
                locale={locale}
                allowEmpty={[true, true]}
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2" htmlFor="orderNumbers">
              Número de Orden
            </label>
            <Form.Item name="orderNumbers" className="mt-1 flex-1 mb-0">
              <Input
                type="text"
                size="large"
                placeholder="Escribe manifiestos a buscar"
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Número de cliente</label>
            <Form.Item name="clientIds" className="mt-1 flex-1 mb-0">
              <Input
                size="large"
                allowClear
                placeholder="Selecciona el id de los clientes a filtrar"
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Nombre del cliente</label>
            <Form.Item name="clientNames" className="mt-1 flex-1 mb-0">
              <Input
                className="w-full "
                size="large"
                allowClear
                placeholder="Selecciona el nombre de los clientes a filtrar"
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Domicilio</label>
            <Form.Item name="domicilio" className="mt-1 flex-1 mb-0">
              <Input
                className="w-full "
                allowClear
                placeholder="Escribe domicilio para filtrar"
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Rutas</label>
            <Form.Item name="rutas" className="mt-1 flex-1 mb-0">
              <Input
                className="w-full "
                allowClear
                placeholder="Selecciona las rutas a filtrar"
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">PDF Creado</label>
            <Form.Item name="pdfCreado" className="mt-1 flex-1 mb-0">
              <Select
                allowClear
                options={[
                  { label: "Si", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Form.Item>
          </div>
          <div className="flex flex-row flex-wrap items-center">
            <label className="mr-2">Email enviado</label>
            <Form.Item name="emailEnviado" className="mt-1 flex-1 mb-0">
              <Select
                allowClear
                options={[
                  { label: "Si", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit" className="mr-2 mt-2">
            Buscar
          </Button>
          <Button onClick={handleBorrarFiltros} type="dashed">
            Borrar filtros
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ManifiestosFiltros;
