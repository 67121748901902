import { Button, Modal, Spin, Table } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getPrefacturas,
  setFilters,
} from "../../redux/actions/prefacturas.actions";
import { Prefacturas } from "../../utils/types";
import { Link } from "react-router-dom";
import { BsArrowRightCircleFill } from "react-icons/bs";
interface IProps {
  clientId: string;
  isOpen: boolean;
  onClose: () => void;
}
const PrefacturaSelectorModal = ({ clientId, isOpen, onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const prefacturasList: Prefacturas[] = useAppSelector(
    (state) => state.prefacturas.prefacturas
  );
  const isPrefacturasLoading = useAppSelector(
    (state) => state.prefacturas.isLoading
  );
  useEffect(() => {
    dispatch(setFilters({ codigoClientes: clientId, facturada: false }));
    dispatch(getPrefacturas());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={isOpen}
      onCancel={() => onClose()}
      title="Selecciona la prefactura deseas facturar"
      destroyOnClose
      closable
      footer={<></>}
    >
      {isPrefacturasLoading ? (
        <Spin />
      ) : (
        <Table
          dataSource={prefacturasList}
          pagination={false}
          columns={[
            {
              key: "periodoStart",
              dataIndex: "periodoStart",
              title: "Periodo de la prefactura",
              render: (
                _,
                { periodoStart, periodoEnd }
              ) => `${periodoStart} al ${periodoEnd}
              `,
            },
            {
              dataIndex: "id",
              title: "Acciones",
              render: (value) => (
                <Link to={`/facturacion/prefactura/${value}`}>
                  <Button type="text">
                    <BsArrowRightCircleFill />
                  </Button>
                </Link>
              ),
            },
          ]}
        />
      )}
    </Modal>
  );
};

export default PrefacturaSelectorModal;
