import { Dispatch } from "@reduxjs/toolkit";
import {
  Manifiesto,
  ManifiestoWithRecolecciones,
} from "../reducers/manifiestos";
import { API_BASE_URL } from "../../utils/api";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { RootState } from "../store";
import moment from "moment";
import dayjs from "dayjs";
import { notification } from "antd";

export const ActionTypes = {
  // SYNC ACTIONS
  RESET_MANIFIESTOS: "RESET_MANIFIESTOS",
  SET_MANIFIESTOS_FILTERS: "SET_MANIFIESTOS_FILTERS",
  OPEN_PDF_VIEWER: "OPEN_PDF_VIEWER",
  UPDATE_PERCENT_EMAIL: "UPDATE_PERCENT_EMAIL",
  SET_PAGINATION_STATE: "SET_PAGINATION_STATE",
  SET_ORDER_BY_STATE: "SET_ORDER_BY_STATE",

  // ASYNC ACTIONS
  FETCH_MANIFIESTOS_START: "FETCH_MANIFIESTOS_START",
  FETCH_MANIFIESTOS_SUCCESS: "FETCH_MANIFIESTOS_SUCCESS",
  FETCH_MANIFIESTOS_FAILED: "FETCH_MANIFIESTOS_FAILED",

  FETCH_MANIFIESTOS_COUNT_START: "FETCH_MANIFIESTOS_COUNT_START",
  FETCH_MANIFIESTOS_COUNT_SUCCESS: "FETCH_MANIFIESTOS_COUNT_SUCCESS",
  FETCH_MANIFIESTOS_COUNT_FAILED: "FETCH_MANIFIESTOS_COUNT_FAILED",

  FETCH_PDF_START: "FETCH_PDF_START",
  FETCH_PDF_SUCCESS: "FETCH_PDF_SUCCESS",
  FETCH_PDF_FAILED: "FETCH_PDF_FAILED",

  SEND_EMAIL_START: "SEND_EMAIL_START",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILED: "SEND_EMAIL_FAILED",

  APROBAR_MANIFIESTO_START: "APROBAR_MANIFIESTO_START",
  APROBAR_MANIFIESTO_SUCCESS: "APROBAR_MANIFIESTO_SUCCESS",
  APROBAR_MANIFIESTO_FAILED: "APROBAR_MANIFIESTO_FAILED",

  CREAR_MANIFIESTO_START: "CREAR_MANIFIESTO_START",
  CREAR_MANIFIESTO_SUCCESS: "CREAR_MANIFIESTO_SUCCESS",
  CREAR_MANIFIESTO_FAILED: "CREAR_MANIFIESTO_FAILED",
};
interface IFilters {
  startDate?: string;
  endDate?: string;
  folios?: string[];
  codigoClientes?: string[];
  nombreCliente?: string;
  rutas?: string[];
  domicilio?: string;
  aprobados?: boolean;
  pdfCreado?: boolean;
  emailEnviado?: boolean;
  facturado?: boolean;
}
export const setFilters = (filters: IFilters) => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.SET_MANIFIESTOS_FILTERS,
    payload: filters,
  });
};

export const setPaginationState =
  (page: number, pageSize: number) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SET_PAGINATION_STATE,
      payload: { page, pageSize },
    });
  };

export const setOrderByState =
  (key: string, direction: "ASC" | "DESC") => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SET_ORDER_BY_STATE,
      payload: [key, direction],
    });
  };

export const resetReport = () => (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.RESET_MANIFIESTOS,
  });
};

export const buildManifiestosUrl = (baseUrl: string, filters: any) => {
  let newUrl = `${baseUrl}`;
  const {
    startDate,
    endDate,
    folios,
    codigoClientes,
    nombreCliente,
    rutas,
    domicilio,
    aprobados,
    emailEnviado,
    pdfCreado,
    facturado,
    pageSize,
    currentPage,
    orderBy,
  } = filters;
  if (pageSize !== undefined && currentPage !== undefined) {
    newUrl += `pageSize=${pageSize}&page=${currentPage}&`;
  }
  if (orderBy && orderBy[0] !== "") {
    newUrl += `orderBy=${JSON.stringify(orderBy)}&`;
  }
  if (folios) {
    folios.forEach((folio: string) => {
      newUrl += `folios[]=${folio}&`;
    });
  }
  if (facturado !== undefined) {
    newUrl += `facturado=${facturado ? true : false}&`;
  }
  if (codigoClientes) {
    codigoClientes.forEach((codigoCliente: string) => {
      newUrl += `codigo_clientes[]=${codigoCliente}&`;
    });
  }
  if (nombreCliente) {
    newUrl += `nombre_cliente=${nombreCliente}&`;
  }
  if (rutas) {
    rutas.forEach((ruta: string) => {
      newUrl += `rutas[]=${ruta}&`;
    });
  }
  if (domicilio) {
    newUrl += `domicilio=${domicilio}&`;
  }
  if (startDate && endDate) {
    let momStartDate = moment(startDate, "DD-MM-YYYY");
    let momEndDate = moment(endDate, "DD-MM-YYYY");
    newUrl += `start_date=${momStartDate.format(
      "DD-MM-YYYY"
    )}&end_date=${momEndDate.format("DD-MM-YYYY")}&`;
  }

  if (emailEnviado !== undefined) {
    newUrl += `emailEnviado=${emailEnviado}&`;
  }
  if (pdfCreado !== undefined) {
    newUrl += `pdfCreado=${pdfCreado}`;
  }
  if (aprobados !== undefined) {
    newUrl += `aprobado=${aprobados}&`;
  }
  return newUrl;
};

export const getManifiestosCount =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: ActionTypes.FETCH_MANIFIESTOS_COUNT_START });
    const {
      manifiestos: { filters },
    } = getState();
    try {
      let URL = `${API_BASE_URL}/serviceOrders/count?`;
      URL = buildManifiestosUrl(URL, filters);
      const response = await fetch(URL);
      const { count } = await response.json();
      dispatch({
        type: ActionTypes.FETCH_MANIFIESTOS_COUNT_SUCCESS,
        payload: count,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_MANIFIESTOS_COUNT_FAILED,
        payload: error,
      });
    }
  };

export const getManifiestos =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({
      type: ActionTypes.FETCH_MANIFIESTOS_START,
    });
    const {
      manifiestos: { pageSize, currentPage, orderBy, filters },
    } = getState();

    try {
      let url = `${API_BASE_URL}/serviceOrders?`;
      url = buildManifiestosUrl(url, {
        ...filters,
        pageSize,
        currentPage: currentPage,
        orderBy,
      });
      const response = await fetch(url);
      const { count, rows } = await response.json();
      dispatch({
        type: ActionTypes.FETCH_MANIFIESTOS_SUCCESS,
        payload: { rows, count },
      });
      return rows;
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_MANIFIESTOS_FAILED,
        payload: ["Unknown Error", error],
      });
    }
  };

export const fetchPDF =
  (manifiestos: Manifiesto[]) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.FETCH_PDF_START });
    const url = `${API_BASE_URL}/serviceOrders/pdf`;
    const response = await fetch(url, {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ orders: [...manifiestos] }),
    });
    if (response.status === 201) {
      const data = await response.json();
      dispatch({
        type: ActionTypes.FETCH_PDF_SUCCESS,
        payload: [data.urlPDF],
      });
    } else {
      dispatch({
        type: ActionTypes.FETCH_PDF_FAILED,
        payload: [response],
      });
    }
  };

export const downloadPDF =
  (manifiesto: Manifiesto, keepInPage: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.FETCH_PDF_START });
    const url = `${API_BASE_URL}/serviceOrders/pdf`;
    const response = await fetch(url, {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ orders: [manifiesto] }),
    });
    if (response.status === 201) {
      const data = await response.json();
      const link = document.createElement("a");
      if (keepInPage) {
        const manifiestoBlob = await fetch(data.urlPDF);
        const blob = await manifiestoBlob.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        link.href = blobUrl;
      } else {
        link.target = "_blank";
        link.href = data.urlPDF;
      }
      link.setAttribute("download", manifiesto.folio + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({
        type: ActionTypes.FETCH_PDF_SUCCESS,
        payload: [],
      });
    } else {
      dispatch({
        type: ActionTypes.FETCH_PDF_FAILED,
        payload: [response],
      });
    }
  };

export const openPDFViewer =
  ({ open }: { open: boolean }) =>
  (dispatch: Dispatch) => {
    dispatch({
      payload: open,
      type: ActionTypes.OPEN_PDF_VIEWER,
    });
  };

export const downloadMultiplePDF =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch({ type: ActionTypes.FETCH_PDF_START });
      let manifiestos: any[] = [];
      const {
        manifiestos: { manifiestosCount, filters },
      } = getState();
      for (let i = 0; i * 100 < manifiestosCount; i++) {
        let url = `${API_BASE_URL}/serviceOrders?`;
        url = buildManifiestosUrl(url, {
          ...filters,
          pageSize: 100,
          currentPage: i,
        });
        const response = await fetch(url);
        const { rows } = await response.json();
        manifiestos = manifiestos.concat(rows);
      }
      let counter = 0;
      const zip = new JSZip();
      while (counter < manifiestos.length) {
        const url = `${API_BASE_URL}/serviceOrders/pdf`;
        const orders = manifiestos.slice(counter, counter + 30);
        const response = await fetch(url, {
          method: "POST",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({ orders: [...orders] }),
        });
        if (response.status === 201) {
          const data = await response.json();
          for (let i = 0; i < data.urlPDF.length; i++) {
            const url = data.urlPDF[i];
            const pdfResponse = await fetch(url);
            const currentFile = await pdfResponse.blob();
            const name =
              url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf(".pdf")) +
              ".pdf";
            zip.file(name, currentFile);
          }
        }
        counter += 30;
      }

      zip
        .generateAsync({ type: "blob" })
        .then((content) => {
          saveAs(content, "manifiestos.zip");
        })
        .catch((e) => console.log(e));

      dispatch({
        type: ActionTypes.FETCH_PDF_SUCCESS,
        payload: [],
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_PDF_FAILED,
        payload: [error],
      });
    }
  };

export const sendPDFByEmail =
  (manifiesto?: Manifiesto) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: ActionTypes.SEND_EMAIL_START });
    try {
      const emailUrl = `${API_BASE_URL}/serviceOrders/mail`;
      const data: { msg: string; errors: string[] } = { msg: "", errors: [] };
      if (manifiesto) {
        const response = await fetch(emailUrl, {
          method: "POST",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({ orders: [manifiesto] }),
        });
        const percent = 100;
        dispatch({ type: ActionTypes.UPDATE_PERCENT_EMAIL, payload: percent });
        const apiResponse: { msg: string; errors: string[] } =
          await response.json();
        data.errors = data.errors.concat([...apiResponse.errors]);
        data.msg += apiResponse.msg;
        notification.success({ message: "Emails enviados" });
        dispatch({ type: ActionTypes.SEND_EMAIL_SUCCESS, payload: data });
        return;
      } else {
        const {
          manifiestos: { filters },
        } = getState();
        const { startDate, endDate } = filters;
        const sd = dayjs(startDate, "DD-MM-YYYY");
        const ed = dayjs(endDate, "DD-MM-YYYY");
        if (ed.diff(sd, "days") > 2) {
          dispatch({
            type: ActionTypes.SEND_EMAIL_FAILED,
            payload:
              "El envío de mails masivos esta limitado a 2 días, si deseas enviar periodos más largos comunicate con el área de sistemas",
          });
          return;
        }
        let url = `${API_BASE_URL}/serviceOrders?`;
        url = buildManifiestosUrl(url, {
          ...filters,
        });
        const response = await fetch(url);
        const { rows: manifiestos, count: manifiestosCount } =
          await response.json();
        for (let i = 0; i < manifiestosCount; i += 10) {
          const orders = manifiestos.slice(i, i + 10);
          const emailResponse = await fetch(emailUrl, {
            method: "POST",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ orders: orders }),
          });
          const percent = ((i / manifiestosCount) * 100).toFixed(2);

          dispatch({
            type: ActionTypes.UPDATE_PERCENT_EMAIL,
            payload: percent,
          });
          const currentResponse: { msg: string; errors: string[] } =
            await emailResponse.json();

          data.errors = data.errors.concat([...currentResponse.errors]);
          data.msg += currentResponse.msg;
        }
        notification.success({ message: "Emails enviados" });

        dispatch({ type: ActionTypes.SEND_EMAIL_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.SEND_EMAIL_FAILED, payload: error });
    }
  };

export const aprobarManifiesto =
  (manifiesto: ManifiestoWithRecolecciones) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.APROBAR_MANIFIESTO_START });
    try {
      const url = `${API_BASE_URL}/serviceOrders/${manifiesto.folio}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({ ...manifiesto }),
      });
      dispatch({
        type: ActionTypes.APROBAR_MANIFIESTO_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: ActionTypes.APROBAR_MANIFIESTO_FAILED });
    }
  };

export const crearManifiesto =
  (manifiesto: ManifiestoWithRecolecciones) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.CREAR_MANIFIESTO_START });
    try {
      const url = `${API_BASE_URL}/serviceOrders`;
      const response = await fetch(url, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({ ...manifiesto }),
      });
      dispatch({
        type: ActionTypes.CREAR_MANIFIESTO_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: ActionTypes.CREAR_MANIFIESTO_FAILED });
    }
  };
