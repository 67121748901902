import { TIPOS_FACTURACION_VALUES } from "./constants";
import { Checkbox, DatePicker, Form, Input } from "antd";
const ExtraControls = ({ tipo }: { tipo: TIPOS_FACTURACION_VALUES }) => {
  switch (tipo) {
    case TIPOS_FACTURACION_VALUES.DIA_DEL_MES:
      return (
        <Form.Item label="Días del mes" name="diasDelMes" className="mb-2">
          <Input
            type="text"
            placeholder="Escribe los dias separados por comas, ejemplo: 15, 30, 31"
          />
        </Form.Item>
      );
    case TIPOS_FACTURACION_VALUES.DIA_DE_LA_SEMANA:
      return (
        <Form.Item
          label="Días de la semana"
          name="diasDeLaSemana"
          className="mb-2"
        >
          <Checkbox.Group
            className="flex-wrap"
            options={[
              { label: "Lunes", value: "Lunes" },
              { label: "Martes", value: "Martes" },
              { label: "Miercoles", value: "Miercoles" },
              { label: "Jueves", value: "Jueves" },
              { label: "Viernes", value: "Viernes" },
              { label: "Sabado", value: "Sabado" },
              { label: "Domingo", value: "Domingo" },
            ]}
          ></Checkbox.Group>
        </Form.Item>
      );
    case TIPOS_FACTURACION_VALUES.FRECUENCIA:
      return (
        <Form.Item
          label="Frecuencia"
          name="frecuencia"
          className="mb-2"
          initialValue={1}
        >
          <Input type="number" min={1} />
        </Form.Item>
      );
    case TIPOS_FACTURACION_VALUES.FECHA_ESPECIFICA:
      return (
        <Form.Item
          label="Selecciona la fecha de facturación"
          name="fechaEspecifica"
          className="mb-2"
        >
          <DatePicker showTime={false} />
        </Form.Item>
      );

    default:
      return <></>;
  }
};

export default ExtraControls;
