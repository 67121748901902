import { Button, Divider, Form, Input, Select, Spin } from "antd";
import { ManifiestoWithRecolecciones } from "../../redux/reducers/manifiestos";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { DRIVIN_BASE_URL } from "../../utils/api";
import TextArea from "antd/es/input/TextArea";
import { BsPencil, BsX } from "react-icons/bs";
import { useAppDispatch } from "../../hooks";
import { fetchProducts } from "../../redux/actions/productos.actions";
import AgregarRecoleccionForm from "./AgregarRecoleccionForm";
import EditarRecoleccionForm from "./EditarRecoleccionForm";

interface IProps {
  manifiesto: ManifiestoWithRecolecciones;
  onFinishEdit: (values: any) => void;
}
const EditorFormulariosConErrores = ({ manifiesto, onFinishEdit }: IProps) => {
  /** UTILS */
  const dispatch = useAppDispatch();
  const [form] = useForm();

  /**INNER STATE */
  const [conductores, setConductores] = useState<any[]>([]);
  const [asistentes, setAsistentes] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isRecoleccionEditorOpen, setIsRecoleccionEditorOpen] = useState(false);

  const [selectedRecoleccion, setSelectedRecoleccion] = useState<any>();
  const [currentManifiesto, setCurrentManifiesto] = useState(
    structuredClone(manifiesto)
  );

  /**Effects */
  useEffect(() => {
    const fetchConductores = async () => {
      const drivinResponse = await fetch(`${DRIVIN_BASE_URL}/users`, {
        headers: {
          "content-type": "application/json",
          "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
        },
      });
      const drivinFormatedData = await drivinResponse.json();
      const tempConductores = drivinFormatedData.response
        .filter((user: any) => user.role_name === "driver")
        .map((conductor: any) => {
          return `${conductor.first_name} ${conductor.last_name}`;
        });
      const tempAsistentes = drivinFormatedData.response
        .filter((user: any) => user.role_name === "peoneta")
        .map((conductor: any) => {
          return `${conductor.first_name} ${conductor.last_name}`;
        });
      setConductores(tempConductores);
      setAsistentes(tempAsistentes);
      setLoading(false);
    };
    setLoading(true);
    dispatch(fetchProducts());
    fetchConductores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAprobarManifiesto = (values: any) => {
    const finalValues = {
      ...currentManifiesto,
      ...values,
      aprobado: true,
    };
    onFinishEdit(finalValues);
  };

  const handleOpenEditRecoleccion = (recoleccion: any) => {
    setSelectedRecoleccion(recoleccion);
    setIsRecoleccionEditorOpen(true);
  };

  const handleDeleteRecoleccion = (idx: number) => {
    const temp = { ...currentManifiesto };
    temp.recoleccions.splice(idx, 1);
    setCurrentManifiesto(temp);
  };

  const handleAddRecoleccion = (
    cantidad: number,
    codigoProducto: string,
    size: string
  ) => {
    const temp = { ...currentManifiesto };
    temp.recoleccions.push({
      cantidad: cantidad,
      codigo_producto: codigoProducto,
      size,
    });
    setCurrentManifiesto(temp);
  };

  const handleFinishEdit = (
    cantidad: number,
    codigoProducto: string,
    size: string
  ) => {
    const temp = { ...currentManifiesto };

    const rowToEdit = temp.recoleccions.findIndex(
      (recoleccion: any) =>
        recoleccion.cantidad === selectedRecoleccion.cantidad &&
        recoleccion.codigo_producto === selectedRecoleccion.codigo_producto &&
        recoleccion.size === selectedRecoleccion.size
    );
    temp.recoleccions[rowToEdit] = {
      cantidad: cantidad,
      codigo_producto: codigoProducto,
      size: size,
    };
    setCurrentManifiesto(temp);
  };

  return loading ? (
    <Spin />
  ) : (
    <>
      {isRecoleccionEditorOpen && (
        <EditarRecoleccionForm
          isOpen={isRecoleccionEditorOpen}
          setIsOpen={setIsRecoleccionEditorOpen}
          recoleccion={selectedRecoleccion}
          onFinishEdit={handleFinishEdit}
        />
      )}
      <Form
        id="editor-manifiesto-form"
        onFinish={handleAprobarManifiesto}
        form={form}
        initialValues={{ ...currentManifiesto }}
        layout="horizontal"
        className="rounded-md p-2 text-lg"
      >
        <div className="flex justify-between">
          <Form.Item className="mr-2" label="Folio" name={"folio"}>
            <Input placeholder="Folio" size="large" />
          </Form.Item>
          <b># Cliente: </b> <span>{currentManifiesto.codigoCliente}</span>
        </div>
        <b>Cliente: </b> <span>{currentManifiesto.nombreCliente}</span>
        <div className="flex ">
          <b>Fecha y hora: </b>{" "}
          <span className="ml-3">
            {moment(currentManifiesto.fechaHora).format("LLL A")}
          </span>
        </div>
        <Divider>Recibio dinero</Divider>
        <div className="flex justify-between">
          <Form.Item className="mr-2" label="Recibio" name={"recibioDinero"}>
            <Select
              options={[
                { label: "Si", value: true },
                { label: "No", value: false },
              ]}
              size="large"
            />
          </Form.Item>
          <Form.Item label="Cuanto" name={"dinero"}>
            <Input type="number" size="large" />
          </Form.Item>
        </div>
        <Divider>Información de ruta</Divider>
        <div className="flex justify-between">
          <Form.Item className="mr-2" label="Vehiculo" name={"codigoVehiculo"}>
            <Input placeholder="Vehiculo" size="large" />
          </Form.Item>
          <Form.Item label="Ruta" name={"ruta"}>
            <Input placeholder="Ruta" size="large" />
          </Form.Item>
        </div>
        <Form.Item label="Chofer" name={"chofer"}>
          <Select
            showSearch
            size="large"
            options={conductores.map((conductor) => ({
              label: conductor,
              value: conductor,
            }))}
          />
        </Form.Item>
        <Form.Item label="Asistente" name={"asistente"}>
          <Select
            allowClear
            showSearch
            size="large"
            options={asistentes.map((asistente) => ({
              label: asistente,
              value: asistente,
            }))}
          />
        </Form.Item>
        <Form.Item label="Observaciones" name={"observaciones"}>
          <TextArea size="large" />
        </Form.Item>
        <Divider>Fotos</Divider>
        <div className="flex flex-wrap gap-2 justify-around">
          {JSON.parse(currentManifiesto.fotos).map((foto: string) => (
            <a href={foto} target="_blank" rel="noreferrer">
              <img width={120} height={190} src={foto} alt={""} />
            </a>
          ))}
        </div>
        <Divider>Recolección</Divider>
        <div className="flex justify-around">
          <b>Cantidad</b>
          <b>Tipo</b>
          <b>Acciones</b>
        </div>
        {currentManifiesto?.recoleccions?.map(
          (recoleccion: any, idx: number) => (
            <div className="flex justify-around mb-2" key={idx}>
              <span>{recoleccion.cantidad}</span>
              <span>
                {recoleccion.codigo_producto} {recoleccion.size}
              </span>
              <span>
                <Button
                  type="primary"
                  onClick={() => handleOpenEditRecoleccion(recoleccion)}
                >
                  <BsPencil />
                </Button>
                <Button
                  type="primary"
                  className="ml-2 bg-red-700"
                  onClick={() => handleDeleteRecoleccion(idx)}
                >
                  <BsX />
                </Button>
              </span>
            </div>
          )
        )}
        <AgregarRecoleccionForm onAdd={handleAddRecoleccion} />
        <div className="flex justify-end mr-5">
          <Button type="primary" htmlType="submit" size="large">
            Aprobar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditorFormulariosConErrores;
