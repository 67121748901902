import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { ClientEcomexico } from "../../utils/types";
import { useAppDispatch } from "../../hooks";
import { filterClients } from "../../redux/actions/client.actions";

type IFormProps = {
  codigo_cliente: string;
  nombre_cliente: string;
  domicilio: string;
};

const Filters = () => {
  const [formRef] = useForm();
  const dispatch = useAppDispatch();
  const clientes: ClientEcomexico[] = useAppSelector(
    (state) => state.clients.clientsData
  );
  const isLoading = useAppSelector((state) => state.clients.isLoading);

  const filterData = ({
    codigo_cliente,
    nombre_cliente,
    domicilio,
  }: IFormProps) => {
    let codigos: string[] = [];
    let resultData: ClientEcomexico[] = [...clientes];
    if (codigo_cliente) {
      const temp = codigo_cliente.trim().replace(" ", "");
      codigos = temp === "" ? [] : temp.split(",");
    }
    if (codigos.length > 0) {
      resultData = clientes.filter((cliente) =>
        codigos.includes(cliente.codigoCliente)
      );
    }
    if (nombre_cliente) {
      resultData = clientes.filter((cliente) =>
        cliente.nombre?.toUpperCase().includes(nombre_cliente.toUpperCase())
      );
    }
    if (domicilio) {
      resultData = clientes.filter((cliente) =>
        cliente.domicilio?.toUpperCase().includes(domicilio.toUpperCase())
      );
    }
    dispatch(filterClients(resultData));
  };

  return (
    <div className="border border-gray-300 p-5 mr-2 rounded-lg mb-2">
      <div className="flex flex-row">
        <h1 className="text-xl font-semibold text-green-700">Filtros</h1>
      </div>
      <div className="flex flex-row mb-5">
        <Form
          form={formRef}
          onFinish={filterData}
          className="flex flex-row flex-wrap w-full"
        >
          <div className="lg:basis-1/4 md:basis-1/2 px-2 flex-col">
            <label>Número de cliente</label>
            <Form.Item name="codigo_cliente">
              <Input
                type="text"
                size="large"
                className="w-full mb-2 "
                placeholder="200, 1000, 50, ..."
              />
            </Form.Item>
          </div>
          <div className="lg:basis-1/4 md:basis-1/2 px-2 flex-col">
            <label>Nombre cliente</label>
            <Form.Item name="nombre_cliente">
              <Input
                className="w-full mb-2 "
                size="large"
                allowClear
                placeholder="El zarandeado..."
              />
            </Form.Item>
          </div>
          <div className="lg:basis-1/4 md:basis-1/2 px-2 flex-col">
            <label>Domicilio</label>
            <Form.Item name="domicilio">
              <Input
                className="w-full mb-2 "
                size="large"
                allowClear
                placeholder="Zapopan..."
              />
            </Form.Item>
          </div>
          <div className="lg:basis-1/4 md:basis-1/2 mt-5">
            <Button
              type="default"
              className="bg-blue-800 text-white hover:text-blue-800 hover:bg-white hover:border-blue-800 w-full"
              htmlType="submit"
              disabled={isLoading}
            >
              Buscar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Filters;
