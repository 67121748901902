import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useAppSelector } from "../../redux/hooks";
import "./PDFViewer.styles.css";
const PDFViewer = () => {
  const pdf = useAppSelector((state) => state.manifiestos.pdfs);
  const [numPages, setNumPages] = useState<number>(0);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);
  return (
    <div>
      <Document
        file={pdf[0]}
        renderMode="canvas"
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => (
            <Page
              scale={numPages > 1 ? 1 : 2}
              renderInteractiveForms={false}
              renderMode="canvas"
              pageNumber={page}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))}
      </Document>

      <p>Page 1 of 1</p>
    </div>
  );
};

export default PDFViewer;
