import { Button, Table, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import { useAppSelector } from "../../redux/hooks";
import {
  deleteTipoFacturacion,
  getTiposFacturacion,
  saveTipoFacturacion,
} from "../../redux/actions/tiposFacturacion.actions";
import { useAppDispatch } from "../../hooks";
import AddEditModal from "./AddEditModal";
import { TIPOS_FACTURACION_VALUES } from "./constants";
import { TiposFacturacion } from "../../utils/types";

const TiposDeFacturacion = () => {
  /** REDUX */
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.tiposFacturacion.isLoading);
  const isDeleteLoading = useAppSelector(
    (state) => state.tiposFacturacion.isDeleteLoading
  );
  const tiposFacturacion = useAppSelector(
    (state) => state.tiposFacturacion.tiposFacturacion
  );

  /** INNER STATE */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tipoEditing, setTipoEditing] = useState<TiposFacturacion>();
  const [formRef] = useForm();
  /** EFFECTS */
  useEffect(() => {
    dispatch(getTiposFacturacion());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayload = (tipo: TIPOS_FACTURACION_VALUES) => {
    const payload: any = {
      tipo,
      descripcion: formRef.getFieldValue("descripcion"),
    };
    switch (tipo) {
      case TIPOS_FACTURACION_VALUES.DIA_DEL_MES: {
        const dias = formRef
          .getFieldValue("diasDelMes")
          .replace(" ", "")
          .split(",");
        payload.diasDelMes = dias;
        break;
      }
      case TIPOS_FACTURACION_VALUES.DIA_DE_LA_SEMANA: {
        const dias = formRef.getFieldValue("diasDeLaSemana");
        payload.diasDeLaSemana = dias;
        if (!Array.isArray(dias)) {
          payload.diasDeLaSemana = [dias];
        }

        break;
      }
      case TIPOS_FACTURACION_VALUES.FECHA_ESPECIFICA: {
        const fechaEspecifica = formRef.getFieldValue("fechaEspecifica");
        payload.fechaEspecifica = fechaEspecifica.format("YYYY-MM-DD");
        break;
      }
      case TIPOS_FACTURACION_VALUES.FRECUENCIA: {
        const frecuencia = formRef.getFieldValue("frecuencia");
        payload.frecuencia = +frecuencia;
        break;
      }
    }
    return payload;
  };

  const handleAddTipoFacturacion = async () => {
    const tipo: TIPOS_FACTURACION_VALUES = formRef.getFieldValue("tipo");
    const payload = getPayload(tipo);
    await dispatch(saveTipoFacturacion(payload)).catch(() => {
      notification.error({ message: "Error" });
    });
    setIsModalOpen(false);
    await dispatch(getTiposFacturacion());
  };

  const handleEditTipoFacturacion = async () => {
    const tipo: TIPOS_FACTURACION_VALUES = formRef.getFieldValue("tipo");
    const payload = getPayload(tipo);
    await dispatch(saveTipoFacturacion(payload, tipoEditing?.id)).catch(() => {
      notification.error({ message: "Error" });
    });
    setIsModalOpen(false);
    await dispatch(getTiposFacturacion());
  };

  const handleDelete = async (id: string) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "¿Seguro deseas borrar el tipo de facturación? Todos los clientes que esten asignados con este tipo quedaran con el tipo vacio, es necesario re-asignar"
      ) === true
    ) {
      await dispatch(deleteTipoFacturacion(id));
      await dispatch(getTiposFacturacion());
    }
  };

  const closeAddEditModal = () => {
    setTipoEditing(undefined);
    setIsModalOpen(false);
  };

  return (
    <div className="p-2 w-full">
      <Button
        type="primary"
        className="mb-2 flex items-center float-right"
        onClick={() => setIsModalOpen(true)}
      >
        <BsPlusCircle className="mr-2" /> Agregar tipo de facturación
      </Button>

      {isModalOpen && (
        <AddEditModal
          formRef={formRef}
          isModalOpen={isModalOpen}
          elementToEdit={tipoEditing}
          mode={tipoEditing ? "EDIT" : "ADD"}
          handleSave={
            tipoEditing ? handleEditTipoFacturacion : handleAddTipoFacturacion
          }
          handleCancel={closeAddEditModal}
        />
      )}
      <Table
        dataSource={tiposFacturacion}
        loading={isLoading}
        columns={[
          {
            title: "Descripción",
            key: "descripcion",
            dataIndex: "descripcion",
          },
          {
            title: "Acciones",
            key: "id",
            dataIndex: "id",
            render: (value, record) => (
              <div className="flex">
                <Button
                  type="primary"
                  onClick={() => {
                    setTipoEditing(record);
                    setIsModalOpen(true);
                  }}
                >
                  <BsPencil />
                </Button>
                <Button
                  loading={isDeleteLoading}
                  className="ml-2 bg-red-700 text-white border-red-700 hover:border-red-400 hover:bg-red-500"
                  onClick={handleDelete.bind(this, value)}
                >
                  <BsTrash />
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default TiposDeFacturacion;
