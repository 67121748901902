import { Tabs, TabsProps } from "antd";
import React from "react";
import TiposDeFacturacion from "../components/TiposDeFacturacion";
import ListaPrecios from "../components/ListaPrecios/ListaPrecios";

const Categorias = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Tipo de facturación",
      children: <TiposDeFacturacion />,
    },
    {
      key: "2",
      label: "Listas de precios",
      children: <ListaPrecios />,
    },
  ];
  return (
    <div className="p-5">
      <h1 className="text-2xl text-green-800">Categorias</h1>
      <div className="flex w-full">
        <Tabs className="w-full" defaultActiveKey="1" items={items} />
      </div>
    </div>
  );
};

export default Categorias;
