import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Spin,
  Upload,
  UploadProps,
  notification,
} from "antd";
import {
  EMPTY_MANIFIESTO,
  ManifiestoWithRecolecciones,
} from "../../redux/reducers/manifiestos";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { API_BASE_URL, DRIVIN_BASE_URL } from "../../utils/api";
import TextArea from "antd/es/input/TextArea";
import { BsEye, BsPencil, BsPlus, BsX } from "react-icons/bs";
import { useAppDispatch } from "../../hooks";
import { fetchProducts } from "../../redux/actions/productos.actions";
import AgregarRecoleccionForm from "./AgregarRecoleccionForm";
import EditarRecoleccionForm from "./EditarRecoleccionForm";
import {
  crearManifiesto,
  getManifiestos,
} from "../../redux/actions/manifiestos.actions";
import { saveNewFile } from "../../utils/s3";
interface IProps {
  onFinish: () => void;
}
const CreadorManifiestos = ({ onFinish }: IProps) => {
  /** UTILS */
  const dispatch = useAppDispatch();
  const [form] = useForm();

  /**INNER STATE */
  const [conductores, setConductores] = useState<any[]>([]);
  const [asistentes, setAsistentes] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isRecoleccionEditorOpen, setIsRecoleccionEditorOpen] = useState(false);
  const [selectedRecoleccion, setSelectedRecoleccion] = useState<any>();
  const [folio, setFolio] = useState("");
  const [folioEnabled, setFolioEnabled] = useState(true);
  const [currentManifiesto, setCurrentManifiesto] =
    useState<ManifiestoWithRecolecciones>({
      ...EMPTY_MANIFIESTO,
      recoleccions: [],
    });
  const [fotos, setFotos] = useState<string[]>([]);

  /**Effects */
  useEffect(() => {
    const fetchConductores = async () => {
      const drivinResponse = await fetch(`${DRIVIN_BASE_URL}/users`, {
        headers: {
          "content-type": "application/json",
          "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
        },
      });
      const drivinFormatedData = await drivinResponse.json();
      const tempConductores = drivinFormatedData.response
        .filter((user: any) => user.role_name === "driver")
        .map((conductor: any) => {
          return `${conductor.first_name} ${conductor.last_name}`;
        });
      const tempAsistentes = drivinFormatedData.response
        .filter((user: any) => user.role_name === "peoneta")
        .map((conductor: any) => {
          return `${conductor.first_name} ${conductor.last_name}`;
        });
      setConductores(tempConductores);
      setAsistentes(tempAsistentes);
      setLoading(false);
    };
    setLoading(true);
    dispatch(fetchProducts());
    fetchConductores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSiguiente = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!form.getFieldValue("folio") || !form.getFieldValue("codigoCliente")) {
      notification.error({
        message: "Llena todos los campos para continuar",
      });
      setLoading(false);
      return;
    }
    const codigoCliente = form.getFieldValue("codigoCliente");
    const currentFolio = form.getFieldValue("folio").replace(" ", "");
    form.setFieldValue("folio", currentFolio);
    const responseEcomexico = await fetch(
      `${API_BASE_URL}/serviceOrders?folios[]=${currentFolio}`
    );
    const dataEcomexico = await responseEcomexico.json();
    if (dataEcomexico.length > 0) {
      notification.error({ message: "Folio duplicado" });
      form.setFieldValue("folio", "");
      setLoading(false);
      return;
    }
    const response = await fetch(
      `${DRIVIN_BASE_URL}/addresses?code[]=${codigoCliente}`,
      {
        headers: {
          "content-type": "application/json",
          "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
        },
      }
    );
    const data = await response.json();
    if (data.response.length === 0) {
      notification.error({ message: "Cliente no encontrado" });
      form.setFieldValue("nombreCliente", "");
      setLoading(false);
      return;
    }
    form.setFieldValue("nombreCliente", data.response[0].name);
    const domicilio = `${data.response[0].address1 || ""}, ${
      data.response[0].city || ""
    }, ${data.response[0].state || ""} ${data.response[0].country || ""}`;
    const telefono = data.response[0].phone;
    form.setFieldValue("domicilio", domicilio);
    form.setFieldValue("telefono", telefono);
    setFolio(currentFolio);
    setFolioEnabled(false);
    setLoading(false);
  };

  const handleAprobarManifiesto = (values: any) => {
    const finalValues = {
      ...currentManifiesto,
      ...values,
      fechaHora: values.fechaHora.toISOString(),
      fotos: JSON.stringify(fotos),
      aprobado: true,
    };
    dispatch(crearManifiesto(finalValues)).then(() => {
      notification.success({ message: "Creado y aprobado con éxito" });
      onFinish?.();
      dispatch(getManifiestos());
    });
  };

  const handleOpenEditRecoleccion = (recoleccion: any) => {
    setSelectedRecoleccion(recoleccion);
    setIsRecoleccionEditorOpen(true);
  };

  const handleDeleteRecoleccion = (idx: number) => {
    const temp = { ...currentManifiesto };
    temp.recoleccions.splice(idx, 1);
    setCurrentManifiesto(temp);
  };

  const handleAddRecoleccion = (
    cantidad: number,
    codigoProducto: string,
    size: string
  ) => {
    const temp = { ...currentManifiesto };
    temp.recoleccions.push({
      cantidad: cantidad,
      codigo_producto: codigoProducto,
      size,
    });
    setCurrentManifiesto(temp);
  };

  const handleFinishEdit = (
    cantidad: number,
    codigoProducto: string,
    size: string
  ) => {
    const temp = { ...currentManifiesto };
    const rowToEdit = temp.recoleccions.findIndex(
      (recoleccion: any) =>
        recoleccion.cantidad === selectedRecoleccion.cantidad &&
        recoleccion.codigo_producto === selectedRecoleccion.codigo_producto &&
        recoleccion.size === selectedRecoleccion.size
    );
    temp.recoleccions[rowToEdit] = {
      cantidad: cantidad,
      codigo_producto: codigoProducto,
      size: size,
    };
    setCurrentManifiesto(temp);
  };

  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {};
  const handleDeleteFoto = (event: any, index: number) => {
    event.preventDefault();
    const newFotos = fotos.slice(0, index).concat(fotos.slice(index + 1));
    setFotos(newFotos);
  };

  return loading ? (
    <Spin />
  ) : (
    <>
      {isRecoleccionEditorOpen && (
        <EditarRecoleccionForm
          isOpen={isRecoleccionEditorOpen}
          setIsOpen={setIsRecoleccionEditorOpen}
          recoleccion={selectedRecoleccion}
          onFinishEdit={handleFinishEdit}
        />
      )}
      <Form
        id="editor-manifiesto-form"
        onFinish={handleAprobarManifiesto}
        form={form}
        layout="horizontal"
        className="rounded-md p-2 text-lg"
      >
        <div
          className={`flex ${folioEnabled ? "flex-col" : ""} justify-between`}
        >
          <Form.Item className="mr-2" label="Folio" name={"folio"}>
            <Input placeholder="Folio" size="large" disabled={!folioEnabled} />
          </Form.Item>
          <Form.Item className="mr-2" label="# Cliente" name={"codigoCliente"}>
            <Input
              placeholder="Codigo cliente"
              size="large"
              disabled={!folioEnabled}
            />
          </Form.Item>
          {folioEnabled && (
            <Button className="mb-4" onClick={handleSiguiente} type="primary">
              Siguiente
            </Button>
          )}
        </div>
        {!folioEnabled && (
          <>
            <Form.Item
              className="mr-2"
              label="Nombre Cliente"
              name={"nombreCliente"}
            >
              <Input placeholder="Nombre cliente" size="large" disabled />
            </Form.Item>
            <Form.Item
              className="mr-2"
              label="Domicilio cliente"
              name={"domicilio"}
            >
              <Input size="large" disabled />
            </Form.Item>
            <Form.Item
              className="mr-2"
              label="Telefono cliente"
              name={"telefono"}
            >
              <Input size="large" disabled />
            </Form.Item>
            <div className="flex ">
              <Form.Item
                className="mr-2"
                label="Fecha y hora"
                name={"fechaHora"}
              >
                <DatePicker format={"DD-MM-YYYY HH:mm"} showTime />
              </Form.Item>
            </div>
            <Divider>Recibio dinero</Divider>
            <div className="flex justify-between">
              <Form.Item
                className="mr-2"
                label="Recibio"
                name={"recibioDinero"}
              >
                <Select
                  options={[
                    { label: "Si", value: true },
                    { label: "No", value: false },
                  ]}
                  size="large"
                />
              </Form.Item>
              <Form.Item label="Cuanto" name={"dinero"}>
                <Input type="number" size="large" />
              </Form.Item>
            </div>
            <Divider>Información de ruta</Divider>
            <div className="flex justify-between">
              <Form.Item
                className="mr-2"
                label="Vehiculo"
                name={"codigoVehiculo"}
              >
                <Input placeholder="Vehiculo" size="large" />
              </Form.Item>
              <Form.Item label="Ruta" name={"ruta"}>
                <Input placeholder="Ruta" size="large" />
              </Form.Item>
            </div>
            <Form.Item label="Chofer" name={"chofer"}>
              <Select
                showSearch
                size="large"
                options={conductores.map((conductor) => ({
                  label: conductor,
                  value: conductor,
                }))}
              />
            </Form.Item>
            <Form.Item label="Asistente" name={"asistente"}>
              <Select
                allowClear
                showSearch
                size="large"
                options={asistentes.map((asistente) => ({
                  label: asistente,
                  value: asistente,
                }))}
              />
            </Form.Item>
            <Form.Item label="Observaciones" name={"observaciones"}>
              <TextArea size="large" />
            </Form.Item>
            <Divider>Fotos</Divider>
            <div className="flex flex-wrap gap-2 justify-around">
              {fotos.map((foto: string, index: number) => (
                <div>
                  <div className="flex justify-end">
                    <a
                      className="bg-green-500 text-white p-1 w-7 h-w-6 rounded-lg z-10 mr-2"
                      href={foto}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsEye />
                    </a>
                    <button
                      onClick={(e) => handleDeleteFoto(e, index)}
                      className="bg-red-500 text-white p-1 w-7 h-w-6 rounded-lg z-10 "
                    >
                      <BsX />
                    </button>
                  </div>
                  <img
                    width={120}
                    height={190}
                    src={foto}
                    alt={""}
                    className="-mt-2 z-0"
                  />
                </div>
              ))}
              <div>
                <div className="flex w-32 h-48 justify-center items-center border border-green-700 rounded-lg">
                  <Upload
                    listType="picture"
                    fileList={[]}
                    maxCount={1}
                    showUploadList={true}
                    accept="image/png, image/jpeg"
                    onChange={handleChange}
                    style={{ height: "100px" }}
                    customRequest={async ({
                      file,
                      onError,
                      onSuccess,
                      onProgress,
                    }) => {
                      if (
                        typeof file === "string" ||
                        onProgress === undefined ||
                        onError === undefined ||
                        onSuccess === undefined
                      )
                        return;
                      const fileType = file.type;
                      try {
                        const url = await saveNewFile(
                          `${folio}/${fotos.length + 1}`,
                          file,
                          fileType
                        );
                        const fotosTemp = [...fotos];
                        fotosTemp.push(url);
                        setFotos(fotosTemp);
                        onSuccess("");
                      } catch (error: any) {
                        onError(error);
                      }
                    }}
                  >
                    <BsPlus />
                  </Upload>
                </div>
              </div>
            </div>
            <Divider>Recolección</Divider>
            <div className="flex justify-around">
              <b>Cantidad</b>
              <b>Tipo</b>
              <b>Acciones</b>
            </div>
            {currentManifiesto?.recoleccions?.map(
              (recoleccion: any, idx: number) => (
                <div className="flex justify-around mb-2" key={idx}>
                  <span>{recoleccion.cantidad}</span>
                  <span>
                    {recoleccion.codigo_producto} {recoleccion.size}
                  </span>
                  <span>
                    <Button
                      type="primary"
                      onClick={() => handleOpenEditRecoleccion(recoleccion)}
                    >
                      <BsPencil />
                    </Button>
                    <Button
                      type="primary"
                      className="ml-2 bg-red-700"
                      onClick={() => handleDeleteRecoleccion(idx)}
                    >
                      <BsX />
                    </Button>
                  </span>
                </div>
              )
            )}
            <AgregarRecoleccionForm onAdd={handleAddRecoleccion} />
            <div className="flex justify-end mr-5">
              <Button type="primary" htmlType="submit" size="large">
                Aprobar
              </Button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default CreadorManifiestos;
