import { Button, Checkbox, Divider, Form } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
interface IProps {
  initialColumns: string[];
  onSubmit: (uhc: string[]) => void;
  location: string;
}
export const allOptions = [
  "folio",
  "diaSemana",
  "fechaHora",
  "codigoCliente",
  "nombreCliente",
  "codigoVehiculo",
  "ruta",
  "chofer",
  "domicilio",
  "recoleccions",
  "comentarios",
  "emailEnviado",
  "pdfCreado",
  "comentariosInternos",
  "registroCambios",
];
const ColumnsSelector = ({ initialColumns, onSubmit, location }: IProps) => {
  const [formRef] = useForm();
  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(allOptions);
  const checkAll = allOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < allOptions.length;
  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? allOptions : ["folio"]);
    formRef.setFieldValue("columns", e.target.checked ? allOptions : ["folio"]);
  };
  const handleSelectColumns = () => {
    const selectedColumns = checkedList.map((key) => key.toString());
    const uhc = allOptions.filter(
      (option) => !selectedColumns.includes(option)
    );
    localStorage.setItem(
      location === "/" ? "approvedHiddenColumns" : "nonApprovedHiddenColumns",
      JSON.stringify(uhc)
    );
    onSubmit(uhc);
  };
  return (
    <Form
      form={formRef}
      onFinish={handleSelectColumns}
      initialValues={{ columns: initialColumns }}
    >
      <Divider>Columnas</Divider>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        Incluir todas
      </Checkbox>
      <Form.Item name={"columns"}>
        <Checkbox.Group
          value={checkedList}
          onChange={onChange}
          className="flex flex-wrap justify-between w-full"
          options={[
            {
              style: { width: 150, marginLeft: "8px" },
              label: "Folio",
              value: "folio",
              disabled: true,
            },
            {
              style: { width: 150 },
              label: "Dia de la semana",
              value: "diaSemana",
            },
            {
              style: { width: 150 },
              label: "Fecha y hora",
              value: "fechaHora",
            },
            {
              style: { width: 150 },
              label: "Numero Cliente",
              value: "codigoCliente",
            },
            {
              style: { width: 150 },
              label: "Nombre Cliente",
              value: "nombreCliente",
            },
            {
              style: { width: 150 },
              label: "Vehiculo",
              value: "codigoVehiculo",
            },
            { style: { width: 150 }, label: "Ruta", value: "ruta" },
            { style: { width: 150 }, label: "Chofer", value: "chofer" },
            { style: { width: 150 }, label: "Domicilio", value: "domicilio" },
            {
              style: { width: 150 },
              label: "Recolecciones",
              value: "recoleccions",
            },
            {
              style: { width: 150 },
              label: "Comentarios",
              value: "comentarios",
            },
            {
              style: { width: 150 },
              label: "Comentarios Internos",
              value: "comentariosInternos",
            },
            {
              style: { width: 150 },
              label: "Registro de cambios",
              value: "registroCambios",
            },
            {
              style: { width: 150 },
              label: "Email Enviado",
              value: "emailEnviado",
              disabled: location === "/monitoreo",
            },
            {
              style: { width: 150 },
              label: "PDF Creado",
              value: "pdfCreado",
              disabled: location === "/monitoreo",
            },
          ]}
        ></Checkbox.Group>
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Mostrar/Ocultar columnas
      </Button>
    </Form>
  );
};

export default ColumnsSelector;
