import { S3 } from "aws-sdk";

let s3Instance: null | S3 = null;
const getS3Instance = () => {
  if (s3Instance === null) {
    s3Instance = new S3({
      region: "us-east-1",
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || "",
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || "",
      },
    });
  }
  return s3Instance;
};

export const getSignedUrl = async (fileName: string) => {
  const s3 = getS3Instance();
  const options = {
    Bucket: process.env.REACT_APP_AWS_BUCKET || "",
    Key: fileName,
  };
  try {
    await s3.headObject(options).promise();
    const url = await s3.getSignedUrlPromise("getObject", options);
    return url;
  } catch (error) {
    return "";
  }
};

export const checkIfFileExists = async (fileName: string) => {
  const s3 = getS3Instance();
  const options = {
    Bucket: process.env.REACT_APP_AWS_BUCKET || "",
    Key: fileName,
  };
  try {
    await s3.headObject(options).promise();
    return true;
  } catch (error) {
    return false;
  }
};

export const deleteFile = async (fileName: string) => {
  const s3 = getS3Instance();
  const options = {
    Bucket: process.env.REACT_APP_AWS_BUCKET || "",
    Key: fileName,
  };
  try {
    await s3.deleteObject(options).promise();
  } catch (error) {
    return "";
  }
};

export const saveNewFile = async (
  fileName: string,
  file: any,
  fileType: any
) => {
  const s3 = getS3Instance();
  const options = {
    Bucket: process.env.REACT_APP_AWS_BUCKET || "",
    Key: fileName,
  };
  try {
    await s3
      .putObject({ ...options, Body: file, ContentType: fileType })
      .promise();
    return `https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/${fileName}`;
  } catch (error) {
    return "";
  }
};

export default getS3Instance;
