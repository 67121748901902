import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  Modal,
  Spin,
  notification,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import { fetchOneProduct } from "../../redux/actions/productos.actions";
import { Product, TranslationSaveBody } from "../../utils/types";
import { BsCheck, BsPlusCircle, BsX } from "react-icons/bs";
interface IProps {
  isModalOpen: boolean;
  formRef: FormInstance;
  mode?: "ADD" | "EDIT";
  elementToEdit?: string;
  handleSave: () => void;
  handleCancel: () => void;
}
const AddEditModal = ({
  isModalOpen,
  formRef,
  mode,
  elementToEdit,
  handleSave,
  handleCancel,
}: IProps) => {
  const dispatch = useAppDispatch();
  const isSaveLoading = useAppSelector((state) => state.products.isSaveLoading);
  const isLoadingOne = useAppSelector((state) => state.products.isLoadingOne);
  const currentProduct: Product[] = useAppSelector(
    (state) => state.products.currentProduct
  );
  const products: Product[] = useAppSelector(
    (state) => state.products.products
  );
  const defaultTranslations: TranslationSaveBody[] = useAppSelector(
    (state) => state.products.defaultTranslations
  );
  const [showAddSizeInputs, setShowAddSizeInputs] = useState(false);
  const [sizesArray, setSizesArray] = useState<Product[]>([]);
  const [addSizeName, setAddSizeName] = useState("");
  const [productName, setProductName] = useState("");
  const [productNameDisabled, setProductNameDisabled] = useState(false);
  useEffect(() => {
    if (elementToEdit) {
      dispatch(fetchOneProduct(elementToEdit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementToEdit]);

  useEffect(() => {
    if (currentProduct) {
      setSizesArray(currentProduct);
    }
  }, [currentProduct]);

  useEffect(() => {
    if (elementToEdit) {
      formRef.setFieldValue("producto", elementToEdit);
      setProductName(elementToEdit);
      products.forEach((producto) => {
        const traduccion = defaultTranslations.find(
          (trans) => trans.size === producto.size
        );
        if (traduccion) {
          formRef.setFieldValue(`${producto.size}`, traduccion.descripcion);
        }
      });
    } else {
      formRef.resetFields();
      setSizesArray([]);
    }
  }, [elementToEdit, formRef, defaultTranslations, products]);

  const handleAddNewSize = () => {
    const found = sizesArray.find((size) => size.size === addSizeName);
    if (found) {
      notification.error({ message: "El tamaño elegido ya existe" });
      return;
    }
    const sizes = [...sizesArray];
    if (elementToEdit) {
      sizes.push({ producto: elementToEdit, size: addSizeName });
    } else {
      sizes.push({ producto: productName, size: addSizeName });
    }
    setSizesArray(sizes);
    setShowAddSizeInputs(false);
  };

  const handleRemove = (size: string) => {
    const indexToRemove = sizesArray.findIndex((c) => c.size === size);
    const newArray = [...sizesArray];
    newArray.splice(indexToRemove, 1);
    setSizesArray(newArray);
  };

  return (
    <Modal
      title={
        mode === "ADD"
          ? "Agregar producto, tamaños y traducciones"
          : "Editar producto, tamaños y traducciones"
      }
      width={850}
      open={isModalOpen}
      onOk={handleSave}
      okButtonProps={{
        className: " disabled:bg-slate-100",
        loading: isSaveLoading,
        disabled: sizesArray.length === 0 || productName === "",
      }}
      okText={"Guardar"}
      cancelText={"Cancelar"}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form form={formRef} className="w-full">
        <Form.Item label="Nombre del producto" name="producto" className="mb-2">
          <Input
            onChange={(e) => setProductName(e.target.value)}
            onKeyDown={(key) => {
              if (key.which === 32) {
                key.preventDefault();
              }
            }}
            onPaste={(e) => {
              notification.warning({
                message: "Función 'Pegar' deshabilitado para este campo",
              });
              e.preventDefault();
            }}
            disabled={mode === "EDIT" || productNameDisabled}
            type="text"
            placeholder="Nombre del producto"
          />
        </Form.Item>
        {mode === "ADD" && productNameDisabled === false ? (
          <Button
            type="primary"
            disabled={productName === ""}
            onClick={() => {
              const found = products.find(
                (producto) => producto.producto === productName
              );
              if (found) {
                notification.error({
                  message:
                    "El producto ya existe en la lista, busca el producto y da click en editar",
                });
              } else {
                setProductNameDisabled(true);
              }
            }}
          >
            Agregar tamaños
          </Button>
        ) : (
          <>
            <Divider>Traducciones y tamaños</Divider>
            <div className="flex flex-wrap justify-around">
              {isLoadingOne ? (
                <Spin />
              ) : (
                <>
                  {sizesArray.map((product) => (
                    <>
                      <Form.Item
                        className="w-1/2 px-2"
                        key={product.size}
                        label={product.size}
                        name={`${product.size}`}
                      >
                        <Input placeholder="Traduccion" />
                        <Button
                          className="bg-red-600 text-white"
                          onClick={() => handleRemove(product.size)}
                        >
                          <BsX />
                        </Button>
                      </Form.Item>
                    </>
                  ))}
                  {showAddSizeInputs ? (
                    <div className="w-1/2 px-2 flex flex-wrap flex-row justify-around mb-6">
                      <label className="mt-1 mr-1" htmlFor="add-size">
                        Tamaño/Tipo:
                      </label>
                      <Input
                        className="w-1/2"
                        name="add-size"
                        onKeyDown={(key) => {
                          if (key.which === 32) {
                            key.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          notification.warning({
                            message:
                              "Función 'Pegar' deshabilitado para este campo",
                          });
                          e.preventDefault();
                        }}
                        onChange={(value) => {
                          setAddSizeName(value.target.value);
                        }}
                      />
                      <Button type="primary" onClick={handleAddNewSize}>
                        <BsCheck />
                      </Button>
                      <Button
                        className="bg-red-600 hover:bg-red-400 text-white"
                        onClick={() => setShowAddSizeInputs(false)}
                      >
                        <BsX />
                      </Button>
                    </div>
                  ) : (
                    <Button
                      type="primary"
                      className="flex"
                      onClick={() => setShowAddSizeInputs(true)}
                    >
                      <BsPlusCircle className="mt-1 mr-1" /> Agregar nuevo
                      tamaño
                    </Button>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default AddEditModal;
