import React, { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { Translation } from "../../utils/types";
import ClientTraductionForm from "./ClientTraductionForm";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteTranslationEntry } from "../../redux/actions/client.actions";

const TranslateTable = () => {
  const dispatch = useAppDispatch();
  const currentClient = useAppSelector((state) => state.clients.currentClient);
  const [translations, setTranslations] = useState<Translation[]>([]);
  useEffect(() => {
    if (currentClient && currentClient.translations) {
      setTranslations(currentClient.translations);
    }
  }, [currentClient]);

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    e.preventDefault();
    dispatch(deleteTranslationEntry(idx));
  };

  return (
    <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-200 uppercase bg-green-700 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th>Producto</th>
          <th>Tamaño</th>
          <th>Traducción</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {translations.map((translation: Translation, idx: number) => (
          <tr key={idx}>
            <td>{translation.codigo_producto}</td>
            <td>{translation.size}</td>
            <td>{translation.descripcion}</td>
            <td>
              <button
                onClick={(e) => handleDelete(e, idx)}
                className="bg-red-700 text-white p-2 rounded"
              >
                <BsTrash />
              </button>
            </td>
          </tr>
        ))}
        <ClientTraductionForm />
      </tbody>
    </table>
  );
};

export default TranslateTable;
