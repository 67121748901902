import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  Spin,
  Upload,
  UploadProps,
  notification,
} from "antd";
import { ManifiestoWithRecolecciones } from "../../redux/reducers/manifiestos";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { DRIVIN_BASE_URL } from "../../utils/api";
import TextArea from "antd/es/input/TextArea";
import {
  BsCheckCircleFill,
  BsEye,
  BsPencil,
  BsPlus,
  BsX,
  BsXCircleFill,
} from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchProducts } from "../../redux/actions/productos.actions";
import AgregarRecoleccionForm from "./AgregarRecoleccionForm";
import EditarRecoleccionForm from "./EditarRecoleccionForm";
import {
  aprobarManifiesto,
  getManifiestos,
  sendPDFByEmail,
} from "../../redux/actions/manifiestos.actions";
import { saveNewFile } from "../../utils/s3";
import { fetchClientsData } from "../../redux/actions/client.actions";

interface IProps {
  manifiesto: ManifiestoWithRecolecciones;
  onFinishEdit: () => void;
}
const EditorManifiestos = ({ manifiesto, onFinishEdit }: IProps) => {
  /** UTILS */
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const {
    clients: { clientsData, isLoading },
  } = useAppSelector((state) => state);

  /**INNER STATE */
  const [conductores, setConductores] = useState<any[]>([]);
  const [asistentes, setAsistentes] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isRecoleccionEditorOpen, setIsRecoleccionEditorOpen] = useState(false);
  const [selectedRecoleccion, setSelectedRecoleccion] = useState<any>();
  const [currentManifiesto, setCurrentManifiesto] = useState(
    structuredClone(manifiesto)
  );
  const [fotos, setFotos] = useState(JSON.parse(currentManifiesto.fotos));

  /**Effects */
  useEffect(() => {
    const fetchConductores = async () => {
      const drivinResponse = await fetch(`${DRIVIN_BASE_URL}/users`, {
        headers: {
          "content-type": "application/json",
          "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
        },
      });
      const drivinFormatedData = await drivinResponse.json();
      const tempConductores = drivinFormatedData.response
        .filter((user: any) => user.role_name === "driver")
        .map((conductor: any) => {
          return `${conductor.first_name} ${conductor.last_name}`;
        });
      const tempAsistentes = drivinFormatedData.response
        .filter((user: any) => user.role_name === "peoneta")
        .map((conductor: any) => {
          return `${conductor.first_name} ${conductor.last_name}`;
        });
      setConductores(tempConductores);
      setAsistentes(tempAsistentes);
      setLoading(false);
    };
    setLoading(true);
    dispatch(fetchProducts());
    dispatch(fetchClientsData());
    fetchConductores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAprobarManifiesto = async (values: any) => {
    const finalValues = {
      ...currentManifiesto,
      ...values,
      fotos: JSON.stringify(fotos),
      aprobado: true,
      registroCambios:
        currentManifiesto.codigoCliente !== values.codigoCliente
          ? `${currentManifiesto.registroCambios}; Se cambió el cliente, originalmente pertenecia al cliente ${currentManifiesto.codigoCliente}`
          : currentManifiesto.registroCambios,
    };

    await dispatch(aprobarManifiesto(finalValues));
    notification.success({ message: "Aprobado con éxito" });
    if (values.sendMail) {
      await dispatch(sendPDFByEmail(finalValues));
    }

    await dispatch(getManifiestos());
    onFinishEdit();
  };

  const handleOpenEditRecoleccion = (recoleccion: any) => {
    setSelectedRecoleccion(recoleccion);
    setIsRecoleccionEditorOpen(true);
  };

  const handleDeleteRecoleccion = (idx: number) => {
    const temp = { ...currentManifiesto };
    temp.recoleccions.splice(idx, 1);
    setCurrentManifiesto(temp);
  };

  const handleAddRecoleccion = (
    cantidad: number,
    codigoProducto: string,
    size: string
  ) => {
    const temp = { ...currentManifiesto };

    temp.recoleccions.push({
      cantidad: cantidad,
      codigo_producto: codigoProducto,
      size,
    });
    setCurrentManifiesto(temp);
  };

  const handleFinishEdit = (
    cantidad: number,
    codigoProducto: string,
    size: string
  ) => {
    const temp = { ...currentManifiesto };

    const rowToEdit = temp.recoleccions.findIndex(
      (recoleccion: any) =>
        recoleccion.cantidad === selectedRecoleccion.cantidad &&
        recoleccion.codigo_producto === selectedRecoleccion.codigo_producto &&
        recoleccion.size === selectedRecoleccion.size
    );
    temp.recoleccions[rowToEdit] = {
      cantidad: cantidad,
      codigo_producto: codigoProducto,
      size: size,
    };
    setCurrentManifiesto(temp);
  };

  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {};
  const handleDeleteFoto = (event: any, index: number) => {
    event.preventDefault();
    const newFotos = fotos.slice(0, index).concat(fotos.slice(index + 1));
    setFotos(newFotos);
  };

  const handleChangeCodigo = (value: any, option: any) => {
    const cliente = clientsData.find(
      (client) => client.codigoCliente === value
    );
    form.setFieldValue("domicilio", cliente?.domicilio);
    form.setFieldValue("nombreCliente", cliente?.nombre);
    form.setFieldValue("cliente", cliente);
  };

  return loading ? (
    <Spin />
  ) : (
    <>
      {isRecoleccionEditorOpen && (
        <EditarRecoleccionForm
          isOpen={isRecoleccionEditorOpen}
          setIsOpen={setIsRecoleccionEditorOpen}
          recoleccion={selectedRecoleccion}
          onFinishEdit={handleFinishEdit}
        />
      )}
      <Form
        id="editor-manifiesto-form"
        onFinish={handleAprobarManifiesto}
        form={form}
        initialValues={{
          ...currentManifiesto,
          fech: moment(currentManifiesto.fechaHora).format("LLL A"),
        }}
        layout="horizontal"
        className="rounded-md p-2 text-lg"
      >
        <div className="flex justify-between">
          <b>Folio: </b>
          <span className="w-full">{currentManifiesto.folio}</span>
          <Form.Item className="w-full" name="codigoCliente" label="# Cliente">
            <Select
              onChange={handleChangeCodigo}
              loading={isLoading}
              disabled={isLoading}
              showSearch
              options={clientsData.map((client) => ({
                label: client.codigoCliente,
                value: client.codigoCliente,
              }))}
            />
          </Form.Item>
        </div>
        <Form.Item name="nombreCliente" label="Cliente">
          <Input className="input-disabled-remarked" disabled />
        </Form.Item>
        <Form.Item name="cliente" label="Cliente" hidden>
          <Input className="input-disabled-remarked" disabled />
        </Form.Item>
        <Form.Item name="fech" label="Fecha y hora">
          <Input className="input-disabled-remarked" disabled />
        </Form.Item>
        <Form.Item name="domicilio" label="Domicilio">
          <Input className="input-disabled-remarked" disabled />
        </Form.Item>
        <Divider>Recibio dinero</Divider>
        <div className="flex justify-between">
          <Form.Item className="mr-2" label="Recibio" name={"recibioDinero"}>
            <Select
              options={[
                { label: "Si", value: true },
                { label: "No", value: false },
              ]}
              size="large"
            />
          </Form.Item>
          <Form.Item label="Cuanto" name={"dinero"}>
            <Input type="number" size="large" />
          </Form.Item>
        </div>
        <Divider>Información de ruta</Divider>
        <div className="flex justify-between">
          <Form.Item className="mr-2" label="Vehiculo" name={"codigoVehiculo"}>
            <Input placeholder="Vehiculo" size="large" />
          </Form.Item>
          <Form.Item label="Ruta" name={"ruta"}>
            <Input placeholder="Ruta" size="large" />
          </Form.Item>
        </div>
        <Form.Item label="Chofer" name={"chofer"}>
          <Select
            showSearch
            size="large"
            options={conductores.map((conductor) => ({
              label: conductor,
              value: conductor,
            }))}
          />
        </Form.Item>
        <Form.Item label="Asistente" name={"asistente"}>
          <Select
            allowClear
            showSearch
            size="large"
            options={asistentes.map((asistente) => ({
              label: asistente,
              value: asistente,
            }))}
          />
        </Form.Item>
        <div className="flex">
          <h1>¿El cliente firmó?</h1>
          {currentManifiesto.firma ? (
            <>
              <BsCheckCircleFill
                color="green"
                style={{ marginLeft: 10, marginTop: 4 }}
              />
              <img
                src={currentManifiesto.firma}
                alt=""
                width={160}
                height={160}
              />
            </>
          ) : (
            <BsXCircleFill
              color="red"
              style={{ marginLeft: 10, marginTop: 4 }}
            />
          )}
        </div>
        <Form.Item label="Observaciones" name={"observaciones"}>
          <TextArea size="large" />
        </Form.Item>
        <Form.Item
          label="Comentarios internos"
          name={"comentariosInternos"}
          help="Estos comentarios no afectaran el PDF ni los vera el cliente."
        >
          <TextArea size="large" />
        </Form.Item>
        <Divider>Fotos</Divider>
        <div className="flex flex-wrap gap-2 justify-around">
          {fotos.map((foto: string, index: number) => (
            <div>
              <div className="flex justify-end">
                <a
                  className="bg-green-500 text-white p-1 w-7 h-w-6 rounded-lg z-10 mr-2"
                  href={foto}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsEye />
                </a>
                <button
                  onClick={(e) => handleDeleteFoto(e, index)}
                  className="bg-red-500 text-white p-1 w-7 h-w-6 rounded-lg z-10 "
                >
                  <BsX />
                </button>
              </div>
              <img
                width={120}
                height={190}
                src={foto}
                alt={""}
                className="-mt-2 z-0"
              />
            </div>
          ))}
          <div>
            <div className="flex w-32 h-48 justify-center items-center border border-green-700 rounded-lg">
              <Upload
                listType="picture"
                fileList={[]}
                maxCount={1}
                showUploadList={true}
                accept="image/png, image/jpeg"
                onChange={handleChange}
                style={{ height: "100px" }}
                customRequest={async ({
                  file,
                  onError,
                  onSuccess,
                  onProgress,
                }) => {
                  if (
                    typeof file === "string" ||
                    onProgress === undefined ||
                    onError === undefined ||
                    onSuccess === undefined
                  )
                    return;
                  const fileType = file.type;
                  try {
                    const url = await saveNewFile(
                      `${currentManifiesto.folio}/${fotos.length + 1}`,
                      file,
                      fileType
                    );
                    const fotosTemp = [...fotos];
                    fotosTemp.push(url);
                    setFotos(fotosTemp);
                    onSuccess("");
                  } catch (error: any) {
                    onError(error);
                  }
                }}
              >
                <BsPlus />
              </Upload>
            </div>
          </div>
        </div>
        <Divider>Recolección</Divider>
        <div className="flex justify-around">
          <b>Cantidad</b>
          <b>Tipo</b>
          <b>Acciones</b>
        </div>
        {currentManifiesto?.recoleccions?.map(
          (recoleccion: any, idx: number) => (
            <div className="flex justify-around mb-2" key={idx}>
              <span>{recoleccion.cantidad}</span>
              <span>
                {recoleccion.codigo_producto} {recoleccion.size}
              </span>
              <span>
                <Button
                  type="primary"
                  onClick={() => handleOpenEditRecoleccion(recoleccion)}
                >
                  <BsPencil />
                </Button>
                <Button
                  type="primary"
                  className="ml-2 bg-red-700"
                  onClick={() => handleDeleteRecoleccion(idx)}
                >
                  <BsX />
                </Button>
              </span>
            </div>
          )
        )}
        <AgregarRecoleccionForm onAdd={handleAddRecoleccion} />
        <Form.Item
          label="Enviar correo"
          name="sendMail"
          valuePropName="checked"
          initialValue={true}
        >
          <Checkbox />
        </Form.Item>
        <div className="flex justify-end mr-5">
          <Button type="primary" htmlType="submit" size="large">
            Aprobar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditorManifiestos;
