import { Button, Table, Tooltip } from "antd";
import {
  BsArrowClockwise,
  BsCheckCircleFill,
  BsExclamationDiamondFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import { getTiposFacturacion } from "../../redux/actions/tiposFacturacion.actions";
import { ClientEcomexico, TiposFacturacion } from "../../utils/types";
import PrefacturaSelectorModal from "../../components/Prefactura/SelectorModal";
import Filtros from "../../components/Prefactura/Filtros";
import { getPrefacturasReport } from "../../redux/actions/prefacturas.actions";
import moment from "moment";
import BotonAtras from "../../components/BotonAtras";

const Facturacion = () => {
  /**REDUX */
  const dispatch = useAppDispatch();
  const isLoadingReport = useAppSelector(
    (state) => state.prefacturas.isLoadingReport
  );
  const reportePrefacturas: ClientEcomexico[] = useAppSelector(
    (state) => state.prefacturas.reportePrefacturas
  );
  const tiposFacturacion: TiposFacturacion[] = useAppSelector(
    (state) => state.tiposFacturacion.tiposFacturacion
  );

  /**INNER STATE */
  const [tableData, setTableData] = useState<any[]>([]);
  const [isPrefacturaSelectorOpen, setIsPrefacturaSelectorOpen] =
    useState(false);
  const [prefacturasSelectedClient, setPrefacturasSelectedClient] =
    useState("");

  useEffect(() => {
    dispatch(getPrefacturasReport());
    dispatch(getTiposFacturacion());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tableFinalData = reportePrefacturas.map((client) => {
      const tipoFact = tiposFacturacion.find(
        (tipoF) => tipoF.id === client.tipoFacturacionId
      );
      return {
        ...client,
        tipoFacturacion: tipoFact ? tipoFact.descripcion : "",
      };
    });
    setTableData(tableFinalData);
  }, [reportePrefacturas, tiposFacturacion]);

  const handleOpenPrefacturaSelector = async (clientId: string) => {
    setPrefacturasSelectedClient(clientId);
    setIsPrefacturaSelectorOpen(true);
  };

  return (
    <div className="p-5">
      {isPrefacturaSelectorOpen && (
        <PrefacturaSelectorModal
          isOpen={isPrefacturaSelectorOpen}
          clientId={prefacturasSelectedClient}
          onClose={() => setIsPrefacturaSelectorOpen(false)}
        />
      )}
      <h1 className="text-2xl text-green-800">Estatus de la facturación</h1>
      <div className="flex mb-3 gap-2">
        <BotonAtras />
        <Filtros onSuccess={() => dispatch(getPrefacturasReport())} />
        <Button onClick={() => dispatch(getPrefacturasReport())} type="default">
          <BsArrowClockwise />
        </Button>
      </div>
      <Table
        showSorterTooltip={false}
        id="Table"
        loading={isLoadingReport}
        key={"table"}
        dataSource={tableData}
        columns={[
          {
            key: "codigoCliente",
            dataIndex: "codigoCliente",
            className: "text-center",
            title: "# Cliente",
            sorter: (a, b) => a.codigoCliente.localeCompare(b.codigoCliente),
          },
          {
            dataIndex: "nombre",
            className: "text-center",
            title: "Nombre",
            sorter: (a, b) => a.nombre?.localeCompare(b.nombre || "") || 0,
          },
          {
            dataIndex: "tipoFacturacion",
            className: "text-center",
            title: "Tipo Facturación",
            sorter: (a, b) => a.tipoFacturacionId - b.tipoFacturacionId,
          },
          {
            dataIndex: "hasFacturasAprobadas",
            className: "text-center",
            title: "Estatus",
            render: (hasFacturasAprobadas) => (
              <div className="flex justify-center">
                {hasFacturasAprobadas ? (
                  <Tooltip
                    title={
                      "El cliente tiene prefacturas aprobadas, usa la herramienta para facturar y definir el número de factura"
                    }
                  >
                    <BsExclamationDiamondFill
                      size={25}
                      className="text-yellow-700"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={"Todas las prefacturas han sido facturadas, gracias"}
                  >
                    <BsCheckCircleFill size={25} className="text-green-700" />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            dataIndex: "ultimaFactura",
            className: "text-center",
            title: "Última factura registrada",
            sorter: (a, b) =>
              a.ultimaFacturaId?.localeCompare(b.ultimaFacturaId || "") || 0,
            render: (_, record) => {
              const fecha = record.ultimaFacturaFecha
                ? moment(record.ultimaFacturaFecha, "YYYY-MM-DD").format("LL")
                : "Sin facturas registradas";
              return (
                <div className="flex justify-center">
                  <p className="text-orange-700 mr-2">
                    {record.ultimaFacturaId}
                  </p>
                  <p className="text-indigo-700">{fecha}</p>
                </div>
              );
            },
          },
          {
            dataIndex: "codigoCliente",
            title: "Acciones",
            render: (value, record) => (
              <div className="flex justify-center">
                <Link to={`/facturacion/prefactura/nueva/${value}`}>
                  <Button type="primary" className="mr-2">
                    Generar prefactura
                  </Button>
                </Link>
                {record.hasFacturasAprobadas && (
                  <Button
                    onClick={() => handleOpenPrefacturaSelector(value)}
                    type="primary"
                    className="bg-slate-500 hover:bg-slate-700 active:bg-slate-900"
                  >
                    Facturar
                  </Button>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Facturacion;
