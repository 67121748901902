import { Form, FormInstance, Input, Modal, Select } from "antd";
import { useAppSelector } from "../../hooks";
import { TIPOS_FACTURACION, TIPOS_FACTURACION_VALUES } from "./constants";
import ExtraControls from "./ExtraControls";
import { useEffect, useState } from "react";
import { TiposFacturacion } from "../../utils/types";
import dayjs from "dayjs";
interface IProps {
  isModalOpen: boolean;
  formRef: FormInstance;
  mode?: "ADD" | "EDIT";
  elementToEdit?: TiposFacturacion;
  handleSave: () => void;
  handleCancel: () => void;
}
const AddEditModal = ({
  isModalOpen,
  formRef,
  mode = "ADD",
  elementToEdit,
  handleSave,
  handleCancel,
}: IProps) => {
  const isSaveLoading = useAppSelector(
    (state) => state.tiposFacturacion.isSaveLoading
  );
  const [tipo, setTipo] = useState<any>(
    elementToEdit ? elementToEdit.tipo : TIPOS_FACTURACION_VALUES.DIA_DEL_MES
  );

  useEffect(() => {
    formRef.setFieldsValue({
      descripcion: elementToEdit ? elementToEdit.descripcion : "",
      tipo: elementToEdit
        ? elementToEdit.tipo
        : TIPOS_FACTURACION_VALUES.DIA_DEL_MES,
      diasDelMes: elementToEdit ? elementToEdit.diasDelMes?.join() : "",
      diasDeLaSemana: elementToEdit ? elementToEdit.diasDeLaSemana?.join() : "",
      fechaEspecifica: elementToEdit
        ? dayjs(elementToEdit.fechaEspecifica)
        : "",
      frecuencia: elementToEdit ? elementToEdit.frecuencia : "",
    });
  }, [elementToEdit, formRef]);

  return (
    <Modal
      title={
        mode === "ADD"
          ? "Agregar tipo de facturación"
          : "Editar tipo de facturación"
      }
      open={isModalOpen}
      onOk={handleSave}
      okButtonProps={{ loading: isSaveLoading }}
      okText={"Guardar"}
      cancelText={"Cancelar"}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form form={formRef} id="tipos-facturacion-form" className="w-full">
        <Form.Item label="Descripción" name="descripcion" className="mb-2">
          <Input type="text" placeholder="Nombre del tipo de facturación" />
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="tipo"
          className="mb-2"
          initialValue={tipo}
        >
          <Select
            options={TIPOS_FACTURACION}
            size="small"
            onChange={(value) => {
              setTipo(value);
              formRef.resetFields([
                "diasDelMes",
                "diasDeLaSemana",
                "frecuencia",
                "fechaEspecifica",
              ]);
            }}
          />
        </Form.Item>
        <ExtraControls tipo={tipo} />
      </Form>
    </Modal>
  );
};

export default AddEditModal;
