import { ClientEcomexico } from "../../utils/types";
import { ActionTypes } from "../actions/manifiestos.actions";
export const EMPTY_MANIFIESTO = {
  folio: "",
  fechaHora: "",
  codigoCliente: "",
  ruta: "",
  chofer: "",
  asistente: "",
  codigoVehiculo: "",
  firma: "",
  observaciones: "",
  nombreFirma: "",
  fotos: "",
  nombreCliente: "",
  domicilio: "",
  telefono: "",
  createdAt: "",
  emailEnviado: false,
  updatedAt: "",
  recibioDinero: false,
  dinero: 0,
  comentariosInternos: "",
  registroCambios: "",
};
export interface Manifiesto {
  folio: string;
  fechaHora: string;
  codigoCliente: string;
  ruta: string;
  chofer: string;
  asistente: string;
  codigoVehiculo: string;
  firma: string;
  observaciones: string;
  nombreFirma: string;
  fotos: string;
  nombreCliente: string;
  domicilio: string;
  telefono: string;
  createdAt: string;
  emailEnviado: boolean;
  updatedAt: string;
  recibioDinero: boolean;
  dinero: number;
  cliente?: ClientEcomexico;
  comentariosInternos: string;
  registroCambios: string;
}
export interface ManifiestoWithRecolecciones extends Manifiesto {
  recoleccions: {
    cantidad: number;
    codigo_producto: string;
    size: string;
  }[];
}

interface IReducer {
  // PAGINATION
  currentPage: number;
  pageSize: number;
  // ORDER BY
  orderBy?: [string, string];
  // FETCH MANIFIESTOS
  isLoading: boolean;
  manifiestos: Manifiesto[];
  manifiestosCount: number;
  // SEND EMAILS
  isSendingEmails: boolean;
  successSendingEmails: boolean;
  emailErrors: string[];
  emailsPercent: number;
  // LOAD PDFS
  isLoadingPDF: boolean;
  pdfs?: string[];
  pdfErrors?: string[];
  // UTILS
  filters?: {};
  isPDFViewerOpen?: boolean;
  // ERRORS
  hasErrors: boolean;
  errors: string[];
}

const initialState: IReducer = {
  currentPage: 0,
  pageSize: 25,
  orderBy: undefined,
  isLoading: false,
  isLoadingPDF: false,
  isPDFViewerOpen: false,
  hasErrors: false,
  errors: [],
  manifiestos: [],
  pdfErrors: [],
  pdfs: [],
  isSendingEmails: false,
  successSendingEmails: false,
  emailErrors: [],
  filters: {
    ids: [],
    clientIds: [],
    clientNames: "",
    vehicleCodes: [],
    aprobado: true,
  },
  emailsPercent: 0,
  manifiestosCount: 0,
};

const ManifiestosReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // SYNC ACTIONS
    case ActionTypes.SET_MANIFIESTOS_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case ActionTypes.SET_PAGINATION_STATE:
      return {
        ...state,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.page,
      };
    case ActionTypes.SET_ORDER_BY_STATE:
      return {
        ...state,
        orderBy: action.payload,
      };
    case ActionTypes.OPEN_PDF_VIEWER:
      return {
        ...state,
        isPDFViewerOpen: action.payload,
      };
    case ActionTypes.RESET_MANIFIESTOS:
      return initialState;
    // ASYNC ACTIONS
    /**FETCH MANIFIESTOS */
    case ActionTypes.FETCH_MANIFIESTOS_START:
      return {
        ...state,
        isLoading: true,
        hasErrors: false,
        errors: [],
        manifiestos: [],
        manifiestosCount: 0,
      };

    case ActionTypes.FETCH_MANIFIESTOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        manifiestos: action.payload.rows,
        manifiestosCount: action.payload.count,
      };
    case ActionTypes.FETCH_MANIFIESTOS_FAILED:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    /** FETCH MANIFIESTOS COUNT */
    case ActionTypes.FETCH_MANIFIESTOS_COUNT_START:
      return {
        ...state,
        isLoading: true,
        manifiestosCount: 0,
        hasErrors: false,
        errors: [],
      };
    case ActionTypes.FETCH_MANIFIESTOS_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        manifiestosCount: action.payload,
      };
    case ActionTypes.FETCH_MANIFIESTOS_COUNT_FAILED:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: [action.payload],
      };

    /** FETCH PDFS */
    case ActionTypes.FETCH_PDF_START:
      return {
        ...state,
        hasErrors: false,
        errors: [],
        isLoadingPDF: true,
        pdfs: [],
        pdfErrors: [],
      };
    case ActionTypes.FETCH_PDF_SUCCESS:
      return {
        ...state,
        isLoadingPDF: false,
        pdfs: action.payload,
      };
    case ActionTypes.FETCH_PDF_FAILED:
      return {
        ...state,
        isLoadingPDF: false,
        pdfErrors: action.payload,
      };
    /** SEND EMAILS */
    case ActionTypes.SEND_EMAIL_START:
      return {
        ...state,
        isSendingEmails: true,
        successSendingEmails: false,
        emailErrors: [],
        emailsPercent: 0,
      };
    case ActionTypes.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isSendingEmails: false,
        successSendingEmails: true,
        emailErrors: action.payload.errors,
      };
    case ActionTypes.SEND_EMAIL_FAILED:
      return {
        ...state,
        isSendingEmails: false,
        emailErrors: [action.payload],
      };
    case ActionTypes.UPDATE_PERCENT_EMAIL:
      return {
        ...state,
        emailsPercent: action.payload,
      };
    case "RESET-ERRORS":
      return {
        ...state,
        hasErrors: false,
        errors: [],
      };
    default:
      return state;
  }
};

export default ManifiestosReducer;
