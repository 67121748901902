import React from "react";
import { ClientEcomexico } from "../../utils/types";
interface IProps {
  cliente: ClientEcomexico;
}
const DisplayDias = ({
  cliente: { lunes, martes, miercoles, jueves, viernes, sabado, domingo },
}: IProps) => {
  return (
    <div className="flex flex-wrap w-full justify-between">
      <p className={lunes ? "font-bold text-green-500" : ""}>Lunes</p>
      <p className={martes ? "font-bold text-green-500" : ""}>Martes</p>
      <p className={miercoles ? "font-bold text-green-500" : ""}>Miercoles</p>
      <p className={jueves ? "font-bold text-green-500" : ""}>Jueves</p>
      <p className={viernes ? "font-bold text-green-500" : ""}>Viernes</p>
      <p className={sabado ? "font-bold text-green-500" : ""}>Sabado</p>
      <p className={domingo ? "font-bold text-green-500" : ""}>Domingo</p>
    </div>
  );
};

export default DisplayDias;
